import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDataToStorage } from '../util/localstorage-util';
import { getAddress } from 'sats-connect';
import { setAddress } from '../store/action';
import SessionStorage, { SessionsStorageKeys } from "../services/session-storage";

export default function WalletProvider(props) {
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchWalletStatus = async () => {
      try {
        await handleWallet();
      }
      catch (error) {
        console.log(error);
      }
    }
    fetchWalletStatus();
    return () => {
      console.log("refresh the screen");
    }
  }, [])

  const handleWallet = async () => {
    let res = getDataToStorage();

    if (res.domain == "unisat") {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let address = await window.unisat.getAccounts();
        let pubkey = await window.unisat.getPublicKey();
        dispatch(setAddress({
            xWalletName : "unisat",
            domain : "unisat",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));

      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }

    if (res.domain == "okxwallet") {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let address = await window.okxwallet.bitcoin.getAccounts();
        let pubkey = await window.okxwallet.bitcoin.getPublicKey();
        dispatch(setAddress({
            xWalletName : "okxwallet",
            domain : "okxwallet",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }

    if (res.domain == "xverseWallet") {
      const getAddressOptions = {
        payload: {
          purposes: ['ordinals', 'payment'],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type:'Mainnet'
          },
        },
        onFinish: (response) => {
          if (response.addresses[0].purpose == "ordinals")
          {
            dispatch(setAddress({
              xWalletName : "xverseWallet",
              domain : "xverseWallet",
              nostrOrdinalsAddress : response.addresses[0].address.trim(),
              nostrPaymentAddress : response.addresses[1].address.trim(),
              ordinalsPublicKey : response.addresses[0].publicKey.trim(),
              paymentPublicKey : response.addresses[1].publicKey.trim()
            }));
          }
          else
          {
            dispatch(setAddress({
              xWalletName : "xverseWallet",
              domain : "xverseWallet",
              nostrOrdinalsAddress : response.addresses[1].address.trim(),
              nostrPaymentAddress : response.addresses[0].address.trim(),
              ordinalsPublicKey : response.addresses[1].publicKey.trim(),
              paymentPublicKey : response.addresses[0].publicKey.trim()
            }));
          }
        },
        onCancel: () => alert('Request canceled'),
        }
          
      await getAddress(getAddressOptions);
    }
  }

  const {children} = props;
  return (
    <>{children}</>
  )
}
