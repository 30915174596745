import React, {useState, useEffect, useCallback} from 'react'
import { 
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';

import { BellAlertIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import { getFeeRate } from '../util/inscribe-util';
import { RiBikeLine } from "react-icons/ri";
import { MdOutlineFlight } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { debounce } from 'lodash';
import Connect from './Connect';
import logo from '../assets/imgs/logo.png';
import { latestDomain, getDomainAddress } from '../util/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateKey } from '../store/action';
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { getPointData } from '../util/api';

export default function Header(props) {
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {tab, handle} = props;
  const [key, setKey] = useState("");
  const [feeRates, setFeeRates] = useState({});
  const [point, setPoint] = useState(0);

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        let response = await getFeeRate();
        setFeeRates(response);
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 20 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    const fetchPoints = async () => {
      if (wallet.nostrOrdinalsAddress != null && wallet.nostrOrdinalsAddress != "") {
        let result = await getPointData(
          {
            address: wallet.nostrOrdinalsAddress
          }
        )
        if (result.total < 0) 
          setPoint(0);
        else
          setPoint(result.total ?? 0);
      }
    }
    fetchPoints();
  }, [wallet.nostrOrdinalsAddress])

  const debouncedSearch = useCallback(debounce( async (value) => {
    let result = await getDomainAddress(value);
    let domain = "";
    let address = "";
    
    if (result.total == 0) 
      address = value;
    else
    {
      domain = result.domain;
      address = result.address;
    }

    let keyData = {address: address, domain: domain};

    dispatch(updateKey(keyData));
    handle(2);
  }, 800), []); 

  const handleKeyChange = (e) => {
    setKey(e.target.value)
    debouncedSearch(e.target.value)
  }

  const logoClick = () => {
    navigate('/')
  }

  return (
    <div className="flex flex-row justify-center h-[65px] fixed top-0 z-50 w-full border-b-[1px] border-b-[#24242a] md:px-2 px-2 text-white bg-[#0b0b0d]">
      <div className=" flex flex-row justify-between sm:gap-4 gap-1 items-center w-full">
        <div className="flex flex-row md:gap-3 gap-2 items-center">
          <div className="flex flex-row items-center">
            <img src = {logo} className="w-[40px] h-[40px] cursor-pointer" onClick={logoClick}/>
            <div className="md:flex hidden font-bold text-xl ml-2 font-title">UNIVERSE</div>
          </div>
          <div className="lg:flex hidden flex-row ">
            {
              tab != 2 ? <Input type="text" color="white" label = "Input Name or Address" className="w-full min-w-[230px]" value={key} onChange = {handleKeyChange}/> : <></>
            }
          </div> 
          <div className={`${wallet.nostrPaymentAddress != "" ? '2xl:flex' : 'xl:flex'} hidden flex-row items-center gap-4 md:ml-2 ml-1 font-title`}>
            <div className={`${tab == 0 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`} onClick={() => {handle(0)}}>Wallet</div>
            <div className={`${tab == 1 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`} onClick={() => {handle(1)}}>Names</div>
            <div className={`${tab == 2 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`} onClick={() => {handle(2)}}>Explorer</div>
            {
              wallet.nostrPaymentAddress != "" ? 
              <div className={`${tab == 3 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`} onClick={() => {handle(3)}}>My Profile</div>
              : <></>
            }
            <div className={`${tab == 4 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`} onClick={() => {handle(4)}}>Index</div>
            <Menu>
              <MenuHandler>
                <div className={`${tab == 5 || tab == 7 || tab == 9 || tab == 10 || tab == 13 || tab == 12 || tab == 14 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`}>Inscribe</div>
              </MenuHandler>
              <MenuList className="bg-black border-black text-white text-md">
                <MenuItem onClick={() => {handle(5)}} >$TAPZERO</MenuItem>
                <MenuItem onClick={() => {handle(7)}} >$iSTAMPS</MenuItem>
                <MenuItem onClick={() => {handle(14)}} >$RUNEZERO</MenuItem>
                <MenuItem onClick={() => {handle(9)}} >TAP PRV AUTH</MenuItem>
                <MenuItem onClick={() => {handle(10)}} >TAP DMT|UNAT BLOCKPAD</MenuItem>
                <MenuItem onClick={() => {handle(12)}} >TAP DMT|UNAT BLOCKDROPS</MenuItem>
                <MenuItem onClick={() => {handle(13)}} >MINT RUNES</MenuItem>
              </MenuList>
            </Menu>
            <div className={`${tab == 6 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`} onClick={() => {handle(6)}}>Marketplace</div>
            <Menu>
              <MenuHandler>
                <div className={`${tab == 11 || tab == 8 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-md`}>Tools</div>
              </MenuHandler>
              <MenuList className="bg-black border-black text-white text-md">
                <MenuItem onClick={() => {handle(8)}} >CHAT</MenuItem>
                <MenuItem onClick={() => {handle(11)}} >TAP AIRDROP</MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className = "flex flex-row sm:gap-1 gap-0 h-[44px]">
          <a href="https://x.com/pinkbtcuniverse" className="sm:m-[0px]" target="_blank">
            <img src="https://mscribe-webapp.s3.amazonaws.com/twitter.png" alt="Twitter" className="cursor-pointer sm:w-[45px] w-[40px] mt-1 p-2 " />
          </a>
          <a href="https://discord.com/invite/Auq977wfM8" className="sm:m-[0px] sm:flex hidden mr-[-8px]" target="_blank">
            <img src="https://mscribe-webapp.s3.amazonaws.com/discord.png" alt="Discord" className="cursor-pointer sm:w-[45px] w-[40px] mt-1 p-2" />
          </a>
          <a href="https://t.me/pinkbtcuniverse"  target="_blank">
            <div className="sm:flex hidden flex-row p-2">
              <span className="sm:text-[32px] text-[28px] text-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M231.3,31.7A16.1,16.1,0,0,0,215,29L30.4,101.5a15.8,15.8,0,0,0-10.1,16.3,16,16,0,0,0,12.8,14.3L80,141.4V200a16,16,0,0,0,9.9,14.8A16.6,16.6,0,0,0,96,216a15.8,15.8,0,0,0,11.3-4.7l26-25.9L172.6,220a16,16,0,0,0,10.5,4,14.2,14.2,0,0,0,5-.8,15.9,15.9,0,0,0,10.7-11.6L236.4,47.4A16,16,0,0,0,231.3,31.7ZM183.2,208l-82.4-72.5L219.5,49.8Z"></path></svg>
              </span>
            </div>
          </a>
          <div className="flex flex-row gap-1">
            {
              wallet.nostrOrdinalsAddress != "" && wallet.nostrOrdinalsAddress != null ? 
              <div className="flex flex-row items-center gap-2 rounded-full cursor-pointer bg-primary hover:bg-primary-hover sm:px-4 px-5 py-1 h-full">
                <RocketLaunchIcon strokeWidth={2} className="h-5 w-5 sm:flex hidden" />
                <span>{point}</span>
              </div> : 
              <></>
            }
            
            <div className="sm:block hidden h-full">
              <Popover placement="bottom-end">
                <PopoverHandler>
                  <div className="flex flex-row items-center gap-2 rounded-full cursor-pointer bg-primary hover:bg-primary-hover px-4 py-1 h-full">
                    <BellAlertIcon strokeWidth={2} className="h-5 w-5" />
                    <span>{feeRates.Normal}</span>
                  </div>
                </PopoverHandler>
                <PopoverContent className="bg-black border-black">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-2 itmes-center">
                      <RiBikeLine className="text-white text-lg"/>
                      <span className="text-white font-semibold">Low: {feeRates.Slow}&nbsp;sats/vB</span>
                    </div>
                    <div className="flex flex-row gap-2 itmes-center">
                      <MdOutlineFlight className="text-white text-lg"/>
                      <span className="text-white font-semibold">Medium: {feeRates.Normal}&nbsp;sats/vB</span>
                    </div>
                    <div className="flex flex-row gap-2 itmes-center">
                      <BsFillLightningChargeFill className="text-white text-lg"/>
                      <span className="text-white font-semibold">High: {feeRates.Fast}&nbsp;sats/vB</span>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
            <Connect />
            <div className= {`${wallet.nostrPaymentAddress != "" ? '2xl:hidden' : 'xl:hidden'} flex`}>
              <Popover placement="bottom-end">
                <PopoverHandler>
                  <div className="flex flex-row items-center gap-2 rounded-md cursor-pointer bg-primary hover:bg-primary-hover px-[10px] py-2">
                    <QueueListIcon strokeWidth={2} className="h-5 w-5" />
                  </div>
                </PopoverHandler>
                <PopoverContent className="bg-black border-black">
                  <div className="flex flex-col gap-4 px-3">
                    <div className={`${tab == 0 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(0)}}>Wallet</div>
                    <div className={`${tab == 1 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(1)}}>Names</div>
                    <div className={`${tab == 2 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(2)}}>Explorer</div>
                    {
                      wallet.nostrPaymentAddress != "" ? 
                      <div className={`${tab == 3 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(3)}}>My Profile</div>
                      : <></>
                    }
                    <div className={`${tab == 4 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(4)}}>Index</div>
                    <Menu placement="left">
                      <MenuHandler>
                        <div className={`${tab == 5 || tab == 7 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`}>Inscribe</div>
                      </MenuHandler>
                      <MenuList className="bg-black border-black text-white">
                        <MenuItem onClick={() => {handle(5)}}>$TAPZERO</MenuItem>
                        <MenuItem onClick={() => {handle(7)}}>$iSTAMPS</MenuItem>
                        <MenuItem onClick={() => {handle(14)}}>$RUNEZERO</MenuItem>
                        <MenuItem onClick={() => {handle(9)}}>TAP PRV AUTH</MenuItem>
                        <MenuItem onClick={() => {handle(10)}} >TAP DMT|UNAT BLOCKPAD</MenuItem>
                        <MenuItem onClick={() => {handle(12)}} >TAP DMT|UNAT BLOCKDROPS</MenuItem>
                        <MenuItem onClick={() => {handle(13)}} >MINT RUNES</MenuItem>
                      </MenuList>
                    </Menu>
                    <div className={`${tab == 6 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(6)}}>Marketplace</div>
                    <Menu placement="left">
                      <MenuHandler>
                        <div className={`${tab == 11 || tab == 8 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`}>Tools</div>
                      </MenuHandler>
                      <MenuList className="bg-black border-black text-white">
                        <MenuItem onClick={() => {handle(8)}}>CHAT</MenuItem>
                        <MenuItem onClick={() => {handle(11)}}>TAP AIRDROP</MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
