import React from 'react'
import { useEffect, useState, useCallback } from 'react'
import { getMarketplaceActivity } from '../../util/api';
import { 
  Input,
  Spinner,
  IconButton,
  Typography,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Tooltip
} from '@material-tailwind/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { formatAddress, formatTime, formatAddressMini } from '../../util/format-data';
import { updateKey } from '../../store/action';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import { amountFormat } from '../../util/format-data';

export default function Activity(props) {
  const { keyAddress } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [ticker, setTicker] = useState('');
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(3); // 0: Tap, 1: Dmt, 2: Unat
  const [mode, setMode] = useState("ALL"); // sale, edit, delist, buy
  const types = ["TAP", "DMT", "UNAT", "ALL", "NAME", "BITMAP"];
  const modes = {
    ALL: "ALL",
    sale: "LIST",
    delist: "DELIST",
    buy: "SALES"
  };

  const colors = {
    sale: "#064231",
    buy: "#8A6E21",
    delist: "#521E1B"
  }

  useEffect(() => {
    fetchActivityData(ticker);
  }, [page, type, mode, keyAddress])

  useEffect(() => {
    setPage(1);
    fetchActivityData(ticker, 1);
  }, [type, mode])


  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const fetchActivityData = async (ticker = "", offset = page) => {
    setLoading(true);
    if (keyAddress != "") {
      let params = {
        ownerAddress: keyAddress,
        offset: offset,
        limit: limit
      }

      if (type != 3) params.type = type;
      if (mode != "ALL") params.mode = mode.toLowerCase();
      if (ticker != "") params.ticker = ticker;

      let results = await getMarketplaceActivity(params);
      setTotal(Math.ceil(results.total / limit));
      setActivityData(results.data);
    }
    else {
      setTotal(0);
      setActivityData([]);
    }
    setLoading(false);
  }

  const handleAddress = (domain, ownerAddress) => {
    let keyData = {address: ownerAddress, domain: domain ? domain: ""};
    dispatch(updateKey(keyData));
    navigate('/explorer');
  }

  const handleKeyChange = (e) => {
    setTicker(e.target.value);
    setPage(1);
    debouncedSearch(e.target.value, 1)
  }

  const debouncedSearch = useCallback(debounce( async (value, offset) => {
    try {
      fetchActivityData(value, offset);
    }
    catch (e) {
      console.log(e);
    }
  }, 300), []); 

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="flex md:flex-row flex-col md:gap-1 gap-3 w-full md:justify-end items-end mt-3">
        <div className="w-[250px]">
          <Input type="text" color="white" label = "Ticker" className="w-[250px]" value = {ticker} onChange = {(e) => {handleKeyChange(e)}}/>
        </div>
        <div className="flex flex-row gap-1">
          <Menu placement="bottom-end">
            <MenuHandler>
              <div className="flex flex-row justify-between border-borderColor border-solid border-[1px] rounded-md pt-[9px] pb-[8px] cursor-pointer sm:w-[180px] w-[160px] px-[24px] items-center relative">
                <div className="text-[14px]">{types[type]}</div>
                <div><ChevronDownIcon strokeWidth={3} className="h-4 w-4" /></div>
                <span className="absolute mt-[-40px] ml-[-8px] text-[12px] bg-[#0B0B0D] px-1">Category</span>
              </div>
            </MenuHandler>
            <MenuList className="bg-black border-black text-white">
              <MenuItem onClick={() => setType(3)}>ALL</MenuItem>
              <MenuItem onClick={() => setType(0)}>TAP</MenuItem>
              <MenuItem onClick={() => setType(1)}>DMT</MenuItem>
              <MenuItem onClick={() => setType(2)}>UNAT</MenuItem>
              <MenuItem onClick={() => setType(5)}>BITMAP</MenuItem>
              <MenuItem onClick={() => setType(4)}>NAME</MenuItem>
            </MenuList>
          </Menu>
          <Menu placement="bottom-end">
            <MenuHandler>
              <div className="flex flex-row justify-between border-borderColor border-solid border-[1px] rounded-md pt-[9px] pb-[8px] cursor-pointer sm:w-[180px] w-[160px] px-[24px] items-center relative">
                <div className="text-[14px]">{modes[mode]}</div>
                <div><ChevronDownIcon strokeWidth={3} className="h-4 w-4" /></div>
                <span className="absolute mt-[-40px] ml-[-8px] text-[12px] bg-[#0B0B0D] px-1">Action</span>
              </div>
            </MenuHandler>
            <MenuList className="bg-black border-black text-white">
              <MenuItem onClick={() => setMode("ALL")}>ALL</MenuItem>
              <MenuItem onClick={() => setMode("sale")}>LIST</MenuItem>
              <MenuItem onClick={() => setMode("delist")}>DELIST</MenuItem>
              <MenuItem onClick={() => setMode("buy")}>SALES</MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <div className="md:flex hidden flex-row gap-1 mt-8 px-4 py-2">
        <div className = "w-[8%]">Category</div>
        <div className = "w-[14%]">Inscription Id</div>
        <div className = "w-[16%]">Ticker</div>
        <div className = "w-[8%]">Action</div>
        <div className = "w-[14%]">Amount</div>
        <div className = "w-[10%]">Total</div>
        <div className = "w-[10%]">Seller</div>
        <div className = "w-[10%]">Buyer</div>
        <div className = "w-[10%]">Time</div>
      </div>
      <div className="md:flex hidden flex-col gap-1">
      {
        activityData.map((data) => 
          <div className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg items-center" key={uuidv4()}>
            <div className = "w-[8%]">
              <span className="text-white p-1 bg-primary rounded-md font-bold">
                {types[data.type]}
              </span>
            </div>
            <div className = "w-[14%]">
              {
                data.inscriptionId.includes("domain-") ? 
                <></> :
                <a href={`https://ordinals.com/inscription/${data.inscriptionId}`}  target="_blank">
                  {formatAddress(data.inscriptionId)}
                </a>
              }
            </div>
            <div className = "w-[16%] font-bold text-primary">
              {data.ticker} {data.blk}
            </div>
            <div className = {`w-[8%]`}>
              { data.reason == "buy" ? <span className="py-[6px] px-[8px] rounded-md bg-[#064231]">Buy Broadcasted</span> : <></> }
              { data.reason == "sale" ? <span className="py-[6px] px-[16px] rounded-md bg-[#832F27]">List</span> : <></> }
              { data.reason == "delist" ? <span className="py-[6px] px-[8px] rounded-md bg-[#521E1B]">Delist</span> : <></> }
            </div>
            <div className = "w-[14%]" >
              {amountFormat(data.amount)}
            </div>
            <div className = "w-[10%] flex flex-row gap-1 items-center">
              <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
              {Math.floor(data.totalPrice / Math.pow(10, 3)) / Math.pow(10, 5)}
            </div>
            <div className = "w-[10%] cursor-pointer" onClick={() => handleAddress(data.ownerDomain, data.ownerAddress)}>
              {data.ownerDomain ? data.ownerDomain : formatAddress(data.ownerAddress)}
            </div>
            <div className = "w-[10%] cursor-pointer" onClick={() => handleAddress(data.buyerDomain, data.buyerAddress)}>
              {data.buyerDomain ? data.buyerDomain : formatAddress(data.buyerAddress)}
            </div>
            <div className = "w-[10%]">
              {data.updatedAt ? formatTime(data.updatedAt) : formatTime(data.createdAt)}
            </div>
          </div>
        )
      }
      </div>
      <div className="md:hidden flex flex-col gap-1 mt-8">
      {
        activityData.map((data) => 
          <div className="flex flex-row items-center gap-1 text-white bg-gray-900 p-4 rounded-lg" key={uuidv4()}>
            <div className = "w-[24%] text-white">
              <span className="text-white p-2 bg-primary rounded-md font-bold">
                {types[data.type]}
              </span>
            </div>
            <div className = "w-[52%] flex flex-col gap-1">
              <div>
                { data.reason == "buy" ? <span className="py-[4px] px-[8px] rounded-md bg-[#064231]">Buy Broadcasted</span> : <></> }
                { data.reason == "sale" ? <span className="py-[4px] px-[16px] rounded-md bg-[#832F27]">List</span> : <></> }
                { data.reason == "delist" ? <span className="py-[4px] px-[8px] rounded-md bg-[#521E1B]">Delist</span> : <></> }
              </div>
              <div className = "font-bold text-primary">
                {data.ticker} {data.blk}
              </div>
            </div>
            <div className = "w-[24%] flex flex-col gap-1">
              <div>
                <span onClick={() => handleAddress(data.ownerDomain, data.ownerAddress)}>{data.ownerDomain ? data.ownerDomain : formatAddressMini(data.ownerAddress)}</span>&nbsp;&#8594;&nbsp;
              </div>
              <div>
                <span onClick={() => handleAddress(data.buyerDomain, data.buyerAddress)}>{data.buyerDomain ? data.buyerDomain : formatAddressMini(data.buyerAddress)}</span>
              </div>
              <div>{Math.floor(data.total / Math.pow(10, 3)) / Math.pow(10, 5)}BTC</div>
            </div>
          </div>
        )
      }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doublePrev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doubleNext}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
}
