import React, {useState, useEffect, useCallback} from 'react'
import { getInscriptionContent, getOrdinals, getUnat, getInscriptionIds, getUnatData, updateUnat } from '../../util/api';
import {Spinner} from '@material-tailwind/react'
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import BitmapCard from '../TokenSaleCard/BitmapCard';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { debounce } from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import { sleep } from '../../util/api';
import { getBtcPrice } from '../../util/api';
import { getUnatByAddress } from '../../util/tap-api';
import { getBitmapWalletHistoricList, getBitmapWalletHistoricListLength, getBitmapByInscription } from '../../util/tap-api';
import { getBitmapByAddress } from '../../util/bitmap-api';

export default function Bitmap(props) {
  const { keyAddress } = props;
  const [datas, setDatas] =  useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [ticker, setTicker] = useState("");
  const [blk, setBlk] = useState("");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchOrdinals();
        setLoading(false);
      }
      catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, [keyAddress, page])

  const fetchOrdinals = async () => {
    if (keyAddress == "") {
      setDatas([]);
      setTotal(0);
    }
    else {
      /*
      let data = [];
      let realData = [];
      let res = await getBitmapWalletHistoricListLength(keyAddress);
      let total = res.result;

      for(let i = 0; i< Math.ceil(total / limit); i++) {
        res = await getBitmapWalletHistoricList(keyAddress, i * limit);
        for(let temp of res.result) {
          let flag = 1;
          for(let temp1 of data) {
            if (temp == temp1) {
              flag = 0;
              break;
            }
          }
          if (flag == 1) data.push(temp);
        }
      }
      for(let temp of data) {
        res = await getBitmapByInscription(temp);
        if (res.result.ownr == keyAddress)
        {
          realData.push()
        }
      }
      setTotal(realData.length);
      setDatas(realData);
      */
      let res = await getBitmapByAddress(keyAddress, limit, (page - 1) * limit);
      let data = [];
      for(let temp of res.data.list){
        data.push(
          {
            id: temp.inscription_id,
            ticker: temp.content,
            amount: 1,
            number: temp.inscription_number
          }
        )
      }
      setDatas(data);
      setTotal(Math.ceil(res.data.count / limit));
    }
  }

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 3xl:grid-cols-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-5">
        {
          total > 0 ? 
            datas.map((data) => 
              <BitmapCard key={uuidv4()} data = {data} price={price} type={5}/> 
            ) : <>No Bitmaps.</>
        }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
    </div>
  )
}
