import React, { useRef } from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { combineAddress, formatAddressMicroText } from '../../util/format-data'
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

export default function MessageInput(props) {
  const {activeContact, address} = props
  const inputRef = useRef();

  const sendMessage = async () => {
    const message = inputRef.current.innerText;
    if (message.trim() != "") {
      await addDoc(collection(firestore, 'chat-' + combineAddress(activeContact, address)), {
        message: message,
        from: address,
        to: activeContact,
        isDeleted: false,
        isRead: false,
        timestamp: serverTimestamp(),
      });
      inputRef.current.innerText = "";

      const docRef = doc(firestore, "chat-" + formatAddressMicroText(activeContact), address);
      const data = {
        address: address,
        typing: false,
        count: 0,
        isRead: false,
        status: false,
        message: message,
        timestamp: serverTimestamp(),
      }
  
      await setDoc(docRef, data, { merge: true});
    }
  }
  return (
    <div className="flex flex-row w-full border-t-[2px] border-t-[#24242a] px-6 py-3 gap-2 items-end">
        <div 
          ref={inputRef}
          className="rounded-md border-[2px] border-[#24242a] min-h-[40px] w-full text-white p-2 break-all whitespace-normal" 
          contentEditable = {true}>
        </div>
        <div className="text-primary p-2 rounded-lg border-[2px] border-primary cursor-pointer hover:bg-primary hover:text-white" onClick={() => sendMessage()}>
            <PaperAirplaneIcon strokeWidth={2} className="h-6 w-6" />
        </div>
    </div>
  )
}
