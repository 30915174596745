import React, {useState, useEffect, useCallback} from 'react'

import {ChevronDownIcon, MagnifyingGlassIcon} from '@heroicons/react/24/solid';
import { 
  ButtonGroup, 
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
  IconButton,
  Slider,
  Select, 
  Option,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Tooltip,
  Checkbox
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { getBlockdropPATokenMintTickers, getBlockdropPATokenDeployTickers, checkBlockdropPATokenDeployTicker, getBlockdropPATokenDetail, saveBlockdropPATokenDetail, registerBlockdropPaymentRandom, latestBlockdropTokenRandom, registerBlockdropTokenRandom, getPaymentUtxosRandom, getPaymentHistoryNew, getBlockdropSignMintTextRandom, getBlockdropPRVInscriptionContent, cancelBlockdropPRVInscriptionContent, savePaymentHistoryNew, getDmtPATokenDeployTickers, getDmtPATokenMintTickers } from '../util/new-api';

import { debounce } from 'lodash';

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding, royalty_pa } from '../configs/constant';

import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFeeNew, getData, isValidTaprootAddress} from '../util/inscribe-util';
import { getInscriptionContent } from '../util/tap-api';

import { CheckIcon } from '@heroicons/react/24/solid';

import { sendBtcTransaction } from 'sats-connect';
import FeeRateCard from '../components/FeeRateCard';
import { BitcoinNetworkType, signMessage, signTransaction } from 'sats-connect';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import toast, { Toaster } from 'react-hot-toast';
import { useWallet, useWallets } from '@wallet-standard/react';
import { useSelector, useDispatch } from 'react-redux';
import { validTapTicker } from '../util/format-data';
import { latestTokenNew, getBlockdropPATokenWhitelistAddressCheck } from '../util/new-api';
import { formatAddress, formatAddressMicroText, formatInscriptionId, formatAddressMicro } from '../util/format-data';
import { getDmtTokenDetail } from '../util/tap-api';

import BlockSearch from '../components/BlockSearch';

export default function BlockdropPAToken() {
  const navigate = useNavigate();

  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [feeValues, setFeeValues] = useState({
    "inscriptionFee": 0,
    "networkFee": 0,
    "serviceFee": 0,
    "royaltyFee": 0,
    "totalFee": 0
  });

  const [loading, setLoading] = useState(false);
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [inscriptionStatus, setInscriptionStatus] = useState(false);
  const [showFeeRateCard, setShowFeeRateCard] = useState(false);
  const [showBlockSearch, setShowBlockSearch] = useState(false);

  const [type, setType] =  useState(1);
  const [ticker, setTicker] = useState("");
  const [parentTicker, setParentTicker] = useState("");
  const [message, setMessage] = useState("tapwallet.io-auth1");
  const [maxSupply, setMaxSupply] = useState(1);
  const [limitPerMint, setLimitPerMint] = useState(1);
  const [walletLimit, setWalletLimit] = useState(25);
  const [inscriptionId, setInscriptionId] = useState("");
  const [mintCost, setMintCost] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [receiveAddress, setReceiveAddress] = useState("");
  const [amount, setAmount] = useState(1);
  const [isLoyalty, setIsLoyalty] = useState(1);
  const [royaltyToken, setRoyaltyToken] = useState(0);
  const [royaltyAddress, setRoyaltyAddress] = useState("");
  const [royaltyLimit, setRoyaltyLimit] = useState(0);
  const [royaltyMaxSupply, setRoyaltyMaxSupply] = useState(0);
  const [royaltyWalletLimit, setRoyaltyWalletLimit] = useState(0);
  const [royaltyWhitelist, setRoyaltyWhitelist] = useState(0);
  const [royaltyNumber, setRoyaltyNumber] = useState(0);
  const [deployTickers, setDeployTickers] = useState([]);
  const [mintTickers, setMintTickers] = useState([]);
  const [parentTickers, setParentTickers] = useState([]);
  const [isDeployTickerValid, setIsDeployTickerValid] = useState(0);
  const [isMintTickerValid, setIsMintTickerValid] = useState(0);
  const [depId, setDepId] = useState("");
  const [blk,setBlk] = useState("");
  const [elementId, setElementId] = useState("");
  const [dmtId, setDmtId] = useState("");
  const [dt, setDt] = useState("");
  const [pattern, setPattern] = useState("");
  const [prj, setPrj] = useState("");  
  const [isParentTickerValid, setIsParentTickerValid] = useState("");
  const [isDifferentProject, setIsDifferentProject] = useState("");

  const [tickerDetail, setTickerDetail] = useState({});

  const deployDataTypes = ["n", "h"];

  const [prvInscriptionId, setPrvInscriptionId] = useState("");

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        if (feeRateMode == "Custom") {
          setFeerate(customFee);
          setFeeValues(calculateFeeRandom(customFee, amount));
        }
        else
        {
          let response = await getFeeRate();
          setFeeRates(response);
          setFeerate(response[feeRateMode]);
          setFeeValues(calculateFeeRandom(response[feeRateMode], amount));
          if (customFee == 0) 
          {
            setCustomFee(response["Fast"] + 1);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [feeRateMode, amount, customFee, isLoyalty])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  useEffect(() => { 
    setReceiveAddress(wallet.nostrOrdinalsAddress);
    handleRoyalty();
  }, [wallet.nostrOrdinalsAddress]);

  useEffect(() => {
    setAmount(1);
    handleTickers();
  }, [type])

  const handleTickers = async () => {
    if (type == 1) {
      let blockdropResults = await getBlockdropPATokenDeployTickers();
      setDeployTickers(blockdropResults);
      setIsDeployTickerValid(checkValid(ticker, blockdropResults));

      let results = await getDmtPATokenMintTickers();
      setParentTickers(results);
      setIsParentTickerValid(checkValid(parentTicker, results) || checkValid(parentTicker, blockdropResults));
    }
    else if (type == 2) {
      let results = await getBlockdropPATokenMintTickers();
      setMintTickers(results);
      setIsMintTickerValid(checkValid(ticker, results));
    }
  }

  const handleDeployTicker = (value) => {
    setTicker(value);
    setIsDeployTickerValid(checkValid(value, deployTickers));
  }

  const handleParentTicker = (value) => {
    setParentTicker(value);
    setIsParentTickerValid(checkValid(value, parentTickers));
  }

  const handleMintTicker = async (value) => {
    setTicker(value);
    setIsMintTickerValid(checkValid(value, mintTickers));
    if (checkValid(value, mintTickers) == true) {
      await handleRoyaltyToken(value);
      const temp = await getDmtTokenDetail(value);
      setDepId(temp.result.ins);
    }
  }

  const handleRoyaltyToken = async (value) => {
    if (type == 0 || type == 1) {
      setRoyaltyToken(0);
      setRoyaltyLimit(0);
      setRoyaltyAddress("");
      setRoyaltyWalletLimit(0);
      setRoyaltyMaxSupply(0);
      setRoyaltyNumber(0);
      setRoyaltyWhitelist(0);
    }
    else {
      const results = await getBlockdropPATokenDetail(value);
      if (results.data.length > 0) {
        let data = results.data[0];
        setRoyaltyToken(data.mintCost);
        setRoyaltyAddress(data.paymentAddress);
        setRoyaltyLimit(data.mintLimit);
        setRoyaltyWalletLimit(data.limitPerMint);
        setRoyaltyMaxSupply(data.maxSupply);
        setRoyaltyNumber(data.number)
        setRoyaltyWhitelist(data.is_whitelist);
      }
    }
  }

  const handleRoyalty = async() => {
    // if (wallet.nostrOrdinalsAddress == "") {
    //   setIsLoyalty(1);
    // }
    // else {
    //   const results = await latestTokenNew(wallet.nostrOrdinalsAddress);
    //   if (results.data.length > 0) setIsLoyalty(0); else setIsLoyalty(1);
    // }
    setIsLoyalty(1);
  }

  const handleType = (value) => {
    setType(value);
  }

  const handleTicker = (value) => {
    setTicker(value);
    // setMessage("tapwallet.io-" + value);
  }

  const handleMessage = (value) => {
    setMessage(value);
  }

  const handleMaxSupply = (value) => {
    setMaxSupply(value);
  }

  const handleInscriptionId = (value) => {
    setInscriptionId(value);
  }

  const handleLimitPerMint = (value) => {
    setLimitPerMint(value);
  }

  const handleMintCost = (value) => {
    setMintCost(value);
  }

  const handleDmtId = (value) => {
    setDmtId(value)
  }

  const handleElementId = (value) => {
    setElementId(value)
  }

  const handlePassword = (value) => {
    setPassword(value);
  }

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
  }

  const handlePaymentAddress = (value) => {
    setPaymentAddress(value);
  }
  
  const handleReceiveAddress = (value) => {
    setReceiveAddress(value);
  }

  const handleAmount = (value) => {
    setAmount(value);
  }

  const handleWalletLimit = (value) => {
    setWalletLimit(value);
  }

  const handleDataType = (value) => {
    setDt(value);
  }

  const handleBlk = (value) => {
    setBlk(value);
    setShowBlockSearch(false);
  }

  const handleShowBlockSearch = async () => {
    if (isMintTickerValid) {
      // const data = await getBlockdropPATokenDetail(ticker);
      // setTickerDetail(data.data[0]);
      // setShowBlockSearch(true);
      navigate(`/inscribe/blockdrop/${ticker}/blockpad`)
    } else {
      toast.error("Please insert valid ticker first!");
    }
  }

  const handleSubmit = async (value) => {
    setLoading(true);

    let blockdropPrv = "";
    let blockdropPrj = "";

    if (wallet.nostrOrdinalsAddress == "") {
      toast.error("Please connect your wallet!");  
      setLoading(false);
      return;
    }

    if (type == 0) {
      if (validTapTicker(ticker)) {
        let results = await checkBlockdropPATokenDeployTicker(ticker);
        if (results.length == 0) {
          setShowFeeRateCard(true);
        }
        else {
          toast.error("Ticker already exists!");  
          setLoading(false);
          return;
        }
      }
      else {
        toast.error("Please insert valid tap ticker!");
        setLoading(false);
        return;
      }
    }
    else if (type == 1) {
      if (ticker == "") {
        toast.error("Please insert ticker!");
        setLoading(false);
        return;
      }
      // if (isDeployTickerValid == 0) {
      //   toast.error("Please insert valid ticker!");
      //   setLoading(false);
      //   return;
      // }

      if (!isDifferentProject) {
        if (parentTicker == "") {
          toast.error("Please insert parent ticker!");
          setLoading(false);
          return;
        }
        
        let temp = await getDmtTokenDetail(parentTicker);
        blockdropPrj = temp.result.ins;
  
        setPrj(blockdropPrj)

        if (blockdropPrj == "") {
          toast.error("Please insert valid parent ticker!");
          setLoading(false);
          return;
        }
      }
      else {
        if (prj == "") {
          toast.error("Please insert prj inscription id!");
          setLoading(false);
          return;
        }

        let temp = await getBlockdropPATokenDeployTickers();
        if (!checkValid(ticker, temp)) {
          toast.error("Please insert valid ticker!");
          setLoading(false);
          return;
        }
      }

      if (inscriptionId == "") {
        toast.error("Please insert prv inscription id!");
        setLoading(false);
        return;
      }

      if (maxSupply <= 0) {
        toast.error("Please insert max supply of token!");
        setLoading(false);
        return;
      }
      if (elementId == "") {
        toast.error("Please insert element inscription id!");
        setLoading(false);
        return;
      }

      if (paymentAddress == "") {
        toast.error("Please insert payment receiver address!");
        setLoading(false);
        return;
      }
      if (mintCost < 0) {
        toast.error("Please insert mint cost!");
        setLoading(false);
        return;
      }
      if (dt == "") {
        toast.error("Please insert data type!");
        setLoading(false);
        return;
      }
      if (walletLimit < 1) {
        toast.error("Please insert limit per wallet!");
        setLoading(false);
        return;
      }
      if (password == "") {
        toast.error("Please insert password!");
        setLoading(false);
        return;
      }
      if (password != confirmPassword) {
        toast.error("Password does not match!");
        setLoading(false);
        return;
      }

      if (type == 1) {
        try{
          const data = await getInscriptionContent(elementId);
          const res = data.split('.');
          if (res.length == 3 || res.length == 4) {
            setPattern(data);
          }
          else 
          {
            toast.error("Please insert valid element inscription id!");
            setLoading(false);
            return;
          }
        }
        catch(e) {
          toast.error("Please insert valid element inscription id!");
          setLoading(false);
          return;
        }
      }

      setShowFeeRateCard(true);
    }
    else {
      if (ticker == "") {
        toast.error("Please insert ticker!");
        setLoading(false);
        return;
      }
      if (isMintTickerValid == 0) {
        toast.error("Please insert valid ticker!");
        setLoading(false);
        return;
      }

      if (blk == 0 || blk == "") {
        toast.error("Please insert blk!");
        setLoading(false);
        return;
      }

      setShowFeeRateCard(true);
      setLoading(false);
    }
    setLoading(false);
  }

  const checkValid = (value, data) => {
    for(let temp of data) {
      if (value.toUpperCase() == temp.ticker.toUpperCase()) return 1;
    }
    return 0;
  }

  const calculateFeeRandom = (feeRate, _mintCount, dataSize = contentLength ) => {
    let mintCount = parseInt(_mintCount);
    if (mintCount <= 0 || mintCount == NaN) mintCount = 1;
    let base_size = 160;
    let prefix = 160;
    let txsize = prefix + Math.floor(dataSize / 4);
    let inscriptionLength = mintCount;
    let inscriptionFee = padding ;
    let networkFee = Math.floor(feeRate * txsize * 1.1);
  
    let royaltyFee = royaltyToken * mintCount + royalty_pa * isLoyalty;

    let serviceFee = 0;
    let totalFee = inscriptionFee * mintCount + networkFee * mintCount + royaltyFee;
    return {
      "inscriptionFee": inscriptionFee,
      "networkFee": networkFee,
      "serviceFee": serviceFee,
      "royaltyFee": royaltyFee,
      "totalFee": totalFee
    }
  }  

  const handleMint = async () => {
    setLoading(true);
    setShowFeeRateCard(false);

    if (!isValidTaprootAddress(receiveAddress)) {
      toast.error("Please insert valid taproot address!");
      setLoading(false);
      return;
    }

    if (type == 2) {
      if (royaltyWhitelist == 1) {
        let tempResults = await getBlockdropPATokenWhitelistAddressCheck(royaltyNumber, receiveAddress) 

        if (tempResults.data.length == 0) {
          toast.error("This is not a whitelisted address!");
          setLoading(false);
          return;
        }
      }
      
      let tempData = await latestBlockdropTokenRandom(receiveAddress, royaltyNumber);
      let tempSum = 0;
      if (tempData.sum == null)
        tempSum = 0;
      else
        tempSum = tempData.sum;

      if (tempSum + amount > royaltyWalletLimit) {
        toast.error("Amount will exceed the wallet limit amount!");
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    setShow(true);
    setInscriptionStatus(false);

    const results = await getPaymentHistoryNew();
    const toggleValue = results[0].value;
    let fundingAddress = '';

    if (toggleValue == 0)
      fundingAddress = tippingAddress;
    else
      fundingAddress = ownerAddress;

    let isSuccess = true;

    let inscriptionFee = feeValues["inscriptionFee"] + feeValues["networkFee"];
    let fundingAmount = feeValues["serviceFee"] + feeValues["royaltyFee"];

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";
    let salts = "";
    let tickerPubKey = "";

    for(let i = 0; i< amount; i++)
    {
      let text = ""
      if (type == 0) {
        let tempResults = await getBlockdropPRVInscriptionContent(ticker);
        if (tempResults.status == "success") {
          tickerPubKey = tempResults.pub;
          text = tempResults.data;
        }
        else {
          setShow(false);
          setInscriptionStatus(false);
          toast.error("Error occured!");
          return;
        }
      } 
      else if (type == 1){
        if (dmtId == "") {
            text = `{ "p": "tap","op": "dmt-deploy","tick": "${ticker}","elem": "${elementId}","prv" : "${inscriptionId}", "dt" : "${dt}", "prj" : "${prj}"}`
        }
        else {
            text = `{ "p": "tap","op": "dmt-deploy","tick": "${ticker}","elem": "${elementId}","prv" : "${inscriptionId}", "dt" : "${dt}", "id" : "${dmtId}", "prj" : "${prj}"}`
        }
      }
      else {
        text = await getBlockdropSignMintTextRandom(receiveAddress, blk, royaltyNumber, depId);
        let textJson = JSON.parse(text);
      }

      console.log(text);

      salts = text;

      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    recipientList.push({
      address: fundingAddress,
      amountSats: BigInt(fundingAmount),
    })

    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // get payment utxos

    let tempfeerate = feerate;
    if (wallet.domain == "xverseWallet") tempfeerate = Math.ceil(feerate * 1.2);

    const paymentUtxos = await getPaymentUtxosRandom(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, royalty_pa * isLoyalty, royaltyAddress, royaltyToken * amount , wallet.paymentPublicKey, tempfeerate, amount, wallet.domain)

    // const paymentUtxos = await getPaymentUtxosRandom(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, 0, royaltyAddress, royaltyToken * amount , wallet.paymentPublicKey, tempfeerate, amount, wallet.domain)

    if (paymentUtxos.status == "fail") {
      alert("Insufficient balance.");
      setLoading(false);
      setShow(false);
      isSuccess = false;
      
      if (type == 0) {
        await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
      }
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: async () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                if (type == 0) {
                  await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
                }
                setShow(false);
            },
          });
        }
        else {
            alert("Insufficient balance.");
            setLoading(false);
            setShow(false);
            isSuccess = false;
            if (type == 0) {
              await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
            }
            return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: async () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                if (type == 0) {
                  await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
                }
                setShow(false);
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          if (type == 0) {
            await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
          }
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      if (type == 0) {
        await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
      }
      return;
    }

    if (isSuccess) {
          // save private key and public key
      await registerBlockdropPaymentRandom(privkey, receiveAddress, royaltyNumber, salts, "");
      
      if (type == 1) {
        let params = {
          ticker : ticker,
          paymentAddress : paymentAddress,
          mintCost : parseInt(mintCost),
          mintLimit : limitPerMint,
          maxSupply : maxSupply,
          address : wallet.nostrOrdinalsAddress,
          limitPerMint : walletLimit,
          passwordKey : password,
          elem: elementId,
          pattern: pattern,
          prj: prj,
          parentTicker: parentTicker,
          isDifferentProject: true
        }
        await saveBlockdropPATokenDetail(params);
      }

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      if (type == 2)
        await registerBlockdropTokenRandom(receiveAddress, transactionData[0].txid + ":" + 0, royaltyNumber, blk);

      await waitSomeSeconds(1);

      for (let i = 0; i < inscriptions.length; i++) {
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
        await waitSomeSeconds(2);
      }
    }
    else {
      if (type == 0) {
        await cancelBlockdropPRVInscriptionContent(ticker, tickerPubKey);
      }
    }

    await savePaymentHistoryNew(1 - toggleValue);

    setLoading(false);
    setInscriptionStatus(true);
  }

  
  const inscribe = async(inscription, vout, txid2, amt2, seckey) => {
    let _toAddress;
    let _script;
    let toAddress = receiveAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if (type == 0) {
      setPrvInscriptionId(_txid2 + "i0");
    }
    else {
      setPrvInscriptionId("");
    }

    if(_txid2.includes('descendant'))
    {
        include_mempool = false;
        inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {
        JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
          toast.success("Copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy: ', err);
      }
    );
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-start items-center mb-10 min-h-[600px]">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      }
      <div className="flex flex-col items-center gap-5 sm:mt-[80px] mt-10">
        <div className="text-white font-bold sm:text-[32px] text-[20px] text-center w-full font-title">
          TAP DMT | BLOCKDROP | INSCRIBE
        </div>
        <div className="flex flex-row gap-2 justify-center max-w-[500px] w-full">
          <button className = {`${type == 0 ? 'bg-primary' : 'bg-gray-700'} hover:bg-primary-hover sm:text-[16px] text-[12px] text-white rounded-md px-6 py-2 font-bold`} onClick={() => handleType(0)}>Prv Auth</button>
          <button className = {`${type == 1 ? 'bg-primary' : 'bg-gray-700'} hover:bg-primary-hover sm:text-[16px] text-[12px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleType(1)}>Deploy</button>
          <button className = {`${type == 2 ? 'bg-primary' : 'bg-gray-700'} hover:bg-primary-hover sm:text-[16px] text-[12px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleType(2)}>Mint</button>
        </div>
      </div>
      <div className="flex flex-row bg-gray-900 rounded-md max-w-[500px] w-full mt-2 px-5 py-10">
      {
        type == 0 ? 
        <div className="flex flex-col gap-6 w-full items-center">
          <div className="flex flex-row items-center max-w-[300px] w-full">
            <div className="bg-gray-700 rounded-md rounded-r-none rounded-l px-2 py-2">dmt-</div>
            <Input className = "rounded-l-none" label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { handleTicker(e.target.value)}}/>
          </div>
          <button className = {`bg-primary hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleSubmit(0)}>CREATE</button>
          <div className="text-primary max-w-[400px] sm:text-[14px] text-[12px] text-justify">
            Requirement: After the Prv Auth Inscription ID is created and confirmed, you must tap it by sending the inscription to yourself.
          </div>
        </div> 
        :
        <>
        </>
      }
      {
        type == 1 ? 
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-row items-center max-w-[300px] w-full relative my-3">
            <div className="bg-gray-700 rounded-md rounded-r-none rounded-l px-2 py-2">dmt-</div>
            <Input label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { handleDeployTicker(e.target.value)}} className = "rounded-l-none "/>
            {
              isDeployTickerValid ?
              <div className="text-primary absolute right-5 top-2 font-bold">
                <CheckIcon strokeWidth={2} className="h-5 w-5" /> 
              </div>
              :
              <></>
            }
          </div>

          <div className="max-w-[300px] w-full mx-[-24px]">
            <Checkbox 
              color="pink" 
              label= {
                <div>
                  <Typography color="white" className="font-medium">
                    Parent is not DMT PRJ
                  </Typography>
                </div>
              }
              checked = {isDifferentProject}
              onChange = {(e) => {setIsDifferentProject(e.target.checked)}}
            />
          </div>
          {
            isDifferentProject ? 
            <>
              <div className="max-w-[300px] w-full mb-3">
                <Input label="PRJ ID (inscription id)" type="text" color="white" value={prj} onChange={(e) => { setPrj(e.target.value)}}/>
              </div>

            </>  :
            <div className="flex flex-row items-center max-w-[300px] w-full relative mb-3">
              <div className="bg-gray-700 rounded-md rounded-r-none rounded-l px-2 py-2">dmt-</div>
              <Input label="Parent Ticker" type="text" color="white" value={parentTicker} onChange={(e) => { handleParentTicker(e.target.value)}} className = "rounded-l-none "/>
              {/* {
                isParentTickerValid ?
                <div className="text-primary absolute right-5 top-2 font-bold">
                  <CheckIcon strokeWidth={2} className="h-5 w-5" /> 
                </div>
                :
                <></>
              } */}
            </div>
          }

          <div className="max-w-[300px] w-full my-3">
            <Input label="DMT Prv Auth Inscription ID" type="text" color="white" value={inscriptionId} onChange={(e) => { handleInscriptionId(e.target.value)}}/>
          </div>

          <div className="max-w-[300px] w-full my-3">
            <Input label="Element Inscription ID" type="text" color="white" value={elementId} onChange={(e) => { handleElementId(e.target.value)}}/>
          </div>
          

          <div className="max-w-[300px] w-full my-3">
            <Menu>
              <MenuHandler>
                <div className="border-solid border-[1px] rounded-md px-3 py-[6px] border-white flex flex-row justify-between items-center cursor-pointer">
                  <span>{dt == "" ? "dt (Data Type)" : dt}</span>
                  <ChevronDownIcon strokeWidth={2} className="h-4 w-4" />
                </div>
              </MenuHandler>
              <MenuList className="bg-black border-black text-white w-[200px]">
                {
                  deployDataTypes.map((data) => 
                    <MenuItem onClick={() => handleDataType(data)} key={uuidv4()}>{data}</MenuItem>
                  )
                }
              </MenuList>
            </Menu>
          </div>

          <div className="max-w-[300px] w-full my-3">
            <Input label="Payment receiver address" type="text" color="white" value={paymentAddress} onChange={(e) => { handlePaymentAddress(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full my-3">
            <Input label="Mint Price (in sats)" type="number" color="white" value={mintCost} onChange={(e) => { handleMintCost(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full my-3">
            <Input label="Limit per wallet" type="number" color="white" value={walletLimit} onChange={(e) => { handleWalletLimit(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full my-3">
            <Input label="Admin Password" type="password" color="white" value={password} onChange={(e) => { handlePassword(e.target.value)}}/>
          </div>
          <div className="max-w-[300px] w-full my-3">
            <Input label="Confirm Admin Password" type="password" color="white" value={confirmPassword} onChange={(e) => { handleConfirmPassword(e.target.value)}}/>
          </div>
          <button className = {`bg-primary hover:bg-primary-hover text-[16px] text-white rounded-md px-8 my-3 py-2 font-bold`} onClick={() => handleSubmit(1)}>DEPLOY</button>
        </div> 
        :
        <></>
      }
      {
        type == 2 ? 
        <div className="flex flex-col gap-6 w-full items-center">
          <div className="flex flex-row items-center max-w-[300px] w-full relative">
            <div className="bg-gray-700 rounded-md rounded-r-none rounded-l px-2 py-2">dmt-</div>
            <Input label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { handleMintTicker(e.target.value)}} className = "rounded-l-none"/>
            {
              isMintTickerValid ?
              <div className="text-primary absolute right-5 top-2 font-bold">
                <CheckIcon strokeWidth={2} className="h-5 w-5" /> 
              </div>
              :
              <></>
            }
          </div>
          <div className="flex flex-row gap-1 items-center bg-primary px-4 py-2 cursor-pointer rounded-md font-bold" onClick={() => handleShowBlockSearch()}>
            <MagnifyingGlassIcon strokeWidth={2} className="h-5 w-5" /> 
            <div>Open Blockpad</div>
          </div>
          {/* <div className="max-w-[300px] w-full relative">
            <Input label="blk" type="text" color="white" value={blk} onChange={(e) => { handleBlk(e.target.value)}}/>
            <div className="flex flex-row gap-1 items-center bg-primary p-1 absolute right-[6px] cursor-pointer top-[4px] rounded-md font-bold" onClick={() => handleShowBlockSearch()}>
              <MagnifyingGlassIcon strokeWidth={2} className="h-5 w-5" /> 
              <div>Open Blockpad</div>
            </div>
          </div>
          <button className = {`bg-primary hover:bg-primary-hover text-[16px] text-white rounded-md px-8 py-2 font-bold`} onClick={() => handleSubmit(2)}>MINT</button> */}
        </div> 
        :
        <></>
      }
      </div>
      <Dialog
        open={showBlockSearch}
        className="bg-gray-900 border-gray-600 border-solid border-[1px]"
        size={"xl"}
      >
        <DialogHeader>
          <div className="text-primary font-bold sm:text-[32px] text-[24px] text-center w-full mt-3">
            Select Block Number
          </div> 
        </DialogHeader>
        <DialogBody>
          <div className='flex flex-col gap-3 w-full items-center'>
            <BlockSearch handle = {handleBlk} ticker = {ticker} data={tickerDetail}/>
            <button className="bg-gray-600 rounded-md py-2 sm:min-w-[200px] min-w-[120px] my-3 text-white" onClick={() => setShowBlockSearch(false)}>CLOSE</button>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={showFeeRateCard}
        className="bg-gray-900 border-gray-600 border-solid border-[1px]"
        size={"xs"}
      >
        <DialogHeader>
          {
            type == 0 ? 
            <div className="text-primary font-bold text-[32px] text-center w-full mt-3">
              DMT PRV AUTH
            </div> : <></>
          }
          {
            type == 1 ? 
            <div className="text-primary font-bold text-[32px] text-center w-full mt-3">
              Deploy
            </div> : <></>
          }
          {
            type == 2 ? 
            <div className="text-primary font-bold sm:text-[32px] text-[24px] text-center w-full mt-3">
              Mint DMT-{ticker} Blk {blk}
            </div> : <></>
          }
        </DialogHeader>
        <DialogBody>
          <div className='flex flex-col gap-3 w-full items-center'>
            <div className="max-w-[360px] w-full">
              <Input label="Inscription Receiver Address" type="text" color="white" value={receiveAddress} onChange={(e) => { handleReceiveAddress(e.target.value)}}/>
            </div>
            <div className="text-left w-full text-gray-600 mt-2 sm:text-[18px] text-[14px]">
              Select the network fee you want to pay:
            </div>
            <div className="grid grid-cols-3 gap-3 w-full">
              <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
              <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              <FeeRateCard header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
            </div>
            {
              feeRateMode == "Custom" ? 
              <div className="w-full">
                <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
              </div>
              : 
              <></>
            }
            <div className="m-auto md:w-[400px] w-[260px]">
              <div className="flex flex-row justify-between mt-1 sm:text-[20px] text-[14px]">
                <div className="font-bold">Total Cost</div>
                <div className="text-right font-bold text-blue-600">{feeValues["totalFee"] / Math.pow(10, 8)} BTC</div>
              </div> 
            </div>
            <div className="flex flex-row w-full justify-center gap-3 ">
              <button className="bg-primary hover:bg-primary-hover rounded-md py-2 sm:min-w-[200px] min-w-[120px] my-3 text-white" onClick={handleMint}>SUBMIT</button>
              <button className="bg-gray-600 rounded-md py-2 sm:min-w-[200px] min-w-[120px] my-3 text-white" onClick={() => setShowFeeRateCard(false)}>CLOSE</button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={show}
        size={"sm"}
      >
        <DialogHeader>
          {
            inscriptionStatus ? <>
                {
                  type == 1 ? 
                  <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 text-[32px] font-bold text-primary">
                    <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> 
                    <span className="text-primary sm:text-[32px] text-[20px]">Deployment successful.</span>
                  </div>
                  :
                  <></>
                }
                {
                  type == 0 ? 
                  <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 text-[32px] font-bold text-primary">
                    <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> 
                    <span className="text-primary sm:text-[32px] text-[20px]">DMT PRV AUTH creation successful.</span>
                  </div>
                  :<></>
                }
                {
                  type == 2 ? 
                  <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 text-[32px] font-bold text-primary">
                    <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> 
                    <span className="text-primary sm:text-[32px] text-[20px]">{ticker} Blk {blk} successfully minted!</span>
                  </div>
                  :<></>
                }
              </>
              :
              <div className="flex flex-row w-full justify-center mt-6 sm:text-[32px] text-[20px] font-bold text-primary">
                Inscribing Now
              </div>
          }
        </DialogHeader>
        <DialogBody>
          <div className={`flex flex-col gap-3 w-full ${inscriptionStatus ? 'min-h-[100px]' : 'min-h-[160px]'}`}>
            {
              inscriptionStatus ? 
              <div className="flex flex-col items-center gap-4">
                {
                  type == 1 ?
                  <div className="text-black sm:text-[18px] text-[14px]"> 
                    <span>You may now access the admin area at </span>
                    <a href={`https://tapwallet.io/inscribe/blockdrop/${ticker}/admin`} className="sm:m-[0px]" target="_blank">
                      <span className="break-all cursor-pointer text-blue-900 font-bold">https://tapwallet.io/inscribe/blockdrop/{ticker}/admin</span>
                    </a>
                    <span>&nbsp;using the admin password.</span>
                  </div>
                  :
                  <></>
                }
                {
                  type == 0 ?
                  <div className="flex flex-col gap-4 text-black sm:text-[18px] text-[14px]"> 
                    <div>
                      Once confirmed, activate it by sending the inscription to yourself.
                    </div>
                    <div>DMT PRV AUTH ID: &nbsp;&nbsp;
                      <Tooltip content="Copy">
                        <span className="text-blue-900 underline font-bold cursor-pointer" onClick={() => handleCopy(prvInscriptionId)}>{formatAddressMicro(prvInscriptionId)}</span>
                      </Tooltip>
                    </div>
                  </div>
                  :
                  <></>
                }
                {/* <div className="flex flex-row justify-center w-full text-[20px] px-2 font-semibold text-green-600 text-center">
                  <CheckCircleIcon strokeWidth={2} className="h-24 w-24" />
                </div> */}
                <></>
                <div className="w-[180px] mt-5">
                  <Button
                    onClick={() => {
                      setShow(false);
                      setInscriptionStatus(false);
                    }}
                    fullWidth
                  >Close</Button>
                </div>
              </div>
              :
              <div className="flex flex-col items-center gap-5">
                <div className="flex flex-row justify-center w-full font-semibold text-[#616161] sm:text-[20px] text-[14px] px-6 text-center">
                  Don´t close this window before the transaction is complete.
                </div>
                <Spinner className="h-12 w-12" />
              </div>
            }
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
