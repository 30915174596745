const apiUrl = "https://api.tapwallet.io";

export const getBitmapByAddress = async (address, limit, offset) => {
  const url = `${apiUrl}/bitmap/get?address=${address}&limit=${limit}&offset=${offset}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}