import React from 'react'
import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

import { 
  ButtonGroup, 
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
  IconButton,
  Slider,
  Select, 
  Option,
  Switch
} from "@material-tailwind/react";

import { getPATokenDetail, savePATokenDetail, updatePATokenPassword, checkPassword, getPATokenWhitelistAddresses, savePATokenWhitelistAddresses } from '../util/new-api';
import toast, { Toaster } from 'react-hot-toast';

export default function MintPATokenAdmin() {
  const { param } = useParams();

  const divRef = useRef(null);

  const [flag, setFlag] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [old1Password, setOld1Password] = useState("");
  const [updatedPassword, setUpdatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState(false);
  const [whitelist, setWhitelist] = useState(false);
  const [whitelistAddresses, setWhitelistAddresses] = useState([]);
  const [mintCost, setMintCost] = useState(0);
  const [limitPerMint, setLimitPerMint] = useState(0);
  const [passwordValid, setPasswordValid] = useState(0);

  useEffect(() => {
    getPATokenAdmin();
  }, [])

  const getPATokenAdmin = async () => {
    const results = await getPATokenDetail(param);
    if (results.data.length == 0) {
      setFlag(0);
    } else {
      setFlag(1);
      setPaymentAddress(results.data[0].paymentAddress);
      setLimitPerMint(results.data[0].limitPerMint);
      setMintCost(results.data[0].mintCost);
      setStatus(results.data[0].is_locked == 0 ? false : true);
      setNumber(results.data[0].number);
      setWhitelist(results.data[0].is_whitelist == 0 ? false : true);
    }
  }

  // useEffect(() => {
  //   getWhitelistAddresses();
  // }, [whitelist, divRef.current])

  const getWhitelistAddresses = async () => {
    if (divRef.current) {
      let datas = await getPATokenWhitelistAddresses(number);
      let result = "";
      for(let data of datas.data) {
        result = result + "<div>" + data.address + ",</div>";
      }
      console.log(result);
      divRef.current.innerHTML = result;
    }
  }

  const handleLogin = async () => {
    let results = await checkPassword(oldPassword, param);
    if (results.status == "success") {
      setPasswordValid(1);
    }
    else {
      setPasswordValid(0);
      toast.error("Password is incorrect!");
    }
  }

  const handleStatus = () => {
    setStatus(!status);
  }

  const handleWhitelist = () => {
    setWhitelist(!whitelist);
  }

  const handleSave = async () => {
    if (paymentAddress == "") {
      toast.error("Please insert payment receiver address!");
      return;
    }
    if (mintCost < 0) {
      toast.error("Please insert mint cost!");
      return;
    }
    if (limitPerMint < 1) {
      toast.error("Please insert limit per wallet!");
      return;
    }
   
    let params = {
      ticker: param,
      passwordKey: oldPassword,
      paymentAddress: paymentAddress,
      mintCost: mintCost,
      limitPerMint: limitPerMint,
      is_locked: status ? 1 : 0,
      is_whitelist: whitelist ? 1 : 0
    }
    
    await savePATokenDetail(params);

    if (whitelist == true) {
      let addresses = handleWhiteListAddresses();
      params = {
        number: number,
        addresses: addresses,
        passwordKey: oldPassword,
        ticker: param
      }

      await savePATokenWhitelistAddresses(params);
    }

    toast.success("Successfully updated!");
  }

  const handlePassword = async () => {
    if (updatedPassword != confirmPassword) {
      toast.error("Password does not match!");
      return;
    }
    let results = await updatePATokenPassword(old1Password, updatedPassword, param);
    if (results.status == "success") {
      toast.success("Successfully updated!");
    }
    else {
      toast.error("Password is incorrect!");
    }
  }

  const handleWhiteListAddresses = () => {
    if (divRef.current) {
      const content = divRef.current.innerHTML;
      const cleanedContent = content.replace(/<\/?div>/g, '').replace(/\s+/g, '');
      const results = cleanedContent.split(',');
      const temp = results.filter(data => data != "");
      return temp;
    }
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-start items-center mb-10 min-h-[600px]">
      {
        flag == 0 ? 
        <div className="mt-10 text-gray-800 font-bold text-[32px]">
          No available token.
        </div>
        :
        <div className="mt-10 flex flex-col gap-3 items-center">
          <div className="text-primary text-[32px] font-bold text-center">{ param.toUpperCase() }</div>
          {
            passwordValid == 0 ? 
            <div className="flex flex-col gap-8 mt-10 items-center bg-gray-900 p-8 rounded-md">
              <div className="sm:w-[400px] w-[320px]">
                <Input label="Insert Password" type="password" color="white" value={oldPassword} onChange={(e) => { setOldPassword(e.target.value)}}/>
              </div>
              <div className="px-10 py-2 bg-primary text-white rounded-md font-bold cursor-pointer" onClick={handleLogin}>LOGIN</div>
            </div>
            :
            <>
              <div className="flex flex-col gap-5 mt-10 bg-gray-900 p-5 rounded-md w-full items-center">
                <div className="sm:w-[400px] w-[320px]">
                  <Input label="Insert Old Password" type="password" color="white" value={old1Password} onChange={(e) => { setOld1Password(e.target.value)}}/>
                </div>
                <div className="sm:w-[400px] w-[320px]">
                  <Input label="Insert New Password" type="password" color="white" value={updatedPassword} onChange={(e) => { setUpdatedPassword(e.target.value)}}/>
                </div>
                <div className="sm:w-[400px] w-[320px]">
                  <Input label="Confirm New Password" type="password" color="white" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value)}}/>
                </div>
                <div className="flex flex-row justify-center">  
                  <div className="w-[300px] px-10 py-2 bg-primary text-white rounded-md font-bold cursor-pointer text-center" onClick={handlePassword}>Change Password</div>
                </div>
              </div>
              <div className="flex flex-col gap-5 mt-10 bg-gray-900 p-5 rounded-md w-full items-center">
                <div className="sm:w-[400px] w-[320px]">
                  <Input label="Payment receiver address" type="text" color="white" value={paymentAddress} onChange={(e) => { setPaymentAddress(e.target.value)}}/>
                </div>
                <div className="sm:w-[400px] w-[320px]">
                  <Input label="Mint Price (in sats)" type="number" color="white" value={mintCost} onChange={(e) => { setMintCost(e.target.value)}}/>
                </div>
                <div className="sm:w-[400px] w-[320px]">
                  <Input label="Limit per wallet" type="number" color="white" value={limitPerMint} onChange={(e) => { setLimitPerMint(e.target.value)}}/>
                </div>
                <div className="sm:w-[400px] w-[320px] flex flex-row gap-3 items-center">
                  <div className="text-white font-bold text-[18px] mr-10 sm:w-[240px] w-[160px]">Mint Status</div>
                  <Switch color="red" checked = {status} onChange={handleStatus}/>
                </div>
                <div className="sm:w-[400px] w-[320px] flex flex-row gap-3 items-center">
                  <div className="text-white font-bold text-[18px] mr-10 sm:w-[240px] w-[160px]">WL Addresses (OFF=ALL)</div>
                  <Switch color="red" checked = {whitelist} onChange={handleWhitelist}/>
                </div>

                {
                  whitelist ? 
                  <>
                    <div className="flex flex-row justify-end">
                      <div className="px-4 py-1 bg-primary rounded-md cursor-pointer" onClick={getWhitelistAddresses}>Refresh</div>
                    </div>
                    <div 
                      ref={divRef}
                      className="w-full sm:max-w-[400px] max-w-[320px] border-[1px] border-solid border-white h-[200px] rounded-md p-2 overflow-auto" 
                      contentEditable= "true">
                      
                    </div>
                  </>
                  :
                  <>
                  </>
                }
                <div className="flex flex-row justify-center">  
                  <div className="w-[200px] px-10 py-2 bg-primary text-white rounded-md font-bold cursor-pointer text-center" onClick={handleSave}>SAVE</div>
                </div>
              </div>
            </>
          }
        </div>
      }
    </div>
  )
}
