import React from 'react'

export default function Src20Card(props) {
  const { data } = props;

  return (
    <div className="border-borderColor border-[1px] border-solid bg-gray-900 p-3 rounded-md">
      <img src={data.deploy_img} alt="no image"/>
      <div className="text-primary font-bold text-[20px]">{data.tick.toUpperCase()}</div>
      <div>Amount: {parseInt(data.amt * 100) / 100}</div>
    </div>
  )
}
