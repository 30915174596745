import { combineReducers } from 'redux';
import walletReducer from './walletReducer';
import tapReducer from './tapReducer';
import keyReducer from './keyReducer';

const rootReducer = combineReducers({
  wallet: walletReducer,
  tap: tapReducer,
  key: keyReducer
  // add more reducers as needed
});

export default rootReducer;