import React from 'react'
import { amountFormat } from '../util/format-data';

export default function RuneCard(props) {
  const { data, price } = props;

  const calcBtc = (data, price) => {
    let amountN = parseInt(data.amount);
    let priceN = parseFloat(data.priceFormatted);

    return Math.floor(amountN * priceN) / Math.pow(10, 8);
  }

  const calcPrice = (data, price) => {
    return (Math.floor(parseInt(data.amount) * parseFloat(data.priceFormatted) / Math.pow(10, 7) * price) / 10 + "")
  }
  return (
    <div className = "flex flex-col gap-1 bg-gray-900 border-solid border-gray-700 rounded-md border-[1px] p-2 w-full items-center">
      <div className="flex flex-row mt-6 gap-1 w-full justify-center"> 
        {
          data.imageURI != "" ? 
            <img className= "w-[20px] h-[20px] mt-[2px] rounded-md" src={data.imageURI}/> : <></>
        }
        <div className="font-bold text-white h-[40px] break-words text-center" style={{ maxWidth: "calc(100% - 30px)" }}>
          {data.name}
        </div>
      </div>
      <div className="h-[40px] w-full text-center break-words">  
        <span className="font-bold text-blue-800 text-[20px]">{amountFormat(data.amount)}</span>
        <span className="font-bold text-[20px]">{data.symbol}</span>
      </div>
      <div className="w-full text-center ">  
        <span className="font-bold text-primary text-[16px]">{calcBtc(data, price)}&nbsp;BTC</span>
      </div>
      <div className="w-full text-center ">  
        <span className="font-bold text-primary text-[16px]">${calcPrice(data, price)}&nbsp;</span>
      </div>
    </div>
  )
}
