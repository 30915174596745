import React, {useEffect, useCallback, useState} from 'react'
import { getUnatHolders } from '../../util/api'
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon, ArrowLongDownIcon, ArrowLongUpIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import toast, { Toaster } from 'react-hot-toast';
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button
} from '@material-tailwind/react';
import { formatUnixTime } from '../../util/format-data';
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from '../../util/format-data';
import UnatIndexerDetail from './UnatIndexerDetail';
import { getCat20TokensList } from '../../util/cat20-api';
import { amountFormat, convertTimestamp } from '../../util/format-data';
import { formatInscriptionId } from '../../util/format-data';

export default function Cat20Indexer() {
  const [ticker, setTicker] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [cat20Datas, setCat20Datas] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [detailticker, setDetailTicker] = useState('');
  const [direct, setDirect] = useState(1);
  const [sort, setSort] = useState(3);
  const [showInfo, setShowInfo] = useState(false);
  const [cat20DataDetail, setCat20DataDetail] = useState({});
  
  useEffect(() => {
    fetchTokenData(ticker, page);
  }, [page, order, direct])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const handleKeyChange = (e) => {
    setTicker(e.target.value)
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    try {
      await fetchTokenData(value, 1);
      setPage(1);
    }
    catch (e) {
      console.log(e);
    }
  }, 300), []); 

  const fetchTokenData = async (value, page) => {
    setLoading(true);
    let data = await getCat20TokensList(value, page, limit);
    setCat20Datas(data.data.items);
    setTotal(Math.ceil(data.data.total / limit));
    setLoading(false);
  }

  const handleOrder = (value) => {
    setSort(value);
    setDirect(1 - direct);
  }

  const showDetailIndexer = (value) => {
    setDetailTicker(value);
    setShowDetail(true);
  }

  const handleShowInfo = async (value) => {
    setShowInfo(true);
    setCat20DataDetail(value);
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success("Token Id copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  return (
    <div className="text-white w-full max-w-[1500px] flex flex-col justify-center items-center">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
        showDetail ? <></> : 
        <>
          {
            <div className="flex flex-col gap-2 max-w-[1000px] w-full mt-10">
              { 
                <div className="mt-5 flex flex-row w-full justify-end gap-2">
                  <div>
                    <Input type="text" color="white" label = "Search by Token Name or Id" className="w-[320px]" value = {ticker} onChange = {(e) => {handleKeyChange(e)}}/>
                  </div>
                  {/* <Menu placement="bottom-end">
                    <MenuHandler>
                      <IconButton
                        size="md"
                        variant="outlined"
                        className="border-white"
                      >
                        <FunnelIcon strokeWidth={2} className="h-7 w-7 text-primary" />
                      </IconButton>
                    </MenuHandler>
                    <MenuList className="bg-black border-black text-white">
                      <MenuItem onClick={() => handleOrder(1)}>Descending Time</MenuItem>
                      <MenuItem onClick={() => handleOrder(2)}>Ascending Time</MenuItem>
                      <MenuItem onClick={() => handleOrder(4)}>Descending Holders</MenuItem>
                      <MenuItem onClick={() => handleOrder(3)}>Ascending Holders</MenuItem>
                    </MenuList>
                  </Menu> */}
                </div>
              }
              <div className="flex flex-row px-4 py-2">
                <div className = "md:w-[25%] w-[70%]">Token Name</div>
                <div className = "md:w-[15%] w-[23%] flex flex-row gap-3 items-center">
                    <span>Holders</span>
                </div>
                <div className = "md:w-[25%] hidden md:flex flex-row gap-3 items-center">
                    <span>Progress</span>
                </div>
                <div className = "md:w-[15%] hidden md:flex flex-row gap-3 items-center">
                    <span>Supply</span>
                </div>
                <div className = "md:w-[15%] hidden md:flex flex-row gap-3 items-center">
                    <span>Time</span>
                </div>
                <div className = "md:w-[5%] w-[7%]">
                    
                </div>
              </div>
              {
                cat20Datas.map((data) => 
                  <div 
                    className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg items-center" 
                    key={uuidv4()}
                  >
                    <div className = "md:w-[25%] w-[70%] text-primary font-bold flex flex-row gap-2 items-center cursor-pointer" >
                        <div className="w-[40px] h-[40px] rounded-md bg-primary text-white text-[28px] px-3">{data.tick.substring(0,1)}</div>
                        <div>{data.tick}</div>
                    </div>
                    <div className = "md:w-[15%] w-[23%]">{data.holder}</div>
                    <div className = "md:w-[25%] hidden md:flex flex-row items-center gap-1">
                        <div className="w-[120px] md:block hidden"><Progress  value={Math.ceil(parseInt(data.mint_amount) / parseInt(data.supply) * 100)} color="blue" size="sm" /></div>
                        <div className="text-blue-800">{Math.ceil(parseInt(data.mint_amount) / parseInt(data.supply) * 100)}%</div>
                    </div>
                    <div className = "md:w-[15%] md:block hidden">{amountFormat(data.supply)}</div>
                    <div className = "md:w-[15%] md:block hidden">{convertTimestamp(data.create_time * 1000)}</div>
                    <div className = "md:w-[5%] w-[7%] cursor-pointer hover:text-primary" onClick={() => handleShowInfo(data)}>
                      <InformationCircleIcon strokeWidth={2} className="h-6 w-6" />
                    </div>
                  </div>
                )
              }
            </div> 
          }
          <Dialog
            open={showInfo}
            size={"xs"}
            className="bg-black border-solid border-[1px] border-gray-600"
          >
            <DialogBody>
              {
                infoLoading ?
                <div className="flex flex-col w-full gap-3 p-3 h-[400px]">
                  <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
                    <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
                    <span className="text-[32px] font-bold text-primary">Details</span>
                  </div>
                  <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/>
                </div>
                :
                <div className="flex flex-col justify-center w-full gap-3 p-3">
                  <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
                    <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
                    <span className="text-[32px] font-bold text-primary">Details</span>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Token Name</div>
                    <div className="text-white">{cat20DataDetail.tick}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Symbol</div>
                    <div className="text-white">{cat20DataDetail.symbol}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Token Id</div>
                    <div className="text-white hover:text-blue-800 cursor-pointer sm:block hidden" onClick={() => handleCopy(cat20DataDetail.token_id)}>{formatInscriptionId(cat20DataDetail.token_id)}</div>
                    <div className="text-white hover:text-blue-800 cursor-pointer sm:hidden block" onClick={() => handleCopy(cat20DataDetail.token_id)}>{formatAddress(cat20DataDetail.token_id)}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Supply</div>
                    <div className="text-white">{cat20DataDetail.supply}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Decimals</div>
                    <div className="text-white">{cat20DataDetail.decimals}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Holders</div>
                    <div className="text-white">{cat20DataDetail.holder}</div>
                  </div>
                  
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Limit</div>
                    <div className="text-white">{cat20DataDetail.amount}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Mint Progress</div>
                    <div className="text-white">{Math.ceil(parseInt(cat20DataDetail.mint_amount)/parseInt(cat20DataDetail.supply) * 100)}%</div>
                  </div>
                </div>
              }

              <div className="flex flex-row-reverse w-full mt-2">
                <Button  onClick={() => setShowInfo(false)}>RETURN</Button>
              </div>
            </DialogBody>
          </Dialog>
          {
            total > 1 ? 
            <div className="flex flex-row items-center gap-8 justify-end mt-5">
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doublePrev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
              <Typography color="white" className="font-normal">
                Page <strong className="text-primary">{page}</strong> of{" "}
                <strong className="text-primary">{total}</strong>
              </Typography>
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={next}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doubleNext}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
            :
            <></>
          }
        </>
      }
      
    </div>
  )
}
