import React from 'react'

export default function FeeRateCardNew(props) {
  const {header, rate, active, onClick} = props;
  return (
    <div className={`flex flex-col items-center rounded-md bg-black border-solid border-[2px] py-1 gap-0 cursor-pointer hover:border-primary ${active == header ? ' border-primary' : 'border-white'}`} onClick={onClick}>
      <div className="text-white font-bold">
        {header}
      </div>
      <div className="p-0 text-center">
        <span className="text-blue-600 text-[32px] font-bold">{rate}</span> sats/vB
      </div>
    </div>
  )
}
