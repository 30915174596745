import React from 'react'
import {useState, useEffect} from 'react'
import { getAllDomains, getBtcPrice } from '../../util/api'
import NameCard from '../TokenSaleCard/NameCard';
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Spinner
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  useLocation
} from "react-router-dom";

export default function Names(props) {

  const { keyAddress } = props;
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [price, setPrice] = useState(0);
  const [nameDatas, setNameDatas] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        if (keyAddress != "") {
          let results = await getAllDomains(keyAddress, page, limit);
          setNameDatas(results.data);
          setTotal(Math.ceil(results.total / limit));
        }
        else {
          setNameDatas([]);
          setTotal(0);
        }
      }
      catch (e) {
        console.log(e);
      }
    }

    fetchDomains();
  }, [keyAddress, page])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  return (
    <div className="flex flex-col w-full">   
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 3xl:grid-cols-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-5">
        {
          total > 0 ? 
            nameDatas.map((data) => 
              <NameCard key={uuidv4()} data = {data} price={price} type={4} isSale = {location.pathname.includes('/profile')} keyAddress={keyAddress}/> 
            ) : <>No Names.</>
        }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
      
    </div>
  )
}
