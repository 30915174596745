import React, {useState, useEffect, useCallback} from 'react'
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon, ArrowLongDownIcon, ArrowLongUpIcon, InformationCircleIcon } from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Popover,
  PopoverHandler,
  PopoverContent,
  Spinner,
  Progress
} from "@material-tailwind/react";

import { debounce } from 'lodash';
import { getDmtTokenDetail } from '../util/tap-api';
import { getDmtBlock, getDmtBlockRandom, getBlockdropBlock, getBlockdropBlockRandom } from '../util/new-api';
import { formatInscriptionIdMini } from '../util/format-data';
import toast, { Toaster } from 'react-hot-toast';

export default function BlockSearch(props) {
  const { handle, ticker, data, depId, dt, isBlockdrop, handleRefresh } = props;

  const [loading, setLoading] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(window.innerWidth < 600 ? 20 : 300);
  // const [patternField, setPatternField] = useState("");
  // const [patternValue, setPatternValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [progress, setProgress] = useState(50);

  const [totalCount, setTotalCount] = useState(0);
  const [mintCount, setMintCount] = useState(0);
  const [avaiableCount, setAvailableCount] = useState(0);


  useEffect(() => {
    if (dt == "n" || dt == "h")
      handleBlock(page);
  }, [page, dt, handleRefresh])

  const handleBlock = async (offset, value = searchKey) => {
    let patternField = "";
    let patternValue = "";
    const patterns = data.pattern.split(".");

    if (patterns.length >3) {
      patternField = patterns[2];
      patternValue = patterns[1];
    }
    else {
      patternField = patterns[1];
    }

    if (patternField != "") {
      let temp = parseInt(value);
      if (isNaN(temp) || temp == undefined) temp = 0;
      if (temp < 0) temp = 0;
      temp = temp.toString();

      setLoading(true);
      let res;
      if (isBlockdrop)
        res = await getBlockdropBlock(data.number, patternField, patternValue, temp, offset, limit, dt);
      else
        res = await getDmtBlock(data.number, patternField, patternValue, temp, offset, limit, dt);

      if (temp == 0)
        setTotal(Math.ceil(res.total / limit));
      else setTotal(0);

      setBlocks(res.data);
      setMintCount(res.mint);
      setTotalCount(res.total);
      setAvailableCount(res.total - res.mint);
      if (res.total == 0)
        setProgress(parseInt(0));
      else
        setProgress(parseInt((res.mint / res.total) * 10000) / 100);
      setLoading(false);
    }
  }

  const handleBlockRandom = async () => {
    if (totalCount <= mintCount) {
      toast.error("No available blocks");
      return;  
    }

    let patternField = "";
    let patternValue = "";
    const patterns = data.pattern.split(".");

    if (patterns.length >3) {
      patternField = patterns[2];
      patternValue = patterns[1];
    }
    else {
      patternField = patterns[1];
    }

    if (patternField != "") {
      setLoading(true);
      let res;
      
      if (isBlockdrop)
        res = await getBlockdropBlockRandom(data.number, patternField, patternValue, dt);
      else
        res = await getDmtBlockRandom(data.number, patternField, patternValue, dt);

      if (res.data[0].blk == null) {
        handle(res.data[0].height);
      }

      setLoading(false);
    }
  }

  const handlePattern = async () => {
    const patterns = data.pattern.split(".");
    if (patterns.length >3) {
      setPatternField(patterns[2]);
      setPatternValue(patterns[1]);
    }
    else {
      setPatternField(patterns[1]);
    }
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const handleClick = (data) => {
    if (data.blk == null) {
      handle(data.height);
    }
  }

  const handleSearch = (value) => {
    setSearchKey(value);
    debouncedSearch(value)
  }

  const debouncedSearch = useCallback(debounce(async (value) => {
    await handleBlock(1, value);
  }, 500), [dt]); 

  return (
    <div className="flex flex-col gap-4 w-full mt-5">
      <div className="flex sm:flex-row flex-col justify-between gap-2">
        <div className="sm:text-[32px] text-[24px] text-primary font-bold">DMT-{data.ticker}</div>
        <div className="flex flex-col gap-1 w-full max-w-[400px]">
          <div className="mb-2 flex items-center justify-between gap-4">
            <div>Total Mint Progress</div>
            <div>{progress}%</div>
          </div>
          <Progress value={progress} size="lg" color="blue"/>
        </div>
      </div>
      <div className="sm:block hidden">
        <div className="flex flex-row gap-2 w-full max-w-[800px] text-gray-600">
          <div className="w-[30%]">Element</div>
          <div className="w-[30%]">DeploymentId</div>
          <div className="w-[20%]">Phase Mint Price</div>
          <div className="w-[20%]">Limit Per Wallet</div>
        </div>
        <div className="flex flex-row gap-2 w-full max-w-[800px]">
          <div className="w-[30%]">{data.pattern}</div>
          <div className="w-[30%]">
            <a href={`https://ordinals.com/inscription/${depId}`} target="_blank" rel="noopener noreferrer">
              <div className="cursor-pointer">{formatInscriptionIdMini(depId)}</div>
            </a>
          </div>
          <div className="w-[20%]">{data.mintCost} sats</div>
          <div className="w-[20%]">{data.limitPerMint}</div>
        </div>
      </div>
      <div className="sm:hidden block w-full">
        <div className="flex flex-row w-full justify-between">
          <div className="text-gray-600">Element</div>
          <div >{data.pattern}</div>
        </div>
        <div className="flex flex-row w-full justify-between">
          <div className="text-gray-600">DeploymentId</div>
          <a href={`https://ordinals.com/inscription/${depId}`} target="_blank" rel="noopener noreferrer">
              <div className="cursor-pointer">{formatInscriptionIdMini(depId)}</div>
            </a>
        </div>
        <div className="flex flex-row w-full justify-between">
          <div className="text-gray-600">Phase Mint Price</div>
          <div >{data.mintCost} sats</div>
        </div>
        <div className="flex flex-row w-full justify-between">
          <div className="text-gray-600">Limit Per Wallet</div>
          <div >{data.limitPerMint}</div>
        </div>
      </div>
      <div className="max-w-[360px] w-full">
        <Input label="Search Block" type="text" color="white" value={searchKey} onChange = {(e) => {handleSearch(e.target.value)}}/>
      </div>
      <div className="flex sm:flex-row-reverse sm:justify-between sm:items-center flex-col gap-2 ">
        <div className="px-4 py-2 bg-primary rounded-md text-white font-bold cursor-pointer" onClick={() => {handleBlockRandom()}}>Select Random</div>
        <div className="flex sm:flex-row flex-col sm:gap-5 gap-3">
          <div className="flex flex-row gap-1">
            <div className="bg-yellow-800 p-3"></div>
            <div className="text-white font-bold text-[18px]">Available: (<span className="font-bold text-blue-800">{avaiableCount}</span>)</div>
          </div>
          <div className="flex flex-row gap-1">
            <div className="bg-red-600 p-3"></div>
            <div className="text-white font-bold text-[18px]">Minted: (<span className="font-bold text-blue-800">{mintCount}</span>)</div>
          </div>
        </div>
      </div>
      {
        loading ? 
        <div className="h-[200px] flex flex-row w-full items-center justify-center"><Spinner className="h-12 w-12" /></div>:
        <div>
        <div className="grid xl:grid-cols-20 lg:grid-cols-15 md:grid-cols-10 grid-cols-4 gap-1">
          {
            blocks.map((data) => {
              return (
                <div key= {data.height} className= {`${data.blk == null ? 'bg-yellow-800' : 'bg-red-600'} text-center px-1 py-2 text-black font-bold cursor-pointer text-[14px]`} onClick={() => {handleClick(data)}}>
                  {data.height}
                </div>
              )
            })
          }
        </div>
        {
          blocks.length == 0 ? <div>No blocks</div> :<></>
        }
        </div>
      }
      {
        total > 1 ? 
        <div className="flex flex-row items-center justify-center gap-8 mt-5">
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doublePrev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doubleNext}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
}
