const apiUrl = "https://api.lambdaprotocol.io"

export const getLRC20TokenList = async (offset = 1, limit = 10) => {

  const url = `https://api.lambdaprotocol.io/contracts/lrc-20`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getLRC20TokenDetail = async (key, offset = 1, limit = 10) => {

  const url = `https://api.lambdaprotocol.io/contract/${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getLRC20TokenBalance = async (key, offset = 1, limit = 10) => {

  const url = `https://api.lambdaprotocol.io/balances/${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}