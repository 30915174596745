import React from 'react'

export default function InscriptionCard(props) {
  const { data } = props;

  return (
    <div className="flex flex-col gap-1 bg-gray-900 border-solid border-gray-700 rounded-md border-[1px] p-3 w-full">
      {
        
        <iframe src={data.preview} scrolling="no" sandbox="allow-scripts" loading="lazy" className="w-full sm:min-h-[256px] min-h-[150px] rounded-md" ></iframe>
      }
      
      <a href={`https://ordinals.com/inscription/${data.inscriptionId}`} target="_blank" rel="noopener noreferrer">
        <span className="text-primary hover:text-primary-hover hover:underline font-semibold">#{data.inscriptionNumber}</span>
      </a>
      
    </div>
  )
}
