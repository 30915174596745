import React from 'react'
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { Carousel, IconButton } from "@material-tailwind/react";

import githubImg from '../assets/imgs/github.png';
import mainImg from '../assets/imgs/home-app.png';
import marketplaceImg from '../assets/imgs/marketplace.png';
import explorerImg from '../assets/imgs/explorer.png';

import image1 from '../assets/imgs/carosuel/1.png';
import image2 from '../assets/imgs/carosuel/2.png';
import image3 from '../assets/imgs/carosuel/3.png';
import image4 from '../assets/imgs/carosuel/4.jpeg';
import image5 from '../assets/imgs/carosuel/5.jpeg';
import image6 from '../assets/imgs/carosuel/6.jpeg';
import image7 from '../assets/imgs/carosuel/7.jpeg';
import image8 from '../assets/imgs/carosuel/8.jpg';
import image9 from '../assets/imgs/carosuel/9.jpg';
import image10 from '../assets/imgs/carosuel/10.png';
import image11 from '../assets/imgs/carosuel/11.png';

export default function Wallet() {
  const navigate = useNavigate();
  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-row justify-center items-center">
      <div className="flex lg:flex-row flex-col-reverse sm:gap-[60px] gap-[40px] sm:mt-[60px] mt-[30px]">
        <div className="flex flex-col gap-5 max-w-[500px]">
          <div className="text-primary font-semibold sm:text-[36px] text-[30px] font-title">UNIVERSE WALLET</div>
          <div className="sm:text-[28px] text-[24px]">Home for all your Bitcoin assets.</div>
          <div className="mt-3">Discover Bitcoin's Full Potential with Universe Wallet—Your All-in-One Solution. Manage all your assets in one place, with full support for TAP, DMT, UNAT, and more.</div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mt-10">
            <a href="https://chromewebstore.google.com/detail/tap-wallet/fjalkkkbjffhgdoheannkodafhemfdba" target="_blank">
              <div className="flex flex-row rounded-md border-solid border-[1px] border-gray-500 px-5 py-1 gap-3 min-w-[200px] cursor-pointer items-center">
                <img src="https://next-cdn.unisat.io/_/139/img/icon/chrome.svg"/>
                <div className="flex flex-col">
                  <span className="text-gray-500">Download</span>
                  <span className="hover:text-primary-hover">Chrome Extension</span>
                </div>
              </div>
            </a>
            <a href="https://github.com/tapwallet/tapwallet" target="_blank">
              <div className="flex flex-row rounded-md border-solid border-[1px] border-gray-500 px-5 py-1 gap-3 min-w-[200px] cursor-pointer items-center">
                <img src={githubImg} className="w-[30px] h-[30px]"/>
                <div className="flex flex-col">
                  <span className="text-gray-500">Download</span>
                  <span className = "hover:text-primary-hover">From Github</span>
                </div>
              </div>
            </a>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full">
            <div className="flex flex-row rounded-md border-solid border-[1px] px-5 border-gray-500 w-full py-1 gap-3 min-w-[200px] cursor-pointer items-center" onClick={() => {navigate('/marketplace')}}>
              <img src={marketplaceImg} className="w-[30px] h-[30px] rounded-full"/>
              <div className="flex flex-col">
                <span className="hover:text-primary-hover py-2 pr-[52px]">Marketplace</span>
              </div>
            </div>
            <div className="flex flex-row rounded-md border-solid border-[1px] w-full border-gray-500 px-5 py-1 gap-3 min-w-[200px] cursor-pointer items-center" onClick={() => {navigate('/explorer')}}>
              <img src={explorerImg} className="w-[30px] h-[30px] rounded-full"/>
              <div className="flex flex-col">
                <span className = "hover:text-primary-hover py-2 pr-10">Explorer</span>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-2 w-full">
            <div className="flex flex-row rounded-md w-full sm:py-2 py-3 sm:text-[20px] text-[16px] gap-3 min-w-[200px] cursor-pointer items-center justify-center bg-primary text-white font-bold" onClick={() => {navigate('/tapzero')}}>
              MINT $TAPZERO
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-2 w-full">
            <div className="flex flex-row rounded-md w-full sm:py-2 py-3 sm:text-[20px] text-[16px] gap-3 min-w-[200px] cursor-pointer items-center justify-center bg-primary text-white font-bold" onClick={() => {navigate('/istamps')}}>
              STAMPS SEED ROUND
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-2 w-full">
            <div className="flex flex-row rounded-md w-full sm:py-2 py-3 sm:text-[20px] text-[16px] gap-3 min-w-[200px] cursor-pointer items-center justify-center bg-primary text-white font-bold" onClick={() => {navigate('/inscribe/dmtprvauth')}}>
              DMT | UNAT PRV AUTH BLOCKPAD
            </div>
          </div>
        </div>
        <div className="max-w-[500px]">
          {/* <img src={mainImg} alt=""/> */}
          <Carousel
            className="rounded-xl"
            autoplay = {true}
            autoplayDelay = {3000}
            prevArrow={({ handlePrev }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handlePrev}
                className="!absolute top-2/4 left-4 -translate-y-2/4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
              </IconButton>
            )}
            nextArrow={({ handleNext }) => (
              <IconButton
                variant="text"
                color="white"
                size="lg"
                onClick={handleNext}
                className="!absolute top-2/4 !right-4 -translate-y-2/4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </IconButton>
            )}
          >
            {/* <img
              src= {image8}
              alt="image 2"
              className="h-full w-full object-cover cursor-pointer"
              onClick={() => navigate('/inscribe/dmtprvauth')}
            /> */}
            <img
              src= {image9}
              alt="image 2"
              className="h-full w-full object-cover cursor-pointer"
              onClick={() => navigate('/inscribe/dmtprvauth')}
            />
            <img
              src= {image10}
              alt="image 2"
              className="h-full w-full object-cover cursor-pointer"
              onClick={() => navigate('/inscribe/dmtprvauth')}
            />
            <img
              src= {image11}
              alt="image 2"
              className="h-full w-full object-cover cursor-pointer"
              onClick={() => navigate('/inscribe/dmtprvauth')}
            />
            <img
              src= {image1}
              alt="image 1"
              className="h-full w-full object-cover"
            />
            <img
              src= {image2}
              alt="image 2"
              className="h-full w-full object-cover"
            />
            <img
              src= {image3}
              alt="image 2"
              className="h-full w-full object-cover"
            />
            <img
              src= {image4}
              alt="image 2"
              className="h-full w-full object-cover"
            />
            <img
              src= {image5}
              alt="image 2"
              className="h-full w-full object-cover"
            />
            <img
              src= {image6}
              alt="image 2"
              className="h-full w-full object-cover"
            />
            <img
              src= {image7}
              alt="image 2"
              className="h-full w-full object-cover"
            />
          </Carousel>
        </div>
      </div>
    </div>
  )
}
