import React, {useState, useEffect, useCallback} from 'react'
import { getInscriptionContent, getOrdinals, getUnat, getInscriptionIds, getUnatData, updateUnat } from '../../util/api';
import {Spinner} from '@material-tailwind/react'
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import UnatCard from '../UnatCard';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { debounce } from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import { sleep } from '../../util/api';
import { getBtcPrice } from '../../util/api';
import { getUnatByAddress } from '../../util/tap-api';
import './Unat.css';

export default function Unat(props) {
  const { keyAddress } = props;
  const [unatData, setUnatDatas] =  useState([]);
  const [filteredUnatData, setFilteredUnatDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [ticker, setTicker] = useState("");
  const [blk, setBlk] = useState("");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const fetchBtcPrice = async () => {
      let tempPrice = await getBtcPrice();
      setPrice(tempPrice);
    }
    fetchBtcPrice();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchOrdinals(page, ticker, 1);
        setLoading(false);
      }
      catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, [keyAddress])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchOrdinals(page, ticker, 1);
        setLoading(false);
      }
      catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, [page])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const handleKeyChange = (e, mode) => {
    if (mode == 1) // ticker
      setTicker(e.target.value);
    else
      setBlk(e.target.value);
    
    debouncedSearch(e.target.value, mode);
  }

  const debouncedSearch = useCallback(debounce( async (value, mode) => {
    try {
      setLoading(true);
      await fetchOrdinals(1, value, mode);
      setPage(1);
      setLoading(false);
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, 300), []); 

  const fetchOrdinals = async (offset, value, mode) => {
    if (keyAddress == "") {
      setTotal(0);
      setUnatDatas([]);
    }
    else {
      let data;
      // if (mode == 1)
      //   data = await getUnatData(keyAddress, limit, offset, value, blk);
      // else
      //   data = await getUnatData(keyAddress, limit, offset, ticker, value);
      // setTotal(Math.ceil(data.total / limit));
      // setUnatDatas(data.data);
      data = await getUnatByAddress(keyAddress, limit, (offset - 1) * limit);
      setTotal(Math.ceil(parseInt(data.sys.elements) / limit));
      setUnatDatas(data.unats)
    }
  }

  const getFilteredData = (datas) => {
    let tempData = [];
    for(let data of datas) {
      if (data.tick.includes(ticker)) {
        tempData.push(data);
      }
    }
    return tempData;
  }

  const handleDataList = async (dataList, tempUnatDatas) => {
    for(let data of dataList) {
      tempUnatDatas.push(data.inscriptionId); 
    }
    return tempUnatDatas;
  }

  const isValidMint = (content_decode) => {
    try{
      let content = JSON.parse(content_decode);
      if (content.p != "tap") return false;
      if (content.op != "dmt-mint") return false;
      if (content.blk == undefined) return false;
      if (content.dep == undefined) return false;
      if (content.tick == undefined) return false;
      if (parseInt(content.blk) <= 0) return false;
    }
    catch (err) {
      return false;
    }
    return true;
  }

  return (
    <div className="flex flex-col w-full">
      {/* {
        total > 0 || ticker != "" ? 
          <div className="mt-5 flex flex-row w-full justify-end">
            <div className="flex flex-row md:gap-2 gap-1">
              <div className="customInput">
                <Input size="sm" type="text" color="white" label = "Search by ticker" value = {ticker} onChange = {(e) => {handleKeyChange(e, 1)}}/>
              </div>
              <div className="customInput">
                <Input type="text" color="white" label = "Search by block number" className="w-[250px] min-w-[170px]" value = {blk} onChange = {(e) => {handleKeyChange(e, 2)}}/>
              </div>
            </div>
          </div> : <></>
      }       */}
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 3xl:grid-cols-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 w-full mt-5">
        {
          total > 0 ? 
            unatData.map((data) => 
              <UnatCard key={uuidv4()} data = {data} price={price} type={2}/> 
            ) : <>No UNATs.</>
        }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
      
    </div>
  )
}
