import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { amountFormat } from '../../util/format-data';
import { getMarketplaceData, saveMarketplaceData, getMarketplacePsbt, getSignedPsbt } from '../../util/api';
import { marketplaceFeeLimit, marketplaceFeeRate } from '../../configs/constant';
import { getPaymentTx } from '../../util/api';
import { savePointData } from '../../util/api';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner
} from "@material-tailwind/react";
import toast, { Toaster } from 'react-hot-toast';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';

import { useWallet, useWallets } from '@wallet-standard/react';
import { latestTokenNew } from '../../util/new-api';
import { formatAddress } from '../../util/format-data';

export default function NameCard(props) {

  const wallet = useSelector(state => state.wallet);

  const { wallets } = useWallets();
  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const { data, price, type, isSale, keyAddress } = props;

  const [sats, setSats] = useState(0);
  const [id, setId] = useState(0);
  const [mode, setMode] = useState(true);
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [operationMode, setOperationMode] = useState(0);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    if (isSale) {
      let param = {
        ownerAddress: wallet.nostrOrdinalsAddress,
        inscriptionId: 'domain-' + data.domain,
        deleteFlag: 0
      }
      let results = await getMarketplaceData(param);
      if (results.total > 0) {
        setMode(false);
        setSats(results.data[0].price);
        setId(results.data[0].id);
        setAmount(results.data[0].price);
      }
      else {
        setMode(true);
        setSats(0);
        setAmount(0);
      }
    }
  }

  const handleAmount = (e) => {
    let amount = e.target.value;
    setAmount(amount);
  }

  const showSale = (value) => {
    setShow(true);
    setOperationMode(value);
  }

  const handleSale = async () => {
    let totalPrice = amount;
    if (totalPrice < marketplaceFeeLimit) {
      setShow(false);
      toast.error("Price should be grater than 0.000005 btc!");
      return;
    }
    setLoading(true);

    let responses = await latestTokenNew(wallet.nostrOrdinalsAddress);
    let fee = 0;
    if (responses.total == 0) {
      fee = Math.floor(totalPrice * marketplaceFeeRate);
      if (fee < marketplaceFeeLimit) fee = marketplaceFeeLimit;
    }

    let targetPrice = totalPrice - fee;

    let params;

    if (operationMode == 1) {
      params = {
        id: id,
        price: amount,
        psbt: wallet.nostrPaymentAddress,
        reason: "edit",
        actualPrice: Math.floor(targetPrice),
        totalPrice: totalPrice
      }
    }
    else {
      params = {
        ownerAddress : wallet.nostrOrdinalsAddress,
        inscriptionId : 'domain-' + data.domain,
        ticker: data.domain,
        type: type, // name
        amount: 1,
        price: amount,
        psbt: wallet.nostrPaymentAddress,
        reason: "sale",
        actualPrice: Math.floor(targetPrice),
        totalPrice: totalPrice
      }
    }
    
    await saveMarketplaceData(params);
    await fetchData();

    params = {
      address : wallet.nostrOrdinalsAddress,
      point : 1,
      reason : "List Name",
      deleteFlag : 0
    }
    await savePointData(params);

    setShow(false);
    setLoading(false);
    toast.success("Your name has been listed!");
  }

  const handleDelist = async () => {
    setLoading(true);
    
    let params = {
      id: id,
      reason: 'delist',
      psbt: ""
    }
    
    await saveMarketplaceData(params);
    await fetchData();

    params = {
      address : wallet.nostrOrdinalsAddress,
      point : -1,
      reason : "Delist Name",
      deleteFlag : 0
    }
    await savePointData(params);

    setShow(false);
    setLoading(false);
    toast.success("Your name has been delisted.");
    
  }

  return (
    <div className="w-full rounded-md border-borderColor border-[1px] border-solid bg-gray-900 max-w-[320px] m-auto">
      <div className="flex flex-row justify-between p-1">
        <div className="font-bold pl-1">{formatAddress(keyAddress)}</div>
      </div>
      <div className="flex flex-row justify-center text-[24px] my-10 font-bold text-primary">
        {data.domain}
      </div>
      {
        isSale ? 
        <div className="bg-[#121212] mt-2 pb-3 px-4 rounded-b-md">
          <div className="flex flex-row justify-between mt-1">
            <div className="flex flex-row gap-1 items-center">
              <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
              <span className="text-primary">{sats / Math.pow(10, 8) }</span>
            </div>
            <div className="text-gray-400">${Math.round(sats / Math.pow(10, 6) * price)/100}</div>
          </div>
          {
            mode ? 
              <div className="w-full text-center border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold flex flex-row items-center justify-center mt-4 mb-2 p-1 cursor-pointer hover:text-primary hover:border-primary" onClick={() => showSale(0)}>List</div> : 
              <div className="w-full gap-2 flex flex-row">
                <div className="w-full text-center border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold flex flex-row items-center justify-center mt-4 mb-2 p-1 cursor-pointer hover:text-primary hover:border-primary" onClick={() => showSale(1)}>Edit</div>
                <div className="w-full text-center border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold flex flex-row items-center justify-center mt-4 mb-2 p-1 cursor-pointer hover:text-primary hover:border-primary" onClick={() => showSale(2)}>Delist</div>
              </div>
          }
        </div>
        : <></>
      }
      
      <Dialog
        open={show}
        size={"xs"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogHeader>
          {
            operationMode == 0 ?
              <div className="flex flex-row w-full justify-center mt-5 sm:text-[32px] text-[24px] font-bold text-primary">LIST</div> : <></>
          }
          {
            operationMode == 1 ?
              <div className="flex flex-row w-full justify-center mt-5 sm:text-[32px] text-[24px] font-bold text-primary">EDIT</div> : <></>
          }
          {
            operationMode == 2 ?
              <div className="flex flex-row w-full justify-center mt-5 sm:text-[32px] text-[24px] font-bold text-primary">DELIST</div> : <></>
          }
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-2 md:px-10 px-2">
            <div className="flex flex-row gap-1 w-full justify-center text-white">
              <span className="text-[24px] font-bold">{data.domain}</span>
            </div>
            {
              operationMode != 2 ?
                <div className="flex flex-col gap-2">
                  <div className="w-full font-bold text-white sm:text-[28px] text-[24px] text-center">
                    ${Math.floor(amount * 10 * price / Math.pow(10, 8)) / 10}
                  </div>
                  <div className="w-full max-w-[300px] grid grid-cols-2 m-auto">
                    <div className="w-full font-bold text-white sm:text-[20px] text-[14px] text-left">
                      <div>Price:</div>
                    </div>
                    <div className="w-full font-bold text-white sm:text-[20px] text-[14px] text-right mb-2">
                      <div>{amount / Math.pow(10, 8)} BTC</div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-1 justify-center">
                    <div className="w-[250px]">
                      <Input color="white" label = "Price per unit" value = {amount} onChange = {(e) => {handleAmount(e)}}/>
                    </div>
                    <span className="font-bold text-white ml-1">sats</span>
                  </div>
                </div> : <></>
            }
          </div>
        </DialogBody>
        <DialogFooter>
          {
            loading ? 
            <div className="flex flex-row justify-center w-full">
              <Spinner className="h-16 w-16 text-gray-900/50" color="pink"/>
            </div>
            :
            <div className="flex flex-row w-full justify-center gap-8">
              {
                operationMode == 2 ? 
                  <button className="bg-primary hover:bg-primary-hover text-[14px] text-white rounded-md px-12 font-bold" onClick={() => handleDelist()}>DELIST</button>
                  :
                  <button className="bg-primary hover:bg-primary-hover text-[14px] text-white rounded-md px-12 font-bold" onClick={() => handleSale()}>LIST</button>
              }
              <Button
                onClick={() => setShow(false)}
                className="py-[12px] px-[32px]"
              >
                <span>Return</span>
              </Button>
            </div>
          }
        </DialogFooter>
      </Dialog>
    </div>
  )
}
