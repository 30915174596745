import React, {useState, useEffect} from 'react'
import RuneCard from '../RuneCard';
import { getUnisatRunes, getRunes, getBtcPrice} from '../../util/api'
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Spinner
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function Brc20(props) {
  const { keyAddress } = props;

  const [runesData, setRunesData] =  useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAllRunes();
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [keyAddress, page])

  const fetchAllRunes = async() => {
    setLoading(true);
    try{
      let data = await getUnisatRunes(keyAddress, (page - 1) * limit, limit);
      setTotal(Math.ceil(data.total / limit));
      if (data.list.length > 0) {
        let tickers = [];
        for(let listData of data.list) {
          tickers.push(listData.rune);
        }
        let tempDatas = await getRunes(tickers);
        for(let tempData of tempDatas) {
          for(let data1 of data.list) {
            if (tempData.name == data1.spacedRune) {
              tempData.amount = parseInt(data1.amount) / Math.pow(10, tempData.divisibility) ;
              break;
            }
          }
        }
        let tempPrice = await getBtcPrice();
        setPrice(tempPrice);
        setRunesData(tempDatas);
      }
      else {
        setRunesData([]);
      }
    }
    catch(e) {
      setLoading(false);
    }
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
        {
          runesData.length > 0 ? 
            runesData.map((data) => {
              return <RuneCard key={uuidv4()} data={data} price={price}/>
            }) : <>No Rune tokens.</>
        }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
    </div>
  )
}
