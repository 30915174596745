import React from 'react'
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, updateDoc, setDoc, doc  } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { formatAddressMicro, formatAddressMicroText } from '../../util/format-data';
import toast, { Toaster } from 'react-hot-toast';
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';

export default function ChatButton(props) {
  const { targetAddress } = props;

  const navigate = useNavigate();
  const wallet = useSelector(state => state.wallet);

  const saveData = async (address1, address2) => {
    const docRef = doc(firestore, "chat-" + formatAddressMicroText(address1), address2);
    const data = {
      address: address2,
      typing: false,
      count: 0,
      isRead: false,
      status: false,
      message: "",
      timestamp: serverTimestamp(),
    }

    await setDoc(docRef, data, { merge: true});
  }

  const handleAdd = async () => {
    if (wallet.nostrOrdinalsAddress == "") {
      toast.error("Please connect your wallet!");
    }
    else if (wallet.nostrOrdinalsAddress == targetAddress) {

    }
    else {
      await saveData(wallet.nostrOrdinalsAddress, targetAddress);
      await saveData(targetAddress, wallet.nostrOrdinalsAddress);
      navigate('/chat')
    }
  }
  return (
    <div className="cursor-pointer text-primary" onClick={() => handleAdd()}>
      <ChatBubbleOvalLeftEllipsisIcon strokeWidth={2} className="h-5 w-5" />
    </div>
  )
}
