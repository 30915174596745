import React, {useEffect, useCallback, useState} from 'react'
import { getUnatHolders } from '../../util/api'
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon, ArrowLongDownIcon, ArrowLongUpIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button
} from '@material-tailwind/react';
import { formatUnixTime } from '../../util/format-data';
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from '../../util/format-data';
import UnatIndexerDetail from './UnatIndexerDetail';
import Lrc20IndexerDetail from './Lrc20IndexerDetail';
import { getLRC20TokenList } from '../../util/lamda-api';
import { amountFormat, convertTimestamp } from '../../util/format-data';

export default function Lrc20Indexer() {
  const [ticker, setTicker] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [lrc20Datas, setLrc20Datas] = useState([]);
  const [totalDatas, setTotalDatas] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [detailticker, setDetailTicker] = useState('');
  const [direct, setDirect] = useState(1);
  const [sort, setSort] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [lrc20DataDetail, setLrc20DataDetail] = useState({});
  const [detailHolders, setDetailHolders] = useState(0);
  const [detailSupply, setDetailSupply] = useState(0);
  const [detailDecimals, setDetailDecimals] = useState(0);
  
  useEffect(() => {
    fetchTokenData();
  }, [])

  useEffect(() => {
    handleData(ticker, page);
  }, [page, order, direct])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const handleKeyChange = (e) => {
    setTicker(e.target.value)
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    try {
      handleData(value, 1);
      setPage(1);
    }
    catch (e) {
      console.log(e);
    }
  }, 300), []); 

  const fetchTokenData = async () => {
    setLoading(true);
    let data = await getLRC20TokenList();
    setTotalDatas(data);
    handleData(ticker, page, data);
    setTotal(Math.ceil(data.length / limit));
    setLoading(false);
  }

  const handleOrder = (value) => {
    setSort(value);
    setDirect(1 - direct);
  }

  const showDetailIndexer = (value, holders, totalSupply, decimals) => {
    setDetailTicker(value);
    setDetailHolders(holders);
    setDetailSupply(totalSupply);
    setDetailDecimals(decimals);
    setShowDetail(true);
  }

  const handleData = (value, offset, data = totalDatas) => {
    let tempData = [];
    if (sort == 0) {
      if (direct == 0) tempData = data.sort((a, b) => a.holders - b.holders);
      if (direct == 1) tempData = data.sort((a, b) => b.holders - a.holders);
    }
    else if(sort == 1) {
      if (direct == 0) tempData = data.sort((a, b) => a.totalSupply - b.totalSupply);
      if (direct == 1) tempData = data.sort((a, b) => b.totalSupply - a.totalSupply);
    } else if (sort == 2) {
      if (direct == 0) tempData = data.sort((a, b) => a.decimals - b.decimals);
      if (direct == 1) tempData = data.sort((a, b) => b.decimals - a.decimals);
    }
    tempData = tempData.filter((temp) => temp.name.includes(value));
    setLrc20Datas(tempData.slice((offset - 1) * limit, offset * limit));
  }

  return (
    <div className="text-white w-full max-w-[1500px] flex flex-col justify-center items-center">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
        showDetail ? <Lrc20IndexerDetail ticker = {detailticker} handleShowDetail = {setShowDetail} setLoading = {setLoading} holders = {detailHolders} supply = {detailSupply} decimals = {detailDecimals}/> : 
        <>
          {
            <div className="flex flex-col gap-2 max-w-[1000px] w-full mt-10">
              { 
                <div className="mt-5 flex flex-row w-full justify-end gap-2">
                  <div>
                    <Input type="text" color="white" label = "Search by ticker" className="w-[250px]" value = {ticker} onChange = {(e) => {handleKeyChange(e)}}/>
                  </div>
                </div>
              }
              <div className="flex flex-row px-4 py-2">
                <div className = "md:w-[35%] w-[75%]">Token</div>
                <div className = "md:w-[20%] w-[25%] flex flex-row gap-3 items-center">
                    <span>Holders</span>
                    <div className="flex flex-row cursor-pointer" onClick={() =>handleOrder(0)}>
                        <ArrowLongDownIcon strokeWidth={2} className={`h-4 w-4 ${sort == 0 && direct == 1 ? 'text-primary' : 'text-white'}`} />
                        <ArrowLongUpIcon strokeWidth={2} className={`h-4 w-4 ml-[-8px] ${sort == 0 && direct == 0 ? 'text-primary' : 'text-white'}`} />
                    </div>
                </div>
                <div className = "md:w-[25%] hidden md:flex flex-row gap-3 items-center">
                    <span>Supply</span>
                    <div className="flex flex-row cursor-pointer" onClick={() =>handleOrder(1)}>
                        <ArrowLongDownIcon strokeWidth={2} className={`h-4 w-4 ${sort == 1 && direct == 1 ? 'text-primary' : 'text-white'}`} />
                        <ArrowLongUpIcon strokeWidth={2} className={`h-4 w-4 ml-[-8px] ${sort == 1 && direct == 0 ? 'text-primary' : 'text-white'}`} />
                    </div>
                </div>
                <div className = "md:w-[20%] hidden md:flex flex-row gap-3 items-center">
                    <span>Decimals</span>
                    <div className="flex flex-row cursor-pointer" onClick={() =>handleOrder(2)}>
                        <ArrowLongDownIcon strokeWidth={2} className={`h-4 w-4 ${sort == 2 && direct == 1 ? 'text-primary' : 'text-white'}`} />
                        <ArrowLongUpIcon strokeWidth={2} className={`h-4 w-4 ml-[-8px] ${sort == 2 && direct == 0 ? 'text-primary' : 'text-white'}`} />
                    </div>
                </div>
              </div>
              {
                lrc20Datas.map((data) => 
                  <div 
                    className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg items-center" 
                    key={uuidv4()}
                  >
                    <div className = "md:w-[35%] w-[75%] text-primary font-bold flex flex-row gap-2 items-center cursor-pointer" onClick = {() => showDetailIndexer(data.name, data.holders, data.totalSupply, data.decimals)}>
                        <div>{data.name}</div>
                    </div>
                    <div className = "md:w-[20%] w-[25%]">{data.holders}</div>
                    <div className = "md:w-[25%] md:block hidden">{amountFormat(data.totalSupply / Math.pow(10, data.decimals))}</div>
                    <div className = "md:w-[15%] md:block hidden">{data.decimals}</div>
                  </div>
                )
              }
            </div> 
          }
          {
            total > 1 ? 
            <div className="flex flex-row items-center gap-8 justify-end mt-5">
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doublePrev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
              <Typography color="white" className="font-normal">
                Page <strong className="text-primary">{page}</strong> of{" "}
                <strong className="text-primary">{total}</strong>
              </Typography>
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={next}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doubleNext}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
            :
            <></>
          }
        </>
      }
      
    </div>
  )
}
