import { UPDATE_KEY } from "./action";


export const initialState = {
  domain: '',
  address: ''
};
export default function keyReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_KEY:
      return { ...action.data };
    default:
      return state;
  }
}