import React, {useState, useEffect} from 'react'
import TokenCard from '../TokenCard'
import { useSelector, useDispatch } from 'react-redux';
import { getStampBalance } from '../../util/stamp-api';
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Spinner
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import StampCard from '../TokenSaleCard/StampCard';

export default function Stamp(props) {
  const { keyAddress } = props;

  const [stampData, setStampData] =  useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAllstamp();
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [keyAddress, page])

  const fetchAllstamp = async() => {
    setLoading(true);
    try{
      let data = await getStampBalance(keyAddress, limit, page);
      if (data.data == undefined) {
        setStampData([]);
        setTotal(0);
      }
      else {
        setStampData(data.data);
        if (data.pagination != undefined) {
            if (data.pagination.totalPages == null) setTotal(0)
            else setTotal(data.pagination.totalPages);
        }
        else {
            if (data.totalPages == null) setTotal(0)
            else setTotal(data.totalPages); 
        }
        
      }
    }
    catch(e) {
      setLoading(false);
    }
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
        {
          stampData.length > 0 ? 
            stampData.map((data) => {
              return <StampCard key={uuidv4()} data = {data}/>
            }) : <>No STAMP.</>
        }
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
            className="bg-primary hover:bg-primary-hover"
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
    </div>
  )
}
