import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";

import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
  Navigate
} from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';
import Wallet from './Wallet';
import MintDomain from './MintDomain';
import Explorer from './Explorer';
import Profile from './Profile';
import IndexPage from './IndexPage';
import Test from './Test';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Mint from './Mint';
import MintP from './MintP';
import Marketplace from './Marketplace';
import Recover from './Recover';
import Admin from './Admin';
import MintStamps from './MintStamps';
import RecoverStamps from './RecoverStamps';
import toast, { Toaster } from 'react-hot-toast';
import Chat from '../components/Chat/Chat';
import MintPAToken from './MintPAToken';
import MintPATokenAdmin from './MintPATokenAdmin';
import MintPATokenTicker from './MintPATokenTicker';
import FractalTest from './FractalTest';
import DmtPAToken from './DmtPAToken';
import DmtPATokenAdmin from './DmtPATokenAdmin';
import DmtPATokenTicker from './DmtPATokenTicker';
import DmtBlockpad from './DmtBlockpad';
import BlockdropBlockpad from './BlockdropBlockpad';
import BlockdropPAToken from './BlockdropPAToken';
import BlockdropPATokenAdmin from './BlockdropPATokenAdmin';
import BlockdropPATokenTicker from './BlockdropPATokenTicker';
import MintRunes from './MintRunes';

export default function Indexer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState(0); //0: Wallet 1: Mint Domain 2: Explorer

  useEffect(() => {
    let tempTab = 1;
    switch(location.pathname) {
      case '/':
        tempTab = 0;
        break;
      case '/register':
        tempTab = 1;
        break;
      case '/explorer':
        tempTab = 2;
        break;
      case '/profile':
        tempTab = 3;
        break;
      case '/index':
        tempTab = 4;
        break;
      case '/tapzero':
        tempTab = 5;
        break;
      case '/marketplace':
        tempTab = 6;
        break;
      case '/istamps':
        tempTab = 7;
        break;
      default:
        tempTab = 3;
        break;
    }
    setTab(tempTab);
  }, [location.pathname])
  
  const handleTab = (tab) => {
    setTab(tab);
    switch(tab) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/register');
        break;
      case 2:
        navigate('/explorer')
        break;
      case 3:
        navigate('/profile')
        break;
      case 4:
        navigate('/index')
        break;
      case 5:
        navigate('/tapzero')
        break;
      case 6:
        navigate('/marketplace')
        break;
      case 7:
        navigate('/istamps')
        break;
      case 8:
        navigate('/chat')
        break;
      case 9:
        navigate('/inscribe/prvauth')
        break;
      case 10:
        navigate('/inscribe/dmtprvauth')
        break;
      case 11:
        window.location.href = 'https://airdrop.tapwallet.io'
        break;
      case 12:
        navigate('/inscribe/blockdrop')
        break;
      case 13:
        navigate('/mint/runes')
        break;
      case 14:
        navigate('/inscribe/dmtprvauth/runezero')
    }
  }
  return (
    <div className="flex flex-col gap-5 bg-background items-center w-full">
      <Header tab = {tab} handle = { handleTab}/>
      <Routes>
        <Route path="/register" element={<MintDomain />} />
        <Route path="/mint" element={<Navigate to="/register" replace />} />
        <Route path="/explorer" element={<Explorer />} />
        <Route path="/profile" element={<Profile />}/>
        <Route path="/profile/:id" element={<Profile />}/>
        <Route path="/index" element={<IndexPage />}/>
        <Route path="/_mint" element={<MintP />}/>
        <Route path="/___recover___" element={<Recover />}/>
        <Route path="/___istamps___" element={<RecoverStamps />}/>
        <Route path="/tapzero" element={<Mint />}/>
        <Route path="/istamps" element={<MintStamps />}/>
        <Route path="/mint/runes" element={<MintRunes />}/>
        <Route path="/inscribe/prvauth" element={<MintPAToken />}/>
        <Route path="/inscribe/prvauth/:param" element={<MintPATokenTicker />}/>
        <Route path="/inscribe/prvauth/:param/admin" element={<MintPATokenAdmin />}/>
        <Route path="/inscribe/dmtprvauth" element={<DmtPAToken />}/>
        <Route path="/inscribe/dmtprvauth/:param/admin" element={<DmtPATokenAdmin />}/>
        <Route path="/inscribe/dmtprvauth/:param" element={<DmtPATokenTicker />}/>
        <Route path="/inscribe/dmtprvauth/:param/blockpad" element={<DmtBlockpad />}/>
        <Route path="/inscribe/blockdrop" element={<BlockdropPAToken />}/>
        <Route path="/inscribe/blockdrop/:param/admin" element={<BlockdropPATokenAdmin />}/>
        <Route path="/inscribe/blockdrop/:param" element={<BlockdropPATokenTicker />}/>
        <Route path="/inscribe/blockdrop/:param/blockpad" element={<BlockdropBlockpad />}/>
        <Route path="/marketplace" element={<Marketplace />}/>
        <Route path="/" element={<Wallet />} />
        <Route path="/_test" element={<Test />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path="/terms-of-service" element={<TermsOfService />}/>
        <Route path="/___admin___" element={<Admin />} />
        <Route path="/chat" element={<Chat />}/>
        <Route path="/fractalTest" element={<FractalTest />}/>
        <></>
      </Routes>
      {/* <div className="text-white border-solid border-white border-[2px] p-3 cursor-pointer" onClick = {() => toast.success("I am okay!")}>Okay</div> */}
      {
        location.pathname == '/chat' ? <></> : <Footer />
      }
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 2000,
          style: {
            background: '#000000',
            color: '#FFFFFF',
            marginTop: '50px'
          },
          iconTheme: {
            primary: '#f9099d',
          }
        }}      
      />
    </div>
  )
}
