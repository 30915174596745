import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, updateDoc, setDoc, doc  } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Sidebar from './Sidebar';
import MessageText from './MessageText';
import MessageInput from './MessageInput';
import { getDomainAddress } from '../../util/api';
import { combineAddress } from '../../util/format-data';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function Chat() {

  const navigate = useNavigate();
  const messageEndRef = useRef(null);

  const wallet = useSelector(state => state.wallet);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [activeContact, setActiveContact] = useState("");
  const [activeName, setActiveName] = useState("");
  const [status, setStatus] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [domain, setDomain] = useState("");

  useEffect(() => {
    setActiveStatus(true);
    fetchDomain(wallet.nostrOrdinalsAddress);
    return () => {
      setActiveStatus(false);
    }
  }, [wallet.nostrOrdinalsAddress])

  const fetchDomain = async (address) => {
    let response = await getDomainAddress(address);
    if (response.total > 0) {
      setDomain(response.domain)
    }
    else setDomain("");
  }

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    fetchName();
    const unsubscribe = onSnapshot(
      query(collection(firestore, 'chat-' + combineAddress(wallet.nostrOrdinalsAddress, activeContact)), orderBy('timestamp')),
      (snapshot) => {
        const data = snapshot.docs.map(doc => doc.data());
        setMessages(data);
      }
    );

    let tempContact = activeContact;
    if (tempContact == "") tempContact = "temp"
    const docRef = doc(firestore, "chat-status", tempContact);
    const unsubscribeStatus =  onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setStatus(doc.data().status);
      } else {
        console.log("No such document!");
        setStatus(false);
      }
    }, (error) => {
      console.error("Error fetching document: ", error);
      setStatus(false);
    });
    
    return () => {
      unsubscribe();
      unsubscribeStatus();
    }
  }, [activeContact])

  const setActiveStatus = async (value) => {
    if (wallet.nostrOrdinalsAddress != "") {
      const docRef = doc(firestore, "chat-status", wallet.nostrOrdinalsAddress);
      const data = {
        status: value,
        timestamp: serverTimestamp()
      }

      await setDoc(docRef, data, { merge: true});
    }
  }

  const handleActiveContact = (value) => {
    setActiveContact(value)
  }

  const fetchName = async () => {
    let response = await getDomainAddress(activeContact);
    if (response.total > 0) {
      setActiveName(response.domain)
    }
    else setActiveName("");
  }

  const handleSidebarActive = (value) => {
    setSidebarActive(value);
  }

  return (
    <div className="flex flex-row mt-[64px] w-full">
      {
        sidebarActive ? 
        <div className="md:flex hidden">
          <Sidebar address={wallet.nostrOrdinalsAddress} activeContact = {activeContact} handleActiveContact = {handleActiveContact} handleSidebarActive = {handleSidebarActive} domain = {domain}/>
        </div> 
        :
        <div className="flex sm:w-[420px] w-full">
          <Sidebar address={wallet.nostrOrdinalsAddress} activeContact = {activeContact} handleActiveContact = {handleActiveContact} handleSidebarActive = {handleSidebarActive} domain = {domain}/>
        </div>
        
      }
      {
        activeName == "" ? 
        <div className={`md:flex ${sidebarActive ? 'flex' : 'hidden'} flex-col gap-3 w-full relative text-center mt-10 font-bold text-[24px] text-white`}>          
          Welcome to the BOCM | Bitcoin On-Chain Messenger
        </div>
        :
        <div className={`md:flex ${sidebarActive ? 'flex' : 'hidden'} flex-col gap-3 w-full relative h-custom-height-64`}>
          <div className="flex flex-row border-b-[2px] border-b-solid border-b-[#24242a] w-full px-6 py-2">
            <div className="flex flex-row gap-2 items-center">
              <div className="md:hidden flex text-white p-2 rounded-full hover:bg-primary hover:text-white cursor-pointer" onClick={() => setSidebarActive(false)}>
                <ArrowLeftIcon strokeWidth={2} className="h-5 w-5" />
              </div>
              <div className="w-[50px] h-[50px] bg-primary rounded-md font-bold text-white text-[32px] text-center">
                {activeName.substring(0, 1)}
              </div>
              <div className="flex flex-col">
                <div className="text-white text-[18px] font-bold">{activeName}</div>
                <div className="text-blue-600">{status ? 'Active' : 'Offline'}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-3 p-6 h-custom-height-204 overflow-auto">
            {
              messages.map((item) => 
                <div className="w-full" key={item.timestamp} >
                  <MessageText name={activeName.substring(0, 1)} message={item.message} flag={item.from == wallet.nostrOrdinalsAddress}/>
                </div>
              )
            }
            <div ref={messageEndRef} />
          </div>
          <div className="flex flex-row w-full absolute bottom-0 bg-[#0b0b0d]">
            <MessageInput activeContact = {activeContact} address={wallet.nostrOrdinalsAddress}/>
          </div>
        </div>
      }
    </div>
  )
}
