import React from 'react';
import {useState, useEffect} from 'react';
import Point from '../components/Admin/Point';

export default function Admin() {
  const [tab, setTab] = useState(0);

  return (
    <div className="text-white mt-[65px] w-full md:px-7 px-2 flex flex-col items-center mb-10 min-h-[600px]">
      <div className="flex md:flex-row flex-wrap gap-2 w-full mt-5">
        <div className={`md:text-[20px] text-[16px] ${tab == 0 ? 'text-primary border-[1px] border-primary border-solid' : ''} hover:text-primary px-4 py-1 rounded-md cursor-pointer`} onClick={() => setTab(0)}>POINT</div>
      </div>
      {
        tab == 0 ? <Point /> : <></>
      }
    </div>
  )
}
