import React from 'react'

import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFeeNew, getData, isValidTaprootAddress} from '../util/inscribe-util';
import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding, royalty_pa } from '../configs/constant';


export default function FractalTest() {
  const { Address, Script, Signer, Tap, Tx } = window.tapscript;

  const handleSwitch =async () => {
    let res = await window.unisat.signMessage('{"p":"lam","op":"call","contract":"dep:dmt:TRUMP","function":"transfer","args":["bc1px92c229fgrewwz2lp9s05v47rkjw4zvdm87mwc806jxpz9cckstqn7sqlm",1000000],"n":4}');
  console.log(res);
  }

  const handleSwitch1 =async () => {
    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    //let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let privkey = "82426f543500ff0384d07af815898a1b53a81ee9985029659262e352b35a12a8";

    console.log("--------------", privkey);

    let inscriptionFee = 2000;
    let seckey = new KeyPair(privkey);

    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";
    let salts = "";
    let tickerPubKey = "";

    for(let i = 0; i< 1; i++)
    {
      let text = '{"p":"tap","op":"dmt-mint","tick":"sats","blk":"284320"}';
      
      salts = text;

      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    let parentInscriptionId = "54237f9e1ebbe419334547ddd05777bd4033e871c754fe986036f3e36bd6981fi0";
    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          '03',
          reverseInscriptionId(parentInscriptionId),
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          '03',
          reverseInscriptionId(parentInscriptionId),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      console.log("--------tapkey---------------------", tapkey);

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      console.log("--------inscriptionAddress-----------", inscriptionAddress);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    console.log("-----------fundingAddress-----------", _fundingAddress);

    await inscribe(inscriptions[0], seckey, init_script, init_leaf, init_cblock, init_tapkey, pubkey)
    
  }

  const inscribe = async(inscription, seckey, init_script, init_leaf, init_cblock, init_tapkey, pubkey) => {
    let _toAddress;
    let _script;
    let toAddress = "bc1p26005tzsk7ta64zuad59l29wkp2u7npgarss865ae5fj5f6g04nq5jxkpz";
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
    }

    const redeemtx = Tx.create({
        vin  : [
          {
            txid: "2a13cf5192aa5902f0928d3e0f2111ee118cdf7930bf046aafc4a513b334b35d",
            vout: 0,
            prevout: {
                value: 546,
                scriptPubKey: [ 'OP_1', init_tapkey ]
            },
          },
          {
            txid: "ec3fcacc809c3928f2799b2065be2cfe431f113788f396e7f30bac9d770871e2",
            vout: 0,
            prevout: {
                value: 2000,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
          }
        ],
        vout : [
          {
            value: padding,
            scriptPubKey: _script
          },
          {
            value: padding,
            scriptPubKey: _script
          },
        ],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: init_leaf});
    redeemtx.vin[0].witness = [ sig.hex, init_script, init_cblock ];

    const sig1 = await Signer.taproot.sign(seckey.raw, redeemtx, 1, {extension: inscription.leaf});
    redeemtx.vin[1].witness = [ sig1.hex, inscription.script_orig, inscription.cblock ];


    const isValid = await Signer.taproot.verify(redeemtx, 0, { pubkey });
    console.log("-----valid------:" + isValid);

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    
  }

  const reverseInscriptionId = (value) => {
    value = value.slice(0, 64);
    let temps = [];
    let result = "";
    for(let i = 0; i< 32; i++) {
      temps.push(value.slice(i * 2, (i + 1) * 2));
    }
    temps = temps.reverse();
    console.log(temps);
    for(let temp of temps ) {
      result = result + temp;
    }
    return result;
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      <div className="px-10 py-2 rounded-md mt-10 bg-primary cursor-pointer" onClick={() => handleSwitch()}> Switch </div>
    </div>
  )
}
