const stampUrl = "https://dev.bitcoinstamps.xyz";
const stampProdUrl = "https://stampchain.io";
const openstampUrl = "https://openstamp.io";
const authorizationKey = "smvTDJONoZDWgeUUqcz1QY0KqKNJ11368sWPyIinLL8BiJIuukToPKkvJ1UG8XeZ";

export const getSrc20Balance = async (address, limit = 12, page = 1) => {

  const url = `${stampUrl}/api/v2/src20/balance/${address}?limit=${limit}&page=${page}`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getSrc20Ticker = async (address, limit = 12, page = 1) => {

  const url = `${stampUrl}/api/v2/src20/tick?limit=10&op=deploy`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getSrc20TickerDetail = async (ticker, limit = 12, page = 1) => {

  const url = `${stampProdUrl}/api/v2/src20/tick/${ticker}?limit=${limit}&page=1&op=deploy&sort=DESC`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getStampBalance = async (address, limit = 12, page = 1) => {

  const url = `${stampUrl}/api/v2/stamps/balance/${address}?limit=${limit}&page=${page}`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getOpenstampSrc20Ticker = async (limit, page, sort, direct, ticker) => {
  let url = ""
  if (ticker == "")
    url = `${openstampUrl}/api/v1/explorer/src20/all?onlySrc20=0&page=${page}&pageSize=${limit}&sort=${sort}&direct=${direct}`;
  else
    url = `${openstampUrl}/api/v1/explorer/src20/all?onlySrc20=0&page=${page}&pageSize=${limit}&sort=${sort}&direct=${direct}&tick=${ticker}`;
  const data = {
    url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getOpenstampSrc20TickerHolders = async (ticker, limit, page) => {
  const url = `${openstampUrl}/api/v1/explorer/src20/holdersByTick?page=${page}&pageSize=${limit}&tick=${ticker}`;
  const data = {
    url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getOpenstampStampHolders = async () => {
  const url = `${openstampUrl}/api/v1/stamp/getMarketTokenList?page=1&pageSize=500&timeWindow=2&favorite=false`;
  const data = {
    url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getOpenstampStampTikerHolders = async () => {
  const url = `${openstampUrl}/api/v1/stamp/getMarketTokenList?page=1&pageSize=500&timeWindow=2&favorite=false`;
  const data = {
    url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getOpenstampMarketplaceData = async () => {
  const url = `https://openapi.openstamp.io/v1/src20MarketData`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'smvTDJONoZDWgeUUqcz1QY0KqKNJ11368sWPyIinLL8BiJIuukToPKkvJ1UG8XeZ'
    }
  })

  return await response.json();
}
  