import React, { useEffect, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { formatAddress } from '../../util/format-data';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner
} from "@material-tailwind/react";


export default function StampCard(props) {
  const { data } = props;

  const [show, setShow] = useState(false);

  return (
    <div className="border-borderColor border-[1px] border-solid bg-gray-900 p-3 rounded-md">
      {/* <iframe src={data.stamp_url} scrolling="no" sandbox="allow-scripts" loading="lazy" className="w-full sm:min-h-[256px] min-h-[150px] rounded-md" ></iframe> */}
      <div className="relative w-full sm:h-[200px] h-[140px]">
        <img
            src={data.stamp_url}
            alt="Stamp"
            className="w-full h-full object-contain rounded-md image-rendering-pixelated"
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="text-primary font-bold text-[20px] mt-1">Stamp {data.stamp}</div>
        <div className="w-[40px]text-[20px] rounded-md font-bold text-center cursor-pointer hover:border-primary hover:text-primary" onClick = {() => {setShow(true)}}>
          <InformationCircleIcon strokeWidth={2} className="h-6 w-6" />
        </div>
      </div>
      <div className="text-white mt-1 sm:w-[250px] w-[140px] truncate">{data.cpid}</div>
      <div className="text-white mt-1">Amount: {data.balance}</div>

      <Dialog
        open={show}
        size={"xs"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col justify-center w-full gap-3 p-3">
            <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
              <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
              <span className="text-[32px] font-bold text-primary">Details</span>
            </div>
            <div className="flex flex-row justify-between border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">STAMP ID:</div>
              <div className="text-white">{data.stamp}</div>
            </div>
            <div className="flex flex-row justify-between border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">CPID:</div>
              <div className="text-white">{data.cpid}</div>
            </div>
            <div className="flex flex-row justify-between border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">CREATOR:</div>
              <div className="text-white">{formatAddress(data.creator)}</div>
            </div>
            <div className="flex flex-row justify-between border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">SUPPLY:</div>
              <div className="text-white">{data.supply}</div>
            </div>
            <div className="flex flex-row justify-between border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">DIVISIBLE:</div>
              <div className="text-white">{data.divisible == 0 ? "FALSE" : "TRUE"}</div>
            </div>
            <div className="flex flex-row justify-between border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">LOCKED:</div>
              <div className="text-white">{data.locked == 0 ? "FALSE" : "TRUE"}</div>
            </div>
          </div>
          <div className="flex flex-row-reverse w-full mt-2">
            <Button  onClick={() => setShow(false)}>RETURN</Button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
