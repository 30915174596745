const unatUrl = "https://api.tapapi.xyz";
const dmtUrl = "https://fra-01.tap-reader.xyz";

export const getUnatByAddress = async (address, limit, offset) => {
  const url = `${unatUrl}/v1/addresses/${address}/unats?limit=${limit}&offset=${offset}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getDmtTokenDetail = async (ticker) => {

  const url = `${dmtUrl}/getDeployment/dmt-${ticker}`;
  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getBitmapWalletHistoricListLength = async (address) => {
  const url = `${dmtUrl}/getBitmapWalletHistoricListLength/${address}`;

  const data = {
      url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getBitmapWalletHistoricList = async (address, offset, max = 20) => {
  const url = `${dmtUrl}/getBitmapWalletHistoricList/${address}?offset=${offset}&max=${max}`;

  const data = {
    url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getBitmapByInscription = async (inscriptionId) => {
  const url = `${dmtUrl}/getBitmapByInscription/${inscriptionId}`;

  const data = {
    url: url
  };
  let response  = await fetch("https://api.tapwallet.io/stamp/get", {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getInscriptionContent = async (id) => {
  const url = `https://api.hiro.so/ordinals/v1/inscriptions/${id}/content`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.text();
}

