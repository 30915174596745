export const SET_ADDRESS = 'SET_ADDRESS';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const EMPTY_BALANCE = 'EMPTY_BALANCE';
export const UPDATE_KEY = "UPDATE_KEY";

export const setAddress = (data) => ({
  type: SET_ADDRESS,
  data: data
});

export const updateBalance = (data) => ({
  type: UPDATE_BALANCE,
  data: data
});

export const emptyBalance = (data) => ({
  type: EMPTY_BALANCE,
  data: data
});

export const updateKey = (data) => ({
  type: UPDATE_KEY,
  data: data
})

