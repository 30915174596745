export const getCat20TokensList = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/cat20/tokens?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}