import React, { useEffect , useState} from 'react'
import { getInscriptionContent, getBlockData } from '../../util/api';
import Natdogs from '../Unat/Natdogs';
import { marketplaceFeeLimit, marketplaceFeeRate, NUMBER_OF_DUMMY_UTXOS_TO_CREATE, marketTipAddress, DUMMY_UTXO_VALUE } from '../../configs/constant'
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { getUtxos, getConfirmedUtxos, getDummyPsbt, calculateFee, getFeeRateUpdated, buyOrdinals, getBtcUtxos } from '../../util/ordinal';
import FeeRateCardNew from '../FeeRateCardNew';
import { getFeeRate } from '../../util/inscribe-util';
import { saveMarketplaceData, getPaymentTx } from '../../util/api';
import { formatAddress } from '../../util/format-data';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ChatButton from '../Chat/ChatButton';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';
import { latestTokenNew } from '../../util/new-api';
import { formatInscriptionId } from '../../util/format-data';
import { updateKey } from '../../store/action';
import { useNavigate, useLocation } from "react-router-dom";
import shroomsJsonDatas from '../../assets/formattedShroomsMetadata.json';
import { savePointData } from '../../util/api';
import { getFilteredUtxos } from '../../util/ordinal';

export default function UnatCard(props) {
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const { ticker, amount, sats, price, id, inscriptionId, handleRefresh, actualPrice, contentId, blk, domain, ownerAddress, handleActivity, number} = props

  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feeRates, setFeeRates] = useState({});
  const [utxos, setUtxos] = useState([]);
  const [dummyUtxos, setDummyUtxos] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sliderValue, setSliderValue] = useState(2);
  const [customFee, setCustomFee] = useState(0);
  const [currentFee, setCurrentFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (contentId != "" && contentId != null) {
          let inscriptionHtml =  await getInscriptionContent(contentId);
          let content = inscriptionHtml.replaceAll('/content/','https://ordinals.com/content/');
          content += '<style> html{height:100%;}</style>'
          content = content.replace('MINT_INSCRIPTION_ID', inscriptionId);
          if (ticker.toLowerCase() == "chaos") {
            let blockData = await getBlockData(blk);
            content = content.replace('NONCE_NUMBER', blockData.nonce);
          }
          setHtmlContent(content);
        }
        
        if (ticker.toLowerCase() == "shrooms") {
          for(let data of shroomsJsonDatas) {
            if (data.id == inscriptionId) {
              setImgUrl(data.meta.high_res_img_url);
              break;
            }
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (feeRateMode == "Custom") {
      setCurrentFee(customFee);
    }
    else {
      setCurrentFee(feeRates[feeRateMode]);
    }
  }, [customFee, feeRateMode])

  useEffect(() => {
    if (mode == 3)
      calculateTotalFee();
  }, [currentFee])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  const handleBuy = async () => {
    if (wallet.nostrPaymentAddress == "") {
      toast.error("Please connnect your wallet!");
    }

    let targetWalletAddress = wallet.nostrPaymentAddress;
    // let targetWalletAddress = "bc1pw2ne93z9h5y6ykvtdjd060wt22a4r80vms5uy7vt6x7lyq4cx5rqpmqjnc";

    const tempUtxos = await getBtcUtxos(targetWalletAddress);
    const {confirmedUtxos, confirmedDummyUtxos} = getFilteredUtxos(tempUtxos);

    setUtxos(confirmedUtxos);
    setDummyUtxos(confirmedDummyUtxos);

    if (confirmedDummyUtxos.length >= 2) {
      let response = await getFeeRate(); // buy ordinals
      let value = (sliderValue / 100) * (500 - response["Normal"]) + response["Normal"];
      setCustomFee(Math.floor(value));
      setFeeRates(response);
      setMode(3)
      setShow(true);
      
    }
    else
    {
      setMode(1); // create dummy utxos
      setShow(true);
    }
    // const dummyUtxos = await getUtxos(wallet.nostrPaymentAddress);
    // const confirmedUtxos = await getBtcUtxos(wallet.nostrPaymentAddress);
    // const confirmedDummyUtxos = getConfirmedUtxos(dummyUtxos);

    // setUtxos(confirmedUtxos);
    // setDummyUtxos(confirmedDummyUtxos);

    // if (dummyUtxos.length >= 2)
    //   {
    //     if (confirmedDummyUtxos.length >= 2) {
    //       let response = await getFeeRate(); // buy ordinals
    //       let value = (sliderValue / 100) * (500 - response["Normal"]) + response["Normal"];
    //       setCustomFee(Math.floor(value));
    //       setFeeRates(response);
    //       setMode(3)
    //       setShow(true);
          
    //     }
    //     else
    //     {
    //       setMode(2); // dummy utxos created but need to wait
    //       setShow(true);
    //     }
    //   }
    //   else
    //   {
    //     setShow(true); // create dummy utxos
    //     setMode(1);
    //   }
  }

  const handleCreateDummyUtxos = async() => {
    setShowSpinner(true);
    let res = await createDummyUtxos(utxos, wallet.paymentPublicKey, wallet.nostrPaymentAddress, wallet.domain);
    if (res == "Success!") {
      setShowSpinner(false);
      setMode(2);
    }
    else {
      setShow(false);
      setShowSpinner(false);
      toast.error(res);
    }
  }

  const createDummyUtxos = async (utxos, ordinalsPublicKey, address, provider) => {
    let feeRate = await getFeeRateUpdated();
    let totalValue = 0
    let selectedUtxos = [];
    let cnt = 0;
  
    for (const utxo of utxos) {
      totalValue += utxo.satoshis;
      cnt += 1;
      utxo.value = utxo.satoshis;
      selectedUtxos.push(utxo);
  
      if (totalValue > calculateFee(cnt, 2, feeRate) + NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) {
        break;
      }
    }
  
    const fee = calculateFee(cnt, 2, feeRate);
  
    const charge = totalValue - (NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) - fee;
  
    if (charge > 0 )
    {
      const data = {
        utxos: selectedUtxos,
        ordinalsPublicKey: ordinalsPublicKey,
        address: address,
        charge: charge,
        provider: provider
      };

      let psbt = await getDummyPsbt(data);
  
      try {
        if (provider == "tapwallet") {
          let res = await window.tapwallet.signPsbt(psbt);
          res = await window.tapwallet.pushPsbt(res);
        }
        if (provider == "unisat")
        {
          let res = await window.unisat.signPsbt(psbt);
          res = await window.unisat.pushPsbt(res);
        }
        if (provider == "okxwallet")
        {
          let res = await window.okxwallet.bitcoin.signPsbt(psbt);
          res = await window.okxwallet.bitcoin.pushPsbt(res);  
        }
        if (provider == "xverseWallet") {
          let signIndexes = [];
          for(let i=0;i<selectedUtxos.length; i++){
            signIndexes.push(i);
          }
  
          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                        sigHash: 1
                    },
                ],
            },
            onFinish: async (response) => {
                //let res_data = await getPaymentTx(response.psbtBase64);
                // await fetch('https://mempool.space/api/tx', {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'text/plain'
                //   },
                //   body: res_data.hex
                // })
            },
            onCancel: () => {
                return "Request canceled";
            },
          });
          return "Success!";
        }
        if (provider == "magiceden") {
          let signIndexes = [];
          for(let i=0;i<selectedUtxos.length; i++){
            signIndexes.push(i);
          }
  
          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);
  
          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                        sigHash: 1
                    },
                ],
            },
            onFinish: async (response) => {
                // let res_data = await getPaymentTx(response.psbtBase64);
                // await fetch('https://mempool.space/api/tx', {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'text/plain'
                //   },
                //   body: res_data.hex
                // })
            },
            onCancel: () => {
                return "Request canceled";
            },
          });
          return "Success!";
        }
  
      } catch (e) {
        console.log(e);
        return "Request canceled";
      }
    }
    else
    {
      return "Insufficient balance!";
    }
  
    return "Success!";
  }

  const calculateTotalFee = async() => {
    let cnt = 0;
    let selectedUtxos = [];
    let totalValue = 0;

    let responses = await latestTokenNew(wallet.nostrOrdinalsAddress);
    let tokenPrice = amount * sats;
    let tip = 0;
    if (responses.total == 0) {
      tip = Math.floor(tokenPrice * marketplaceFeeRate);
      if (tip < marketplaceFeeLimit) tip = marketplaceFeeLimit;
    }

    let salesPrice = actualPrice;
    tip = tip + tokenPrice - actualPrice;

    let newDummyUtxos = dummyUtxos.slice(0, 2);

    for (const utxo of utxos) {
      totalValue += utxo.satoshis;
      cnt += 1;
      utxo.value = utxo.satoshis;
      selectedUtxos.push(utxo);

      if (totalValue > calculateFee(3 + cnt, 6, currentFee) + NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE + salesPrice + tip) {
        break;
      }
    }

    const fee = calculateFee(3 + cnt, 6, currentFee);

    const charge = totalValue - (NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) - fee - salesPrice - tip;

    setTotalPrice((NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) + fee + salesPrice + tip);
    
    return {selectedUtxos, newDummyUtxos, tip, charge}
  }

  const handleBuyOrdinal = async () => {
    setShowSpinner(true);

    const {selectedUtxos, newDummyUtxos, tip, charge} = await calculateTotalFee();

    if (charge > 0 )
    {
      const data = {
        selectedUtxos: selectedUtxos,
        dummyUtxos: newDummyUtxos,
        pubkey: wallet.paymentPublicKey,
        address: wallet.nostrPaymentAddress,
        charge: charge,
        id: id,
        tip: tip,
        tipAddress: marketTipAddress,
        provider: wallet.domain,
        inscriptionId: inscriptionId,
        ordinalAddress: wallet.nostrOrdinalsAddress
      };

      let psbt = await buyOrdinals(data);
      if (psbt == "fail") {
        toast.error("This item is no longer available for sale.");
        setShowSpinner(false);
        setShow(false);
        return;
      }

      try {
        if (wallet.domain == "tapwallet") {
          let res = await window.tapwallet.signPsbt(psbt);
          let txId = await window.tapwallet.pushPsbt(res);
        }
        if (wallet.domain == "unisat")
        {
          let res = await window.unisat.signPsbt(psbt);
          let txId = await window.unisat.pushPsbt(res);
        }
        if (wallet.domain == "okxwallet")
        {
          let res = await window.okxwallet.bitcoin.signPsbt(psbt);
          let txid = await window.okxwallet.bitcoin.pushPsbt(res);
        }
        if (wallet.domain == "magiceden") {
          let signIndexes = [];
          for(let i=0;i<2; i++){
            signIndexes.push(i);
          }
          for(let i=3;i<3 + selectedUtxos.length; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: psbt,
                broadcast: false,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                        sigHash: 1
                    },
                ],
            },
            onFinish: async (response) => {  
              let res_data = await getPaymentTx(response.psbtBase64, signIndexes);

              await fetch('https://mempool.space/api/tx', {
                method: 'POST',
                headers: {
                  'Content-Type': 'text/plain'
                },
                body: res_data.hex
              })
              
              let marketplaceData = {
                id: id,
                buyerAddress: wallet.nostrPaymentAddress,
                reason: "buy"
              }
          
              await saveMarketplaceData(marketplaceData);  
                        
              let params = {
                address : wallet.nostrOrdinalsAddress,
                point : 1,
                reason : "Buy",
                deleteFlag : 0
              }
              await savePointData(params);
              
              setShowSpinner(false);
              setShow(false);
              toast.success("Successful!");
              handleRefresh();
              return;
            },
            onCancel: () => {
              toast.error("User reject request!")
              setShowSpinner(false);
              setShow(false);
              return;
            },
          });
          return;
        }

        if (wallet.domain == "xverseWallet") {
          let signIndexes = [];
          for(let i=0;i<2; i++){
            signIndexes.push(i);
          }
          for(let i=3;i<3 + selectedUtxos.length; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: psbt,
                broadcast: false,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                        sigHash: 1
                    },
                ],
            },
            onFinish: async (response) => {  
              let res_data = await getPaymentTx(response.psbtBase64, signIndexes);

              await fetch('https://mempool.space/api/tx', {
                method: 'POST',
                headers: {
                  'Content-Type': 'text/plain'
                },
                body: res_data.hex
              })
              
              let marketplaceData = {
                id: id,
                buyerAddress: wallet.nostrPaymentAddress,
                reason: "buy"
              }
          
              await saveMarketplaceData(marketplaceData);  
              
              let params = {
                address : wallet.nostrOrdinalsAddress,
                point : 1,
                reason : "Buy",
                deleteFlag : 0
              }
              await savePointData(params);

              setShowSpinner(false);
              setShow(false);
              toast.success("Successful!");
              handleRefresh();
              return;
            },
            onCancel: () => {
              toast.error("User reject request!")
              setShowSpinner(false);
              setShow(false);
              return;
            },
          });
          return;
        }

      } catch (e) {
        toast.error("User reject request!");
        console.log(e);
        setShowSpinner(false);
        setShow(false);
        return;
      }
    }
    else
    {
      toast.error("Insufficient balance!");
      setShowSpinner(false);
      setShow(false);
      return;
    }

    let marketplaceData = {
      id: id,
      buyerAddress: wallet.nostrPaymentAddress,
      reason: "buy"
    }

    await saveMarketplaceData(marketplaceData);  

    let params = {
      address : wallet.nostrOrdinalsAddress,
      point : 1,
      reason : "Buy",
      deleteFlag : 0
    }
    await savePointData(params);
    
    setShowSpinner(false);
    setShow(false);
    toast.success("Successful!");
    handleRefresh();
  }

  const handleAddress = () => {
    let keyData = {address: ownerAddress, domain: domain ? domain: ""};
    dispatch(updateKey(keyData));
    navigate('/explorer');
  }

  return (
    <div className="gap-1 bg-gray-900 border-solid border-gray-700 rounded-md border-[1px] p-2 w-full max-w-[320px] m-auto">
      <span className="font-bold px-1 cursor-pointer hover:text-primary" onClick = {() => {handleActivity(2, ticker)}}>{ticker} {blk}</span>
      {
        domain ? 
          <div className="flex flex-row items-center">
            <div className="pl-1 pr-2 text-blue-800 cursor-pointer" onClick={handleAddress}>
              {domain}
            </div>
            <ChatButton targetAddress = {ownerAddress}/>
          </div> : 
          <div className="px-1 text-blue-800 cursor-pointer" onClick={handleAddress}>
            {formatAddress(ownerAddress)}
          </div> 
      }
      <div className="flex flex-row w-full justify-center mt-1">
        {
          contentId != "" ?
            <iframe srcDoc={htmlContent} scrolling="no" sandbox="allow-scripts" loading="lazy" className="w-[256px] h-[256px]"></iframe> : <></>
            
        }
        {
          contentId == "" && ticker != 'natfishes' && ticker != 'natdogs' && ticker.toLowerCase() != "shrooms" ? <iframe src={`https://ordinals.com/preview/${inscriptionId}`} scrolling="no" sandbox="allow-scripts" loading="lazy" className="w-[256px] h-[256px] rounded-md" ></iframe> :<></>
        }
        {
          contentId == "" && ticker == 'natfishes' ? <img src={`https://cdn.arrival.so/natfish_images/image_${blk}.png`}  className="w-[256px] h-[256px] rounded-md" />:<></>
        }
        {
          contentId == "" && ticker == 'natdogs' ? <Natdogs blockNumber = {blk} />:<></>
        }
        {
          contentId == "" && ticker.toLowerCase() == "shrooms" ? <img src={imgUrl}  className="w-[256px] h-[256px] rounded-md" />:<></>
        }
      </div>
      <div className="mt-2 pb-1 px-4 rounded-b-md">
        <a href={`https://ord.io/${number}`}  target="_blank">
          <div className=" w-full border-b-[1px] border-b-solid border-b-gray-900">#{number}</div>
        </a>
        <div className="flex flex-row justify-between mt-1">
          <div className="flex flex-row gap-1 items-center">
            <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#e91d9b"></circle><path d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z" fill="#fff"></path></svg>
            <span className="text-primary">{sats / Math.pow(10, 8) }</span>
          </div>
          <div className="text-gray-400">${Math.round(sats / Math.pow(10, 6) * price)/100}</div>
        </div>
        <div className="w-full flex flex-row items-center justify-center mt-4 mb-2 p-1 gap-1">
          <div className="w-full border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold text-center cursor-pointer hover:border-primary hover:text-primary p-1" onClick = {handleBuy}>
            Buy
          </div>
          <div className="w-[40px] border-[1px] border-borderColor border-solid text-[20px] rounded-md font-bold text-center cursor-pointer p-[6px] hover:border-primary hover:text-primary" onClick = {() => {setShowInfo(true)}}>
            <InformationCircleIcon strokeWidth={2} className="h-6 w-6" />
          </div>
          <div></div>
        </div>
      </div> 
      <Dialog
        open={show}
        size={"sm"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
        {
            mode == 1 ?
            <div className="flex flex-col gap-4 w-full items-center p-3">
              <div className="font-bold sm:text-[24px] text-[18px] text-primary">
                Preparing Your Wallet
              </div>
              <div className="text-white text-justify sm:text-[18px] text-[14px]">
                To enable transactions on our platform, your wallet must be prepared by creating a payment UTXO. This UTXO is utilized to ensure fully decentralized transactions, prioritizing the safety of your Ordinals during transfers. It's important to remember that this UTXO remains in your wallet and will be used for fulfilling Marketplace transactions in the future. Each time you go through this process, it permits you to make a number of concurrent purchases on our platform. If you engage in numerous concurrent transactions, you will need to undertake this process more frequently.
              </div>
              {
                showSpinner ? 
                  <Spinner className="h-12 w-12" color="red"/>
                  :
                  <div className="flex flex-row gap-3">
                    <button className="bg-primary hover:bg-primary-hover text-white rounded-md px-5 font-bold" onClick={handleCreateDummyUtxos}>Prepare your wallet</button>
                    <Button  onClick={() => setShow(false)}>Cancel</Button>
                  </div>
              }
            </div> : <></>
          }
          {
            mode == 2 ?
            <div className="flex flex-col gap-4 w-full items-center p-3">
              <div className="font-bold sm:text-[24px] text-[18px] text-primary">
                Preparing your wallet...
              </div>
              <div className="font-semibold text-center text-white sm:text-[16px] text-[14px]">
                Please wait for the transaction to confirm and finalize your purchase.
              </div>
              <div className="flex flex-row-reverse w-full mt-4 mr-10">
                <button className="bg-primary hover:bg-primary-hover text-white rounded-md px-10 font-bold py-1" onClick={() => setShow(false)}>CLOSE</button>
              </div>
            </div> : <></>
          }
          {
            mode == 3 ?
            <div className="flex flex-col gap-4 w-full items-center p-3">
              <div className="font-bold text-[32px] text-primary mt-4">
                Cart
              </div>
              <div className="flex flex-row gap-2 text-white">
                <div>{ticker} {blk}</div>
                <div>|</div>
                <div>{sats / Math.pow(10, 8)}BTC</div>
              </div>
              <div className="w-full text-white font-bold">
                Network Fee:
              </div>
              <div className="grid grid-cols-3 gap-3 w-full">
                <FeeRateCardNew header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
                <FeeRateCardNew header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
                <FeeRateCardNew header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
              </div>
              {
                feeRateMode == "Custom" ? 
                <div className="w-full">
                  <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
                </div>
                : 
                <></>
              }
              <div className="flex flex-col mt-3 font-bold text-white sm:text-[20px] text-[14px]">
                You pay : {totalPrice / Math.pow(10, 8)}BTC
              </div>
              {
                showSpinner ? 
                  <Spinner className="h-12 w-12" color="red"/>
                  :
                  <div className="flex flex-row gap-3">
                    <button className="bg-primary hover:bg-primary-hover text-white rounded-md px-10 font-bold" onClick={handleBuyOrdinal}>BUY</button>
                    <Button  onClick={() => setShow(false)}>RETURN</Button>
                  </div>
              }
            </div> : <></>
          }
        </DialogBody>
      </Dialog>
      <Dialog
        open={showInfo}
        size={"xs"}
        className="bg-black border-solid border-[1px] border-gray-600"
      >
        <DialogBody>
          <div className="flex flex-col justify-center w-full gap-3 p-3">
            <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
              <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
              <span className="text-[32px] font-bold text-primary">Details</span>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Price</div>
              <div className="text-white">{amount * sats / Math.pow(10, 8)} BTC</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Ticker</div>
              <div className="text-white">{ticker}</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Block</div>
              <div className="text-white">{blk}</div>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Inscription id</div>
              <a href={`https://ordinals.com/inscription/${inscriptionId}`}  target="_blank">
                <div className="text-white">{formatInscriptionId(inscriptionId)}</div>
              </a>
            </div>
            <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
              <div className="text-[#9a9790]">Owner</div>
              {
                domain ? 
                  <div className="text-white cursor-pointer" onClick={handleAddress}>{domain}</div>
                  :
                  <div className="text-white cursor-pointer" onClick={handleAddress}>{formatInscriptionId(ownerAddress)}</div>
              }
            </div>
          </div>
          <div className="flex flex-row-reverse w-full mt-2">
            <Button  onClick={() => setShowInfo(false)}>RETURN</Button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
