import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash';
import { Input } from '@material-tailwind/react'
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Spinner
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { formatAddress } from '../../util/format-data';

import { getPrizeData, savePrizeData } from '../../util/api';
import { formatTime } from '../../util/format-data';

export default function Point() {

  const [loading, setLoading] = useState(false);
  const [prizeDatas, setPrizeDatas] = useState('');
  const [handledDatas, setHandledDatas] = useState('');

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');

  const [page1, setPage1] = useState(1);
  const [total1, setTotal1] = useState(0);
  const [limit1, setLimit1] = useState(10);
  const [key1, setKey1] = useState('');

  const prizeData = [
    {
      prize: "$TAPZERO",
      point: 100,
      status: false
    },
    {
      prize: "Bitmap",
      point: 125,
      status: false
    },
    {
      prize: "Natfrog",
      point: 150,
      status: false
    },
    {
      prize: "Royal",
      point: 200,
      status: false
    }
  ]

  useEffect(() => {
    updatePrizeData(key, page);
  }, [page])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const debouncedSearch = useCallback(debounce((value) => {
    setPage(1);
    updatePrizeData(value, 1)
  }, 300), []); 

  const handleSearch = (value) => {
    setKey(value);
    debouncedSearch(value);
  };

  const updatePrizeData = async (value, offset = page) => {
    setLoading(true);
    let param = {
      limit: limit,
      offset: offset,
      deleteFlag: 0,
      status: 0
    }

    if (value != "") {
      param.address = value;
    }

    let data = await getPrizeData(param);
    setPrizeDatas(data.data);
    setTotal(Math.ceil(data.total / limit))
    setLoading(false);
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  useEffect(() => {
    updatePrizeData1(key1, page1);
  }, [page])

  const next1 = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev1 = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const debouncedSearch1 = useCallback(debounce((value) => {
    setPage1(1);
    updatePrizeData1(value, 1)
  }, 300), []); 

  const handleSearch1 = (value) => {
    setKey1(value);
    debouncedSearch1(value);
  };

  const updatePrizeData1 = async (value, offset = page) => {
    setLoading(true);
    let param = {
      limit: limit,
      offset: offset,
      deleteFlag: 1,
      status: 1
    }

    if (value != "") {
      param.address = value;
    }

    let data = await getPrizeData(param);
    setHandledDatas(data.data);
    setTotal1(Math.ceil(data.total / limit1))
    setLoading(false);
  }

  const acceptPrize = async (data) => {
    setLoading(true);
    let prizeValue = 0;
    for(let temp of prizeData) {
      if (data.prize == temp.prize) prizeValue = temp.point;
    }

    let param = {
      point: prizeValue,
      address: data.address,
      prize: data.prize,
      id: data.id,
      status: 1,
      deleteFlag: 1
    }
    await savePrizeData(param);
    await updatePrizeData(key);
    await updatePrizeData1(key);

    setLoading(false);
  }

  return (
    <div className="text-white w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      }
      <div className="md:min-w-[600px] md:w-[600px] min-w-[300px] flex flex-col mb-10 w-full">
        <div className="text-white font-bold text-lg w-full text-center border-b-[1px] border-b-[#24242a] pb-1 mb-3">Latest Requests</div>
        <Input type="text" color="white" label = "Search by address" className="w-full md:min-w-[500px] min-w-[250px]" value = {key}
                    onChange = {(e) => {handleSearch(e.target.value)}}/>
        <div className="w-full border-gray-800 border-[1px] border-solid rounded-lg mt-4 bg-[#081817]">
          <div className="w-full grid grid-cols-3 py-4 px-6 bg-[#1e1f1f]">
            <div>Prize</div>
            <div>Address</div>
            <div>Action</div>
          </div>
          {
            prizeDatas.length == 0 ? <div className="w-full flex flex-row py-4 px-6 text-gray-500 hover:bg-[#121313] justify-center">No Request</div> : 
            <>
              {
                prizeDatas.map((data) => {
                  return (
                    <div className="w-full grid grid-cols-3 py-4 px-6 text-gray-500 hover:bg-[#121313] cursor-pointer" key={uuidv4()}>
                      <div>{data.prize}</div>
                      <div onClick={() => handleCopy(data.address)}>{formatAddress(data.address)}</div>
                      <div>
                        <span className="bg-primary hover:bg-primary-hover rounded-md px-4 py-2 text-white font-bold" onClick={() => {acceptPrize(data)}}>ACCEPT</span>
                      </div>
                      
                    </div>
                  )
                })
              }
            </>
          }
        </div>
        {
          total > 1 ? 
          <div className="flex flex-row items-center gap-8 justify-end mt-5">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="white" className="font-normal">
              Page <strong className="text-primary">{page}</strong> of{" "}
              <strong className="text-primary">{total}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          :
          <></>
        }
      </div>

      <div className="md:min-w-[600px] md:w-[600px] min-w-[300px] flex flex-col mb-10 w-full">
        <div className="text-white font-bold text-lg w-full text-center border-b-[1px] border-b-[#24242a] pb-1 mb-3">Handled Requests</div>
        <Input type="text" color="white" label = "Search by address" className="w-full md:min-w-[500px] min-w-[250px]" value = {key}
                    onChange = {(e) => {handleSearch(e.target.value)}}/>
        <div className="w-full border-gray-800 border-[1px] border-solid rounded-lg mt-4 bg-[#081817]">
          <div className="w-full grid grid-cols-3 py-4 px-6 bg-[#1e1f1f]">
            <div>Prize</div>
            <div>Address</div>
            <div>Time</div>
          </div>
          {
            handledDatas.length == 0 ? <div className="w-full flex flex-row py-4 px-6 text-gray-500 hover:bg-[#121313] justify-center">No Request</div> : 
            <>
              {
                handledDatas.map((data) => {
                  return (
                    <div className="w-full grid grid-cols-3 py-4 px-6 text-gray-500 hover:bg-[#121313] cursor-pointer" key={uuidv4()}>
                      <div>{data.prize}</div>
                      <div onClick={() => handleCopy(data.address)}>{formatAddress(data.address)}</div>
                      <div>{formatTime(data.updatedAt)}</div>
                    </div>
                  )
                })
              }
            </>
          }
        </div>
        {
          total > 1 ? 
          <div className="flex flex-row items-center gap-8 justify-end mt-5">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev1}
              disabled={page1 === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="white" className="font-normal">
              Page <strong className="text-primary">{page1}</strong> of{" "}
              <strong className="text-primary">{total1}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next1}
              disabled={page1 === total1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          :
          <></>
        }
      </div>
    </div>
  )
}
