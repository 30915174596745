import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import prizeImg from '../../assets/imgs/prize.jpg'
import { getPointData } from '../../util/api';
import { getPrizeData, savePrizeData } from '../../util/api';
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';

export default function PointCatalog(props) {
  const { keyAddress } = props;

  const [point, setPoint] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(0);
  const [dataList, setDataList] = useState([]);

  const prizeData = [
    {
      prize: "$TAPZERO",
      point: 100,
      status: false
    },
    {
      prize: "Bitmap",
      point: 125,
      status: false
    },
    {
      prize: "Natfrog",
      point: 150,
      status: false
    },
    {
      prize: "Royal",
      point: 200,
      status: false
    }
  ]

  useEffect(() => {
    const fetchPoints = async () => {
      if (keyAddress != "" && keyAddress != undefined) {
        let result = await getPointData(
          {
            address: keyAddress
          }
        )
        if (result.total < 0) 
          setPoint(0);
        else
          setPoint(result.total ?? 0);

        await handleRefresh();
      }   
      else setPoint(0);
    }
    fetchPoints(0);
  }, [keyAddress])

  const handleRefresh = async () => {
    let currentValue = point;
    let param = {
      address: keyAddress,
      deleteFlag: 0,
      status: 0
    }

    let result = await getPrizeData(param);
    let tempPrizeData = prizeData;
    for(let data of tempPrizeData) {
      for(let temp of result.data) {
        if (data.prize == temp.prize) {
          currentValue = currentValue - data.point;
          data.status = true;
          break;
        }
      }
    }

    setCurrentPoint(currentValue);
    setDataList(tempPrizeData);
  }

  const cancelPrize = async (index) => {
    let param = {
      address: keyAddress,
      prize: dataList[index].prize,
      deleteFlag: 1,
      status: 0
    }
    
    await savePrizeData(param);
    await handleRefresh();
    toast.success("Request handled!");
  }

  const requestPrize = async (index) => {
    if (currentPoint >= dataList[index].point) {
      let param = {
        address: keyAddress,
        prize: dataList[index].prize,
        deleteFlag: 0,
        status: 0
      }
      
      await savePrizeData(param);
      await handleRefresh();
    }
    else {
      toast.error("You can not request this prize!");
    }
  }

  return (
    <div className="flex sm:flex-row flex-col w-full mt-8 justify-center gap-8" >
      <div>
        <img className="max-w-[500px] w-full" src={prizeImg}/>
      </div>
      <div className="flex flex-col gap-3 items-center justify-center">
        {
          dataList.map((data, index) => {
            return <div className="flex flex-row justify-between items-center w-[350px]" key={uuidv4()}>
              <div className="sm:text-[32px] text-[24px] font-bold">{data.prize}</div>
              {
                !data.status ? <div className="bg-primary rounded-md hover:bg-primary-hover cursor-pointer px-4 py-2 font-bold" onClick={() => requestPrize(index)}>REDEEM</div>
                :
                <div className="bg-gray-800 rounded-md hover:bg-gray-600 cursor-pointer px-[18px] py-2 font-bold" onClick={() => cancelPrize(index)}>CANCEL</div>
              }
            </div>
          })
        }
      </div>
    </div>
  )
}
