import React, {useEffect, useCallback, useState} from 'react'
import { getUnatHolders } from '../../util/api'
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon, ArrowLongDownIcon, ArrowLongUpIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import toast, { Toaster } from 'react-hot-toast';
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button
} from '@material-tailwind/react';
import { formatUnixTime } from '../../util/format-data';
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from '../../util/format-data';
import { getUnisatRunesTopIndex } from '../../util/unisat-api';
import { getBtcPrice } from '../../util/api';
import { getRunes } from '../../util/api';

export default function RuneTopIndexer(props) {

  const { handle } = props;
  const [ticker, setTicker] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState(0);
  const [sort, setSort] = useState("sixHourMints");
  const [loading, setLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [runeDatas, setRuneDatas] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [detailticker, setDetailTicker] = useState({});
  const [direct, setDirect] = useState(1);
  const [showInfo, setShowInfo] = useState(false);
  const [runeDataDetail, setRuneDataDetail] = useState({});
  const [price, setPrice] = useState(0);
  
  useEffect(() => {
    handlePrice();
  }, [])

  useEffect(() => {
    fetchTokenData(ticker, page);
  }, [page, sort])

  const handlePrice =  async () => {
    let tempPrice = await getBtcPrice();
    setPrice(tempPrice);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const handleKeyChange = (e) => {
    setTicker(e.target.value)
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    try {
      await fetchTokenData(value, 1);
      setPage(1);
    }
    catch (e) {
      console.log(e);
    }
  }, 300), []); 

  const fetchTokenData = async (value, page) => {
    setLoading(true);
    let data = await getUnisatRunesTopIndex(value, sort,  (page - 1) * limit, limit);

    if (data.data.detail.length > 0) {
      let tickers = [];
      for(let temp of data.data.detail) {
        tickers.push(temp.rune);
      }
      let tempDatas = await getRunes(tickers);

      for(let data1 of data.data.detail){
        for(let temp of tempDatas) {
          if (data1.spacedRune == temp.name) {
            data1.price = temp.price;
            data1.marketCap = temp.marketCap;
            data1.imageURI = temp.imageURI;
            data1.volume24H = temp.volume1d;
          }
        }
      }
    }

    console.log(data.data.detail);

    setRuneDatas(data.data.detail);
    setTotal(Math.ceil(data.data.total / limit));
    setLoading(false);
  }

  const handleOrder = (value) => {
    setSort(value);
  }

  const showDetailIndexer = (value) => {
    setDetailTicker(value);
    setShowDetail(true);
  }

  const handleShowInfo = async (value) => {
    setShowInfo(true);
    setRuneDataDetail(value);
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success("Token Id copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const handleMint = (value) => {
    handle(value);
  }

  return (
    <div className="text-white w-full max-w-[1500px] flex flex-col justify-center items-center">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
        showDetail ? <></>: 
        <>
          {
            <div className="flex flex-col gap-2 max-w-[1300px] mt-10">
              { 
                <div className="mt-5 flex flex-row w-full sm:justify-between justify-end gap-2 items-center">
                  <div className="sm:flex hidden px-4 py-[6px] border-solid border-[1px] rounded-md text-[20px] border-primary text-primary font-bold">
                    HOT MINTS
                  </div>
                  <div>
                    <Input type="text" color="white" label = "Search by rune" className="w-[320px]" value = {ticker} onChange = {(e) => {handleKeyChange(e)}}/>
                  </div>
                </div>
              }
              <div className="flex flex-row px-4 py-2 gap-4">
                <div className = "w-[220px]">Name</div>
                <div className = "md:w-[105px] hidden md:flex flex-row gap-3 items-center">
                    <span>Progress</span>
                </div>
                <div className = "w-[60px]">
                    <span>Action</span>
                </div>
              </div>
              {
                runeDatas.map((data) => 
                  <div 
                    className="flex flex-row text-white bg-gray-900 p-4 rounded-lg items-center gap-4" 
                    key={uuidv4()}
                  >
                    <div className = "w-[220px] text-primary font-bold flex flex-row gap-2 items-center" >
                      {
                        data.imageURI != "" ?
                        <img src={data.imageURI} className="w-[45px] h-[45px] rounded-md"/>
                        :
                        <div className="w-[45px] h-[45px] rounded-md bg-primary text-white text-[28px] px-3">{data.rune.substring(0,1)}</div>
                      }
                      
                      <div className="max-w-[160px] break-words">{data.spacedRune}</div>
                    </div>
                    <div className = "w-[105px] hidden md:flex flex-col items-center gap-1">
                        {
                            parseInt(data.remaining) == 0 ?
                                <div className="text-blue-800">
                                    100%
                                </div>
                                :
                                <div className="text-blue-800">
                                    {Math.ceil(parseInt(data.supply) / (parseInt(data.supply) + parseInt(data.remaining)) * 10000)/100}%
                                </div>
                        }
                        <div className="w-[105px]">
                            {
                                parseInt(data.remaining) == 0 ?
                                    <Progress  value={100} color="blue" size="sm" /> :
                                    <Progress  value={Math.ceil(parseInt(data.supply) / (parseInt(data.supply) + parseInt(data.remaining)) * 100)} color="blue" size="sm" />
                            }
                        </div>                        
                    </div>
                    <div className = "w-[60px]">
                        <div className="px-3 py-1 rounded-md bg-primary cursor-pointer" onClick = {() => handleMint(data.spacedRune)}>MINT</div>
                    </div>
                  </div>
                )
              }
            </div> 
          }
          <Dialog
            open={showInfo}
            size={"xs"}
            className="bg-black border-solid border-[1px] border-gray-600"
          >
            <DialogBody>
              {
                infoLoading ?
                <div className="flex flex-col w-full gap-3 p-3 h-[400px]">
                  <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
                    <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
                    <span className="text-[32px] font-bold text-primary">Details</span>
                  </div>
                  <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/>
                </div>
                :
                <div className="flex flex-col justify-center w-full gap-3 p-3">
                  <div className="flex flex-row text-center justify-center items-center w-full text-primary gap-1">
                    <InformationCircleIcon strokeWidth={2} className="h-12 w-12" />
                    <span className="text-[32px] font-bold text-primary">Details</span>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Token Name</div>
                    <div className="text-white break-words">{runeDataDetail.spacedRune}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Rune #</div>
                    <div className="text-white">{runeDataDetail.number}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Symbol</div>
                    <div className="text-white">{runeDataDetail.symbol}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Rune Id</div>
                    <div className="text-white">{runeDataDetail.runeid}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Price</div>
                    <div className="text-white">$ {runeDataDetail.price * Math.pow(10, runeDataDetail.divisibility) * price / Math.pow(10, 8)}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">MarketCap</div>
                    <div className="text-white">{runeDataDetail.marketCap} BTC</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Volume24H</div>
                    <div className="text-white">{runeDataDetail.volume24H / Math.pow(10, 8)} BTC</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Supply</div>
                    <div className="text-white">{parseInt(runeDataDetail.supply / Math.pow(10, runeDataDetail.divisibility))}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Divisibility</div>
                    <div className="text-white">{runeDataDetail.divisibility}</div>
                  </div>                  
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Transactions</div>
                    <div className="text-white">{runeDataDetail.transactions}</div>
                  </div>
                  <div className="flex flex-col border-b-[1px] border-gray-900 pb-1">
                    <div className="text-[#9a9790]">Premine</div>
                    <div className="text-white">{parseInt(runeDataDetail.premine / Math.pow(10, runeDataDetail.divisibility))}</div>
                  </div>
                </div>
              }

              <div className="flex flex-row-reverse w-full mt-2">
                <Button  onClick={() => setShowInfo(false)}>RETURN</Button>
              </div>
            </DialogBody>
          </Dialog>
          {
            total > 1 ? 
            <div className="flex flex-row items-center gap-8 justify-end mt-5">
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doublePrev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
              <Typography color="white" className="font-normal">
                Page <strong className="text-primary">{page}</strong> of{" "}
                <strong className="text-primary">{total}</strong>
              </Typography>
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={next}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doubleNext}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
            :
            <></>
          }
        </>
      }
      
    </div>
  )
}
