import React from 'react';
import {useEffect, useState} from 'react';

import { 
    Input,
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList
  } from '@material-tailwind/react';
import { CheckIcon, UserCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { getDomainAddress } from '../../util/api';

export default function SidebarItem(props) {
  const {address, timestamp, message, activeContact} = props;

  const [name, setName] = useState("");

  useEffect(() => {
    fetchName();
  }, []);

  const fetchName = async () => {
    let response = await getDomainAddress(address);
    if (response.total > 0) {
      setName(response.domain)
    }
    else setName("");
  }

  return (
    <div className={`flex flex-row w-full gap-2 items-center px-6 py-2 hover:bg-gray-900 cursor-pointer relative group ${activeContact == address ? 'bg-gray-900' : ''}`}>
      <div className="w-[50px] h-[50px] rounded-md bg-primary text-white text-[32px] text-center font-bold">
        {name.substring(0,1)}
      </div>
      <div className="flex flex-col">
        <div className="font-bold text-white">{name}</div>
        <div className="text-white text-[14px] flex flex-row">
          <span className="text-[14px] truncate block max-w-[130px]">{message}</span>
          <span className="text-gray-500 font-bold text-transparent">・</span>
          {/* <span className="text-[14px] text-gray-500">{timestamp}</span> */}
        </div>
      </div>
      <div className={`absolute right-[20px]`}>
      {/* <Menu placement="left-start">
          <MenuHandler>
            <div className="rounded-full bg-transparent text-transparent group-hover:text-white group-hover:bg-blue-gray-500 w-[32px] h-[32px] text-center">...</div>
          </MenuHandler>
          <MenuList className="bg-black border-none outline-none flex flex-col gap-1">
              <div className="flex flex-row gap-2 items-center cursor-pointer p-1">
                <div className="text-white"><CheckIcon strokeWidth={2} className="h-4 w-4" /></div>
                <div className="text-white">Mark as Read</div>
              </div>
              <div className="flex flex-row gap-2 items-center cursor-pointer p-1">
                <div className="text-white"><UserCircleIcon strokeWidth={2} className="h-4 w-4" /></div>
                <div className="text-white">View Profile</div>
              </div>
              <div className="flex flex-row gap-2 items-center cursor-pointer p-1">
                <div className="text-white"><TrashIcon strokeWidth={2} className="h-4 w-4" /></div>
                <div className="text-white">Delete</div>
              </div>
          </MenuList>
        </Menu> */}
      </div>
    </div>
  )
}
