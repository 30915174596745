import React, {useEffect, useCallback, useState} from 'react'
import { getUnatHolders } from '../../util/api'
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';
import { formatUnixTime } from '../../util/format-data';
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from '../../util/format-data';
import UnatIndexerDetail from './UnatIndexerDetail';

export default function UnatIndexer() {
  const [ticker, setTicker] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState(1);
  const [loading, setLoading] = useState(false);
  const [unatDatas, setUnatDatas] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [detailticker, setDetailTicker] = useState('');

  
  useEffect(() => {
    fetchTokenData(ticker, page);
  }, [page, order])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }

  const handleKeyChange = (e) => {
    setTicker(e.target.value)
    debouncedSearch(e.target.value)
  }

  const handleOrder = (mode) => {
    setOrder(mode);
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    try {
      await fetchTokenData(value, 1);
      setPage(1);
    }
    catch (e) {
      console.log(e);
    }
  }, 300), []); 

  const fetchTokenData = async (value, page) => {
    setLoading(true);
    let data = await getUnatHolders(value, limit, page, order);
    setUnatDatas(data.data);
    setTotal(Math.ceil(data.total / limit));
    setLoading(false);
  }

  const showDetailIndexer = (value) => {
    setDetailTicker(value);
    setShowDetail(true);
  }
  return (
    <div className="text-white w-full max-w-[1500px] flex flex-col justify-center items-center">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
        showDetail ? <UnatIndexerDetail ticker = {detailticker} handleShowDetail = {setShowDetail} setLoading = {setLoading}/> : 
        <>
          {
            <div className="flex flex-col gap-2 max-w-[1000px] w-full mt-5">
              { 
                <div className="mt-5 flex flex-row w-full justify-end gap-2">
                  <div>
                    <Input type="text" color="white" label = "Search by ticker" className="w-[250px]" value = {ticker} onChange = {(e) => {handleKeyChange(e)}}/>
                  </div>
                  <Menu placement="bottom-end">
                    <MenuHandler>
                      <IconButton
                        size="md"
                        variant="outlined"
                        className="border-white"
                      >
                        <FunnelIcon strokeWidth={2} className="h-7 w-7 text-primary" />
                      </IconButton>
                    </MenuHandler>
                    <MenuList className="bg-black border-black text-white">
                      <MenuItem onClick={() => handleOrder(1)}>Descending Time</MenuItem>
                      <MenuItem onClick={() => handleOrder(2)}>Ascending Time</MenuItem>
                      <MenuItem onClick={() => handleOrder(4)}>Descending Holders</MenuItem>
                      <MenuItem onClick={() => handleOrder(3)}>Ascending Holders</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              }
              <div className="flex flex-row px-4 py-2">
                <div className = "md:w-[20%] w-[60%]">Ticker</div>
                <div className = "md:w-[13%] w-[40%]">Holders</div>
                <div className = "md:w-[25%] md:block hidden">Element</div>
                <div className = "md:w-[14%] md:block hidden">DeploymentId</div>
                <div className = "md:w-[14%] md:block hidden">Deployer</div>
                <div className = "md:w-[14%] md:block hidden">Time</div>
              </div>
              {
                unatDatas.map((data) => 
                  <div 
                    className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg cursor-pointer" 
                    key={uuidv4()}
                    onClick = {() => showDetailIndexer(data.tick)}
                  >
                    <div className = "md:w-[20%] w-[60%] text-primary font-bold">dmt-{data.tick}</div>
                    <div className = "md:w-[13%] w-[40%]">{data.holders}</div>
                    <div className = "md:w-[25%] md:block hidden">{data.elem}</div>
                    <div className = "md:w-[14%] md:block hidden hover:text-primary">
                      <a href={`https://ordinals.com/inscription/${data.inscriptionId}`} target="_blank" rel="noopener noreferrer" >{formatAddress(data.originalInscriptionId)}</a></div>
                    <div className = "md:w-[14%] md:block hidden">{formatAddress(data.address)}</div>
                    <div className = "md:w-[14%] md:block hidden">{formatUnixTime(data.time)}</div>
                  </div>
                )
              }
            </div> 
          }
          {
            total > 1 ? 
            <div className="flex flex-row items-center gap-8 justify-end mt-5">
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doublePrev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prev}
                  disabled={page === 1}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
              <Typography color="white" className="font-normal">
                Page <strong className="text-primary">{page}</strong> of{" "}
                <strong className="text-primary">{total}</strong>
              </Typography>
              <div className="flex flex-row items-center gap-2">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={next}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={doubleNext}
                  disabled={page === total}
                  className="bg-primary hover:bg-primary-hover"
                >
                  <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
            :
            <></>
          }
        </>
      }
      
    </div>
  )
}
