import logo from './logo.svg';
import './App.css';
import Indexer from './pages/Indexer';
import wrapper from "./store/store";
import WalletProvider from './components/WalletProvider';
import { WalletStandardProvider } from '@wallet-standard/react';

import {
  BrowserRouter as Router,
} from "react-router-dom";

function App() {
  return (
    <WalletStandardProvider>
      <WalletProvider>
        <Router>
          <Indexer />
        </Router>
      </WalletProvider>
    </WalletStandardProvider>
  );
}

export default App;
