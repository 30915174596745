import React, {useState, useEffect, useCallback} from 'react'
import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty_name, tip } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { latestDomain, checkDomain, registerPayment, lockDomain, unlockDomain, calculateFee, getPaymentTx, getPaymentHistory, savePaymentHistory, getAllDomains} from '../util/api'
import { registerDomainNew } from '../util/new-api';
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt } from '../util/inscribe-util';
import { sendBtcTransaction } from 'sats-connect';
import { BitcoinNetworkType, signMessage, signTransaction } from 'sats-connect';
import { getPaymentUtxos } from '../util/new-api';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';
import { savePointData } from '../util/api';

export default function MintDomain() {
  const wallet = useSelector(state => state.wallet);

  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const [address, setAddress] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');

  useEffect(() => {
    updateDomains(key, page);
  }, [page])

  const updateDomains = async (value, offset) => {
    setLoading(true);
    let data = await getAllDomains(value, offset, limit);
    setDomains(data.data);
    setTotal(Math.ceil(data.total / limit))
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const debouncedSearch = useCallback(debounce((value) => {
    setPage(1);
    updateDomains(value, 1)
  }, 300), []); 

  const handleSearch = (value) => {
    setKey(value);
    debouncedSearch(value);
  };

  const handleRegister = async () => {

    if (domain == "") {
      toast.error("Please insert name!");
      return;
    }

    if (wallet.nostrPaymentAddress == "")
    {
      toast.error("Please connect your wallet!");
      return;
    }

    if (address == "") {
      toast.error("Please insert wallet address!");
      return;
    }

    let data = await checkDomain(domain);
    if (data.isExists)
    {
      toast.error("Name already exists!");
      return;
    }

    setLoading(true);
    await lockDomain(domain);

    const results = await getPaymentHistory();
    const toggleValue = results[0].value;

    let total_fees = royalty_name; // 1: input counts, 2: output counts

    let fundingAddress = "";

    if (toggleValue == 0)
      fundingAddress = tippingAddress;
    else
      fundingAddress = ownerAddress;

    let isSuccess = true;

    try{
      if (wallet.domain == "tapwallet") {
        await window.tapwallet.sendBitcoin(fundingAddress, total_fees);
      }
      if (wallet.domain == "unisat") {
        await window.unisat.sendBitcoin(fundingAddress, total_fees);
      }
      if (wallet.domain == "okxwallet") {
        await window.okxwallet.bitcoin.sendBitcoin(fundingAddress,total_fees);
      }
      if (wallet.domain == "xverseWallet") {
        let fundingAddressList = [];
        fundingAddressList.push(fundingAddress);
        let fees = await getFeeRate();
        let res = await getPaymentUtxos(wallet.nostrPaymentAddress, fundingAddressList, total_fees, wallet.nostrPaymentAddress, 0, wallet.paymentPublicKey, Math.ceil(fees["Fast"] * 1.3), 1, wallet.domain);
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }
 
          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {

            },
            onCancel: () => {
                alert('Request canceled');
                unlockDomain(domain);
                setLoading(false);
                isSuccess = false;
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          unlockDomain(domain);
          setLoading(false);
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let fundingAddressList = [];
        fundingAddressList.push(fundingAddress);
        let fees = await getFeeRate();
        let res = await getPaymentUtxos(wallet.nostrPaymentAddress, fundingAddressList, total_fees, wallet.nostrPaymentAddress, 0, wallet.paymentPublicKey, fees["Fast"], 1, wallet.domain);
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: () => {
                alert('Request canceled');
                unlockDomain(domain);
                setLoading(false);
                isSuccess = false;
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          unlockDomain(domain);
          setLoading(false);
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      unlockDomain(domain);
      setLoading(false);
      return;
    }

    if (isSuccess) {
      await savePaymentHistory(1 - toggleValue);
      await registerDomainNew(domain, address);
      
      // let params = {
      //   address : address,
      //   point : 5,
      //   reason : "Register Domain",
      //   deleteFlag : 0
      // }
      // await savePointData(params);

      toast.success("Name registration successful!");
      await updateDomains(key, page);
    }

    setLoading(false);
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      }
      <div className="text-white font-bold sm:text-[36px] text-[24px] mt-10 text-center font-title">Register your name</div>
      <div className="md:min-w-[600px] min-w-[300px] border-gray-800 border-[1px] border-solid p-8 rounded-lg mt-5 flex flex-col gap-8 bg-[#081817] md:w-[600px] w-full">
        <div>
          <Input type="text" color="white" label = "Insert Name Here" className="w-full md:min-w-[500px] min-w-[250px]" onChange = {(e) => {setDomain(e.target.value)}}/>
        </div>
        <div>
          <Input type="text" color="white" label = "Linked Wallet Address" className="w-full md:min-w-[500px] min-w-[250px]" onChange = {(e) => {setAddress(e.target.value)}}/>
        </div>
        <div className="flex flex-row w-full justify-center">
          <button className="bg-primary hover:bg-primary-hover rounded-md py-2 min-w-[240px]" onClick={handleRegister}>Register</button>
        </div>
      </div>
      <div className="md:min-w-[600px] md:w-[600px] min-w-[300px] flex flex-col mt-10 mb-10 w-full">
        <div className="text-white font-bold text-lg w-full text-center border-b-[1px] border-b-[#24242a] pb-1 mb-3">Latest registrations</div>
        <Input type="text" color="white" label = "Search by name or address" className="w-full md:min-w-[500px] min-w-[250px]" value = {key}
                    onChange = {(e) => {handleSearch(e.target.value)}}/>
        <div className="w-full border-gray-800 border-[1px] border-solid rounded-lg mt-4 bg-[#081817]">
          <div className="w-full grid grid-cols-2 py-4 px-6 bg-[#1e1f1f]">
            <div>Name</div>
            <div>Address</div>
          </div>
          {
            domains.length == 0 ? <div className="w-full flex flex-row py-4 px-6 text-gray-500 hover:bg-[#121313] justify-center">No Name</div> : 
            <>
              {
                domains.map((data) => {
                  return (
                    <div className="w-full grid grid-cols-2 py-4 px-6 text-gray-500 hover:bg-[#121313] cursor-pointer" key={uuidv4()}>
                      <div onClick={() => handleCopy(data.domain, 1)}>{data.domain}</div>
                      <div onClick={() => handleCopy(data.address, 2)}>{formatAddress(data.address)}</div>
                    </div>
                  )
                })
              }
            </>
          }
        </div>
        {
          total > 1 ? 
          <div className="flex flex-row items-center gap-8 justify-end mt-5">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="white" className="font-normal">
              Page <strong className="text-primary">{page}</strong> of{" "}
              <strong className="text-primary">{total}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          :
          <></>
        }
      </div>
    </div>
  )
}
