import CryptoJS from 'crypto-js';
const password = process.env.REACT_APP_PASSWORD;

const apiUrl1 = "https://api.tapwallet.io";
const apiUrl2 = "https://api2.tapwallet.io";
const apiUrl4 = "https://api4.tapwallet.io";

export const getPaymentUtxosRunes = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount, provider) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxosRunes';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount),
    provider: provider
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const mintRuneStart = async (randomKey, transactionDatas) => {
  
  const url = `${apiUrl4}/runes/mint/start`;
  const data = {
    randomKey : randomKey,
    transactionDatas : transactionDatas
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const mintRuneCancel = async (randomKey) => {
  
  const url = `${apiUrl4}/runes/mint/cancel`;
  const data = {
    randomKey : randomKey
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const mintRunePrepare = async (runeKey, count, receiveAddress, outputValue, feeRate) => {
  
  const url = `${apiUrl4}/runes/mint/prepare`;
  const data = {
    runeKey : runeKey,
    count : count,
    receiveAddress : receiveAddress,
    outputValue : outputValue,
    feeRate : feeRate
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const checkRuneName = async (name) => {
  
  const url = `${apiUrl2}/runes/name/check`;
  const data = {
    name: name
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const registerRunesPayment = async (privateKey, receiverAddress, runekey) => {
  
  const url = 'https://api.tapwallet.io/runes/payment/register';
  const data = {
    privateKey: privateKey,
    receiverAddress: receiverAddress,
    runekey: runekey
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const updateRunesPayment = async (privateKey) => {
  
  const url = 'https://api.tapwallet.io/runes/payment/update';
  const data = {
    privateKey: privateKey,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const runesPaymentIndex = async () => {
  
  const url = 'https://api.tapwallet.io/runes/payment/index';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerDomainNew = async (domain, address) => {
  
  const url = 'https://api2.tapwallet.io/domain/register';
  const data = {
    data: CryptoJS.AES.encrypt(domain + address, password).toString(),
    domain: domain,
    address: address
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const updateBlockdropMint = async (key) => {
  
  const url = 'https://api2.tapwallet.io/patoken/blockdrop/recover';
  const data = {
    privKey: key 
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getBlockdropBlockRandom = async (number, patternField, patternValue, dt) => {

  const url = `https://api2.tapwallet.io/blockdrop/block/random?patternField=${patternField}&patternValue=${patternValue}&number=${number}&dt=${dt}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getBlockdropBlock = async (number, patternField, patternValue, block, offset = 1, limit = 25, dt) => {

  const url = `https://api2.tapwallet.io/blockdrop/block?block=${block}&patternField=${patternField}&patternValue=${patternValue}&offset=${offset}&limit=${limit}&number=${number}&dt=${dt}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerBlockdropTokenRandom = async (address, transaction, number, blk) => {
  
  const url = 'https://api2.tapwallet.io/random_token/blockdrop/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
    number: number,
    blk: blk
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerBlockdropPaymentRandom = async (key, address, number, inscriptionAddress, parent_inscription) => {
  
  const url = 'https://api.tapwallet.io/random_payment/blockdrop/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
    number: number,
    parent_inscription: parent_inscription
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const latestBlockdropTokenRandom = async (key, number,  offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/random_token/blockdrop/latest?key=${key}&offset=${offset}&limit=${limit}&number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getBlockdropSignMintTextRandom = async (address, blk, number, depId) => {
  
  const url = 'https://api2.tapwallet.io/blockdrop/_signMintRandom';
  const data = {
    address: address,
    blk: parseInt(blk),
    number: number,
    dep: depId
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}


export const getBlockdropPRVInscriptionContent = async (ticker) => {
  const url = `https://api2.tapwallet.io/blockdrop/_signAuthRandomNew`;

  const data = {
    ticker: ticker
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const cancelBlockdropPRVInscriptionContent = async (ticker, pubKey) => {
  const url = `https://api2.tapwallet.io/blockdrop/_signAuthRandomNew/cancel`;

  const data = {
    ticker: ticker,
    pubKey: pubKey
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getBlockdropPATokenMintTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/mint/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getBlockdropPATokenDeployTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/deploy/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const checkBlockdropPATokenDeployTicker = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/deploy/ticker/check?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}


export const getBlockdropPATokenDetail = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/get?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return await response.json();

}

export const saveBlockdropPATokenDetail = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/blockdrop/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getBlockdropPATokenWhitelistAddresses = async (number) => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/whitelist/get?number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getBlockdropPATokenWhitelistAddressCheck = async (number, address) => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/whitelist/check?number=${number}&address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const saveBlockdropPATokenWhitelistAddresses = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/blockdrop/whitelist/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()
  data.passwordKey = "";

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const checkBlockdropPassword = async (oldPassword, ticker) => {
  const url = `https://api2.tapwallet.io/patoken/blockdrop/checkPassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const updateBlockdropPATokenPassword = async (oldPassword, newPassowrd, ticker) => {

  const url = `https://api2.tapwallet.io/patoken/blockdrop/updatePassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    newPassword:  CryptoJS.AES.encrypt(newPassowrd, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getDmtBlockRandom = async (number, patternField, patternValue, dt) => {

  const url = `https://api2.tapwallet.io/dmt/block/random?patternField=${patternField}&patternValue=${patternValue}&number=${number}&dt=${dt}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getDmtBlock = async (number, patternField, patternValue, block, offset = 1, limit = 25, dt) => {

  const url = `https://api2.tapwallet.io/dmt/block?block=${block}&patternField=${patternField}&patternValue=${patternValue}&offset=${offset}&limit=${limit}&number=${number}&dt=${dt}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerDmtTokenRandom = async (address, transaction, number, blk) => {
  
  const url = 'https://api2.tapwallet.io/random_token/dmt/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
    number: number,
    blk: blk
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerDmtPaymentRandom = async (key, address, number, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/random_payment/dmt/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const latestDmtTokenRandom = async (key, number,  offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/random_token/dmt/latest?key=${key}&offset=${offset}&limit=${limit}&number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getDmtSignMintTextRandom = async (address, blk, number, depId) => {
  
  const url = 'https://api2.tapwallet.io/dmt/_signMintRandom';
  const data = {
    address: address,
    blk: parseInt(blk),
    number: number,
    dep: depId
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}


export const getDmtPRVInscriptionContent = async (ticker) => {
  const url = `https://api2.tapwallet.io/dmt/_signAuthRandomNew`;

  const data = {
    ticker: ticker
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const cancelDmtPRVInscriptionContent = async (ticker, pubKey) => {
  const url = `https://api2.tapwallet.io/dmt/_signAuthRandomNew/cancel`;

  const data = {
    ticker: ticker,
    pubKey: pubKey
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getDmtPATokenMintTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/dmt/mint/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getDmtPATokenDeployTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/dmt/deploy/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const checkDmtPATokenDeployTicker = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/dmt/deploy/ticker/check?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}


export const getDmtPATokenDetail = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/dmt/get?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return await response.json();

}

export const saveDmtPATokenDetail = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/dmt/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getDmtPATokenWhitelistAddresses = async (number) => {
  const url = `https://api2.tapwallet.io/patoken/dmt/whitelist/get?number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getDmtPATokenWhitelistAddressCheck = async (number, address) => {
  const url = `https://api2.tapwallet.io/patoken/dmt/whitelist/check?number=${number}&address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const saveDmtPATokenWhitelistAddresses = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/dmt/whitelist/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()
  data.passwordKey = "";

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const checkDmtPassword = async (oldPassword, ticker) => {
  const url = `https://api2.tapwallet.io/patoken/dmt/checkPassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const updateDmtPATokenPassword = async (oldPassword, newPassowrd, ticker) => {

  const url = `https://api2.tapwallet.io/patoken/dmt/updatePassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    newPassword:  CryptoJS.AES.encrypt(newPassowrd, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getPRVInscriptionContent = async (ticker) => {
  const url = `https://api2.tapwallet.io/_signAuthRandomNew`;

  const data = {
    ticker: ticker
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const cancelPRVInscriptionContent = async (ticker, pubKey) => {
  const url = `https://api2.tapwallet.io/_signAuthRandomNew/cancel`;

  const data = {
    ticker: ticker,
    pubKey: pubKey
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getPATokenMintTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/mint/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getPATokenDeployTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/deploy/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const checkPATokenDeployTicker = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/deploy/ticker/check?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}


export const getPATokenDetail = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/get?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const savePATokenDetail = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getPATokenWhitelistAddresses = async (number) => {
  const url = `https://api2.tapwallet.io/patoken/whitelist/get?number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getPATokenWhitelistAddressCheck = async (number, address) => {
  const url = `https://api2.tapwallet.io/patoken/whitelist/check?number=${number}&address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const savePATokenWhitelistAddresses = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/whitelist/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const checkPassword = async (oldPassword, ticker) => {
  const url = `https://api2.tapwallet.io/patoken/checkPassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const updatePATokenPassword = async (oldPassword, newPassowrd, ticker) => {

  const url = `https://api2.tapwallet.io/patoken/updatePassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    newPassword:  CryptoJS.AES.encrypt(newPassowrd, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}


export const checkWhiteListedDomain = async (key) => {

  const url = `https://api.tapwallet.io/domain/whitelist?key=${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerPaymentNew = async (key, address, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/new_payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerPaymentRandom = async (key, address, number, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/random_payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const checkTesterAddress = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/token/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const latestTokenNew = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/new_token/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const latestTokenRandom = async (key, number,  offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/random_token/latest?key=${key}&offset=${offset}&limit=${limit}&number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const checkWhiteListedAddress = async (key) => {

  const url = `https://api.tapwallet.io/check/whitelisted?key=${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerTokenNew = async (address, transaction) => {
  
  const url = 'https://api2.tapwallet.io/new_token/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerTokenRandom = async (address, transaction, number, amount) => {
  
  const url = 'https://api2.tapwallet.io/random_token/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
    number: number,
    amount: amount
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getMintStatus = async () => {
  
  const url = `https://api.tapwallet.io/mint/status`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getPaymentUtxos = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount, provider) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxos';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount),
    provider: provider
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentUtxosRandom = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, royaltyAddress, royaltyAmount, paymentPubkey, feeRate, amount, provider) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxosRandom';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    royaltyAddress: royaltyAddress,
    royaltyAmount: royaltyAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount),
    provider: provider
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentUtxosTemp = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxosTemp';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getSignMintTextNew = async (address, amount) => {
  
  const url = 'https://api2.tapwallet.io/_signMintNew';
  const data = {
    address: address,
    amount: parseInt(amount)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}

export const getSignMintTextRandom = async (address, amount, number) => {
  
  const url = 'https://api2.tapwallet.io/_signMintRandom';
  const data = {
    address: address,
    amount: parseInt(amount),
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();

  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}


export const getPaymentHistoryNew = async () => {
  const url = 'https://api.tapwallet.io/payment_history_new';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const savePaymentHistoryNew = async (value) => {
  const url = 'https://api.tapwallet.io/payment_history_new';
  const data = {
    value: value
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = 'https://api.tapwallet.io/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}
