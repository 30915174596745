import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { XMarkIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import SidebarItem from './SidebarItem';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox
} from "@material-tailwind/react";

import {
  useNavigate,
  useLocation
} from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';
import { getAllActiveDomains } from '../../util/api';
import { formatAddressMicro, formatAddressMicroText } from '../../util/format-data';

export default function Sidebar(props) {
  const { address, activeContact, handleActiveContact, handleSidebarActive, domain } = props;

  const navigate = useNavigate();
  const [openNew, setOpenNew] = useState(false);
  const scrollContainerRef = useRef(null);
  const [searchName, setSearchName] = useState("");
  const [names, setNames] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [contactItems, setContactItems] = useState([]); 

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, 'chat-' + formatAddressMicroText(address)), orderBy('timestamp', 'desc')),
      (snapshot) => {
        const data = snapshot.docs.map(doc => doc.data());
        console.log("---------", data);
        setContactItems(data);
        if (data.length > 0) {
          handleActiveContact(data[0].address);
        }
        else handleActiveContact("");
      }
    );
  
    return () => unsubscribe();
  }, [address])

  useEffect(() => {
    fetchNames(searchName, page);
  }, [page])

  const fetchNames = async (name, offset) => {
    let response = await getAllActiveDomains(name, offset);
    setTotal(Math.ceil(response.total / limit));
    if (offset == 1) {
      setNames(response.data);
    }
    else {
      let tempNames = [];
      for(let name of names) {
        tempNames.push(name);
      }
      for(let data of response.data) {
        tempNames.push(data);
      }
      setNames(tempNames);
    }
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    await fetchNames(value, 1);  
  }, 800), []); 

  const handleOpenNew = () => {
    setOpenNew(false);
  }

  const handleSearchName = (e) => {
    setSearchName(e.target.value);
    debouncedSearch(e.target.value);
    setPage(1);
  }

  const handleScroll = () => {
    const element = scrollContainerRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setPage(page + 1);
    }
  };

  const handleAdd = async (targetAddr) => {
    for(let item of contactItems) {
      if (item.address == targetAddr)
      {
        setOpenNew(false);
        return;
      }
    }
    // firesore register chat-targetAdr
    if (address != targetAddr) {
      await saveData(address, targetAddr);
      await saveData(targetAddr, address);
    }
    setOpenNew(false);
  }

  const saveData = async (address1, address2) => {
    const docRef = doc(firestore, "chat-" + formatAddressMicroText(address1), address2);
    const data = {
      address: address2,
      typing: false,
      count: 0,
      isRead: false,
      status: false,
      message: "",
      timestamp: serverTimestamp(),
    }

    await setDoc(docRef, data, { merge: true});
  }

  const showOpenNew = () => {
    if (address == "") {
      toast.error("Please connect your wallet!");
    }
    else {
      if (domain == "") {
        toast.error("Please register a name!");
        navigate('/register');
      }
      else {
        setOpenNew(true);
      }
    }
  }
  return (
    <div className="h-custom-height-64 md:w-[320px] w-full min-w-[320px] border-r-[#24242a] border-r-[2px] border-r-solid text-white">
      <div className="flex flex-row justify-between items-center px-6 py-6">
        <div className="font-bold text-primary text-[24px]">Chats</div>
        <div className="cursor-pointer text-primary text-[18px]" onClick={() => showOpenNew()}>+&nbsp;New chat</div>
      </div>
      <div className="flex flex-col gap-2 h-custom-height-148 overflow-auto">
        {
          contactItems.map((item, index) => 
            <div className="flex flex-row w-full" key={item.address} 
              onClick={
                () => {
                  handleActiveContact(item.address)
                  handleSidebarActive(true)
                }
              }
            >
              <SidebarItem message = {item.message} address = {item.address} timestamp = {"1 days"} activeContact = {activeContact}/>
            </div>
          )
        }
        {
          contactItems.length == 0 ?
          <div className="md:hidden flex flex-row text-center justify-center text-[20px] p-3">Welcome to the BOCM | Bitcoin On-Chain Messenger</div>
          :
          <></>
        }
      </div>
      <Dialog
        open={openNew}
        size={"xs"}
        handler={handleOpenNew}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem] bg-black border-[2px] border-[#24242a]">
          <CardBody className="flex flex-col justify-center items-center gap-4 w-full">
            <div className="flex flex-row justify-between w-full">
              <div className="text-white font-bold text-[24px]" value = {searchName} onChange = {(e) => {handleSearchName(e)}}>Search by Name</div>
              <div className="text-gray-600 cursor-pointer" onClick={() => setOpenNew(false)}><XMarkIcon strokeWidth={2} className="h-6 w-6" /></div>
            </div>
            <div className="mt-2 max-w-[300px] w-full">
              <Input type="text" color="white" label="Search Name" value = {searchName} onChange = {(e) => {handleSearchName(e)}} />
            </div>
            <div 
              className="flex flex-col gap-2 h-[300px] w-full max-w-[300px] overflow-auto"
              onScroll={handleScroll}
              ref={scrollContainerRef}
            >
              {
                names.map((name) => 
                  <div className="flex flex-row justify-between w-full bg-gray-900 px-2 py-1 rounded-md items-center" key={uuidv4()}>
                    <div className="flex flex-col w-full">
                      <div className="text-primary font-bold">{name.domain}</div>
                      <div className="text-gray-600 text-[14px]">{formatAddressMicro(name.address)}</div>
                    </div>
                    <div className="text-white cursor-pointer" onClick={() => handleAdd(name.address)}>
                      <PlusCircleIcon strokeWidth={2} className="h-6 w-6" />
                    </div>
                  </div>
                )
              }
            </div>
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}
