import React from 'react'
import {
  useLocation
} from "react-router-dom";

export default function (props) {
  const location = useLocation();
  const {tick, transferable, available, balance, handle} = props;

  return (
    <div className = "flex flex-col gap-1 bg-gray-900 border-solid border-gray-700 rounded-md border-[1px] p-2 w-full cursor-pointer">
      <div className="font-semibold text-primary">{tick}</div>
      <div className="flex flex-row justify-between">
        <div>Transferable</div>
        <div>{transferable}</div>
      </div>
      <div className="flex flex-row justify-between border-b-gray-700 border-b-soild border-b-[1px] pb-1">
        <div>Available</div>
        <div>{available}</div>
      </div>
      <div className="flex flex-row justify-between">
        <div>Balance</div>
        <div>{balance}</div>
      </div>
      {
        location.pathname.includes('/profile') ? <div className="w-full border-[1px] border-solid text-center rounded-md py-1 mt-2 hover:border-primary hover:text-primary cursor-pointer font-bold" onClick={handle}>
          Trade
        </div> : <></>
      }
      
    </div>
  )
}
