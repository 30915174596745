import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div className="text-white mt-[65px] w-full max-w-[1200px] md:px-7 px-2 flex flex-col justify-center mb-10">
      <div className="mt-5 mb-5 font-bold text-[32px]" >
        TapWallet Privacy Policy
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-[16px]">
        TapWallet.io  recognizes that people who use the product value their privacy. This privacy policy (“Policy”) describes how TapWallet.io (“Company”, “we”, “our”, or “us”) collects, uses, shares, and stores personal information of users of our website, https://tapwallet.io (collectively the “Site”). This Policy applies to the Site, applications, products and services (collectively, “Services”) on or in which it is posted, linked, or referenced.
        </div>
        <div className="text-[16px]">
        By using the Services, you accept the terms of this Policy and our Terms of Use, and consent to our collection, use, disclosure, and retention of your information as described in this Policy.  If you have not done so already, please also review our terms of use. The terms of use contain provisions that limit our liability to you and require you to resolve any dispute with us on an individual basis and not as part of any class or representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF USE, THEN PLEASE DO NOT USE ANY OF THE SERVICES
        </div>
        <div className="text-[16px]">
        Please note that this Policy does not apply to information collected through third-party websites or services that you may access through the Services or that you submit to us through email, text message or other electronic message or offline.
        </div>
        <div className="text-[16px]">
        If you are visiting this site from the European Union (EU), see our Notice to EU Data Subjects below for our legal bases for processing and transfer of your data.
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
          WHAT WE COLLECT
        </div>
        <div className="text-[16px]">
        To provide our service
        </div>
        <div className="text-[16px]">
        We will use your personal information in the following ways:
        </div>
        <div className="text-[16px]">
        To enable you to access and use the Services
        </div>
        <div className="text-[16px]">
        To provide and deliver products and services that you may request.
        </div>
        <div className="text-[16px]">
        To send information, including confirmations, technical notices, updates, security alerts, and support and administrative messages.
        </div>
        <div className="text-[16px]">
        To comply with law
        </div>
        <div className="text-[16px]">
        We use your personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests from government authorities. 
        </div>
        <div className="text-[16px]">
        To communicate with you
        </div>
        <div className="text-[16px]">
        We use your personal information to communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.
        </div>
        <div className="text-[16px]">
        To optimize our platform
        </div>
        <div className="text-[16px]">
        In order to optimize your user experience, we may use your personal information to operate, maintain, and improve our Services. We may also use your information to respond to your comments and questions regarding the Services, and to provide you and other users with general customer service.
        </div>
        <div className="text-[16px]">
        With your consent
        </div>
        <div className="text-[16px]">
        We may use or share your personal information with your consent, such as when you instruct us to take a specific action with respect to your personal information, or you opt into third party marketing communications.
        </div>
        <div className="text-[16px]">
        For compliance, fraud prevention, and safety
        </div>
        <div className="text-[16px]">
        We may use your personal information to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
        SHARING OF PERSONAL INFORMATION
        </div>
        <div className="text-[16px]">
        We do not share or sell the personal information that you provide us with other organizations without your express consent, except as described in this Privacy Policy. We disclose personal information to third parties under the following circumstances:
        </div>
        <div className="text-[16px]">
        Affiliates. We may disclose your personal information to our subsidiaries and corporate affiliates for purposes consistent with this Privacy Policy.
        </div>
        <div className="text-[16px]">
        Compliance with Laws and Law Enforcement; Protection and Safety. We may share personal information for legal, protection, and safety purposes.
        </div>
        <div className="text-[16px]">
        We may share information to comply with laws.
        </div>
        <div className="text-[16px]">
        We may share information to respond to lawful requests and legal processes.
        </div>
        <div className="text-[16px]">
        We may share information to protect the rights and property of the Company, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.
        </div>
        <div className="text-[16px]">
        We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.
        </div>
        <div className="text-[16px]">
        Professional Advisors and Service Providers. We may share information with those who need it to do work for us. These recipients may include third party companies and individuals to administer and provide the Service on our behalf (such as customer support, hosting, email delivery and database management services), as well as lawyers, bankers, auditors, and insurers.
        </div>
        <div className="text-[16px]">
        Other. You may permit us to share your personal information with other companies or entities of your choosing. Those uses will be subject to the privacy policies of the recipient entity or entities.
        We may also share aggregated and/or anonymized data with others for their own uses.
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
        INTERNATIONAL TRANSFER
        </div>
        <div className="text-[16px]">
        The Company has offices outside of the EU and has affiliates and service providers in the United States and in other countries. Your personal information may be transferred to or from the United States or other locations outside of your state, province, country or other governmental jurisdiction where privacy laws may not be as protective as those in your jurisdiction.
        </div>
        <div className="text-[16px]">
        EU users should read the important information provided  below about transfer of personal information outside of the European Economic Area (EEA).
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
        HOW INFORMATION IS SECURED
        </div>
        <div className="text-[16px]">
        We retain information we collect as long as it is necessary and relevant to fulfill the purposes outlined in this privacy policy. In addition, we retain personal information to comply with applicable law where required, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of Use, and other actions permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
        </div>
        <div className="text-[16px]">
        In some circumstances we may anonymize your personal information (so that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you.
        </div>
        <div className="text-[16px]">
        We employ industry standard security measures designed to protect the security of all information submitted through the Services. However, the security of information transmitted through the internet can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for changes to or losses of data. Users of the Services are responsible for maintaining the security of any password, biometrics, user ID or other form of authentication involved in obtaining access to password protected or secure areas of any of our digital services. In order to protect you and your data, we may suspend your use of any of the Services, without notice, pending an investigation, if any breach of security is suspected.
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
        HOW INFORMATION IS SECURED
        </div>
        <div className="text-[16px]">
        We retain information we collect as long as it is necessary and relevant to fulfill the purposes outlined in this privacy policy. In addition, we retain personal information to comply with applicable law where required, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of Use, and other actions permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
        </div>
        <div className="text-[16px]">
        In some circumstances we may anonymize your personal information (so that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you.
        </div>
        <div className="text-[16px]">
        We employ industry standard security measures designed to protect the security of all information submitted through the Services. However, the security of information transmitted through the internet can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for changes to or losses of data. Users of the Services are responsible for maintaining the security of any password, biometrics, user ID or other form of authentication involved in obtaining access to password protected or secure areas of any of our digital services. In order to protect you and your data, we may suspend your use of any of the Services, without notice, pending an investigation, if any breach of security is suspected.
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
        ELIGIBILITY
        </div>
        <div className="text-[16px]">
        If you are under the age of majority in your jurisdiction of residence, you may use the Services only with the consent of or under the supervision of your parent or legal guardian. Consistent with the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have received any information directly from a child under age 13 without first receiving his or her parent's verified consent, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the child that he or she cannot use the Site and subsequently we will delete that information.
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <div className="font-bold text-[24px]">
        LOCAL STORAGE POLICY
        </div>
        <div className="text-[16px]">
        Local Storage, including Javascript-enabled localStorage, is a typical way for a website to store a small file of letters and numbers in your browser. We use Local Storage to assign you a unique user ID for purposes of communication with you. Local Storage are deleted when the website that stored them deletes them. You can also delete Local Storage from your browser at any time you like by visiting your web browser settings.
        </div>
        <div className="text-[16px]">
        How to Clear Local Storage from Your Browser:
        </div>
        <div className="text-[16px]">
        In Firefox, localStorage is cleared when the following conditions are met: (a) user clears recent history, (b) cookies are selected to be cleared, and (c) time range is “Everything.”
        In Chrome, localStorage is cleared when the following conditions are met: (a) clear browsing data, (b) "cookies and other site data" is selected, and (c) timeframe is “from beginning of time.” In Chrome, it is also now possible to delete localStorage for one specific site.
        </div>
        <div className="text-[16px]">
        In IE, to clear localStorage: (a) click on Tools--Internet Options, (b) General tab, (c) delete browsing history on exit, (d) ensure “Cookies and website data” (or “temporary internet files and website files”) is selected, and (e) consider unchecking "Preserve Favorites website data" at the top.
        </div>
        <div className="text-[16px]">
        In Safari, (a) Click Safari, (b) Preferences, (c) Select the Privacy tab, (d) Click Remove all website data and (e) Click Remove Now
        </div>
      </div>
    </div>
  )
}
