const bear_token = process.env.REACT_APP_UNISAT;
const main_url = "https://api.tapwallet.io";
const apiUrl4 = "https://api4.tapwallet.io"

export const getUnisatRunesIndex = async (key, sort, start, limit) => {
    const url =  `https://open-api.unisat.io/v1/indexer/runes/info-list?rune=${key}&sort=${sort}&start=${start}&limit=${limit}`;

    const data = {
        url: url
    };

    let response  = await fetch(`${main_url}/unisat/runes/index`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  
    return await response.json();
}

export const getUnisatRunesHolders = async (runeId, start, limit) => {
    const url =  `https://open-api.unisat.io/v1/indexer/runes/${runeId}/holders?limit=${limit}&start=${start}`;

    const data = {
        url: url
    };

    let response  = await fetch(`${main_url}/unisat/runes/holder`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  
    return await response.json();
}

export const getUnisatRunesTopIndex = async (key, sort, start, limit) => {
  const url =  `https://open-api.unisat.io/v1/indexer/runes/info-list?rune=${key}&sort=${sort}&start=${start}&limit=${limit}&complete=no&sort=sixHourMints`;

  const data = {
      url: url
  };

  let response  = await fetch(`${apiUrl4}/unisat/runes/top`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}