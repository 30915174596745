import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCcO7RO9Ly_YYykK4VjasWuXx6fy9HiYHs",
  authDomain: "tapwallet-message-system.firebaseapp.com",
  projectId: "tapwallet-message-system",
  storageBucket: "tapwallet-message-system.appspot.com",
  messagingSenderId: "552450425879",
  appId: "1:552450425879:web:38a8df1cf0ed2fe8144852",
  measurementId: "G-8CQ60CZ8D6"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const messaging = getMessaging(app);

export { firestore, messaging };