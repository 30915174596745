import { UPDATE_BALANCE, EMPTY_BALANCE } from "./action";


export const initialState = {
  balance: []
};
export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BALANCE:
      state.balance.push({... action.data});
      return state;
    case EMPTY_BALANCE:
      state.balance = [];
      return state;
    default:
      return state;
  }
}