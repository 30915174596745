import React from 'react'

export default function Lrc20Card(props) {
  const { data } = props;

  return (
    <div className="flex flex-col border-borderColor border-[1px] border-solid bg-gray-900 p-4 rounded-md ">
      <div className="text-primary font-bold text-[20px]">{data.tick.toUpperCase()}</div>
      <div>ID: {data.contract}</div>
      <div className="mt-3">{data.amt} <span className="font-bold text-blue-800">{data.symbol}</span></div>
    </div>
  )
}
